/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import DataController from '../classes/DataController';

var _ = require('lodash');

type Props = {};

type State = {};

export default class PlusSuggestedQuizScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', 'Suggested'),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
    }
  }

  componentDidUpdate(prevProps) {}

  keyExtractor(item, index) {
    return item.name;
  }

  renderPlusQuizSuggestion({item}) {
    var plusQuizSuggestion = item;

    var quizSet = plusQuizSuggestion.quizSet;

    var sectionColor = colors.SectDefault;
    if (plusQuizSuggestion.quizSet && plusQuizSuggestion.quizSet.section) {
      sectionColor = DataController.getPrimaryColorForSection(
        plusQuizSuggestion.quizSet.section,
      );
    }

    return (
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomWidth: 1,
          borderColor: colors.Grey300,
        }}
        onPress={this.goToQuizOverview.bind(this, quizSet)}>
        <View style={{flex: 1, paddingLeft: 12, paddingVertical: 12}}>
          <View style={{flex: 1}}>
            {plusQuizSuggestion.quizSet.section ? (
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text
                  style={{
                    marginBottom: 4,
                    fontSize: 12,
                    fontWeight: '600',
                    color: sectionColor,
                  }}>
                  {plusQuizSuggestion.quizSet.section}
                </Text>
              </View>
            ) : null}
            <Text style={{marginBottom: 4, fontSize: 17, fontWeight: '600'}}>
              {plusQuizSuggestion.title}
            </Text>
            <Text style={{fontSize: 13, color: colors.Grey800}}>
              Viewed {plusQuizSuggestion.displayDate}
            </Text>
          </View>
        </View>
        <Image
          style={{width: 26, height: 26, marginRight: 12}}
          source={require('../assets/Button_Disclosure_Right_Blue.png')}
        />
      </TouchableOpacity>
    );
  }

  goToQuizOverview(quizSet: Object) {
    this.props.navigation.navigate(
      'QuizOverview',
      {
        quizSet: quizSet,
        from: 'Plus Quiz Suggestion',
      },
      this.state,
    );
  }

  render() {
    var emptyLayout = null;

    if (this.props.screenProps.plusQuizSuggestions.length === 0) {
      emptyLayout = (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            There are no suggested quizzes at the moment. Try coming back later.
          </DynamicText>
        </View>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <View style={{flex: 1, flexDirection: 'column'}}>
          <View
            ref={ref => (this._scrollView = ref)}
            style={{flex: 1}}
            contentContainerStyle={{}}>
            <View style={{flex: 1, backgroundColor: colors.white}}>
              <FlatList
                style={{zIndex: 0}}
                data={this.props.screenProps.plusQuizSuggestions}
                extraData={this.props.screenProps.fontSize}
                keyExtractor={this.keyExtractor.bind(this)}
                renderItem={this.renderPlusQuizSuggestion.bind(this)}
              />
            </View>
          </View>

          {emptyLayout}
        </View>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
