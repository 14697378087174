import P, {ArrowContainer} from 'react-tiny-popover';
import React, {Component} from 'react';
import {View} from 'react-native';

export default class Popover extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View>
        <P
          isOpen={this.props.visible}
          content={({position: arrowPosition, targetRect, popoverRect}) =>
            this.props.content
          }
          onClickOutside={this.props.onRequestClose}
          position={this.props.position}>
          {this.props.children}
        </P>
      </View>
    );
  }
}
