import React from 'react';
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import {containerWidths} from '../../constants/containerWidths';
import BackgroundContainer from './BackgroundContainer';

const UpTicks = ({content, onPress}) => {
  const voteUpHandler = () => onPress();
  const voteDownHandler = () => onPress();
  // TODO: enable this for launch
  // TODO: ad handlers
  // if (!content) {
  //   return null;
  // }
  return (
    <BackgroundContainer color="white">
      <View style={styles.container}>
        <Text style={styles.text}>Did you find this case study helpful?</Text>
        <View style={styles.iconContainer}>
          <TouchableOpacity style={styles.button} onPress={voteUpHandler}>
            <Image
              style={styles.image}
              source={require('../../assets/thumb_up_blue.png')}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={voteDownHandler}>
            <Image
              style={styles.image}
              source={require('../../assets/thumb_down_blue.png')}
            />
          </TouchableOpacity>
        </View>
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 11,
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 27,
  },
  button: {
    paddingHorizontal: 30,
  },
  image: {
    width: 46,
    height: 40,
  },
});

export default UpTicks;
