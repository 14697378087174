/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
  Linking,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import CaseStudyQuizCell from '../components/CaseStudyQuizCell';
import ProgressView from '../components/ProgressView';
import LoadingBar from '../components/LoadingBar';
import DynamicText from '../components/DynamicText';

import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';

var _ = require('lodash');

type Props = {};

type State = {};

export default class CaseStudiesAndStandbyDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{marginLeft: 10, width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerLeft: leftButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
      });
    }, 100);
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  goToCaseStudiesDashboard() {
    this.props.navigation.navigate('CaseStudiesDashboard', {}, this.state);
  }

  goToStandbyDashboard() {
    this.props.navigation.navigate('StandbyDashboard', {}, this.state);
  }

  render() {
    return (
      <ScreenComponent style={[styles.container]}>
        <LoadingBar screenProps={this.props.screenProps} />

        <ScrollView styles={{flex: 1}} contentContainerStyle={{}}>
          <View style={{padding: 10}}>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={this.goToCaseStudiesDashboard.bind(this)}>
              <View
                style={{
                  marginBottom: 16,
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                  backgroundColor: colors.white,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    height: 60,
                    backgroundColor: '#57B0AB',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 42,
                      height: 42,
                      borderRadius: 21,
                      shadowOffset: {width: 0, height: 1},
                      shadowRadius: 1,
                      shadowColor: 'black',
                      shadowOpacity: 0.2,
                      backgroundColor: '#F0FAF9',
                    }}>
                    <Image
                      style={{width: 34, height: 34}}
                      source={require('../assets/Results_Glyph_CaseStudy.png')}
                    />
                  </View>
                  <Text
                    style={{
                      marginLeft: 13,
                      fontSize: 30,
                      fontWeight: 'bold',
                      color: colors.white,
                    }}>
                    Case Studies
                  </Text>
                </View>
                <View style={{height: 150, alignItems: 'stretch'}}>
                  <Image
                    style={{flex: 1, width: null}}
                    resizeMode="cover"
                    source={require('../assets/caseStudyDashboardImage.jpg')}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      paddingHorizontal: 12,
                      paddingVertical: 16,
                      fontSize: 20,
                      fontWeight: '600',
                      color: '#212529',
                    }}>
                    Browse Case Studies
                  </Text>
                </View>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              activeOpacity={0.7}
              onPress={this.goToStandbyDashboard.bind(this)}>
              <View
                style={{
                  marginBottom: 10,
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                  backgroundColor: colors.white,
                }}>
                <View
                  style={{
                    height: 60,
                    backgroundColor: colors.StandbyYellow,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Image
                    style={{width: 325, height: 49}}
                    source={require('../assets/Logo_Standby_Large.png')}
                  />
                </View>
                <View style={{height: 150, alignItems: 'stretch'}}>
                  <Image
                    style={{flex: 1, width: null}}
                    resizeMode="cover"
                    source={require('../assets/standbyDashboardImage.jpg')}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      paddingHorizontal: 12,
                      paddingVertical: 16,
                      fontSize: 20,
                      fontWeight: '600',
                      color: '#212529',
                    }}>
                    Browse Standby CPD's
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
