export const psirfAnalyticsEventNames = {
  started: 'psirf_started',
  dashboard: 'psirf_dashboard',
  button: 'psirf_button_press',
};

export const buttonEvents = {
  keyPointsNext: 'key_points_next',
  keyPointsBack: 'key_points_back',
  learningShowMore: 'learning_show_more',
  learningShowLess: 'learning_show_less',
  furtherReadingShowMore: 'further_reading_show_more',
  furtherReadingShowLess: 'further_reading_show_less',
  usefulLinkPress: 'useful_link_viewed',
  parafolioReflection: 'parafolio_reflection',
  acknowledged: 'acknowledged',
};
