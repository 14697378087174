/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
  Linking,
} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import AuthenticationController from '../classes/AuthenticationController';
import DataController from '../classes/DataController';
import DeviceInfoController from '../classes/DeviceInfoController';
import PlatformController from '../classes/PlatformController';

var _ = require('lodash');

type Props = {};

type State = {};

export default class ProfileScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: 'Profile',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: '500',
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
    }
  }

  logOut() {
    this.props.screenProps.logOut();
  }

  viewWyvernAccount() {
    PlatformController.openURL(
      'http://www.parapass.co.uk/Account/MyAccountJRCALCCPD',
    );
  }

  resyncAllData() {
    this.props.screenProps.resyncAllData();
  }

  async contactSupport() {
    this.props.screenProps.sendSupportEmail('ParaPass Support', 'support');
  }

  readFAQ() {
    PlatformController.openURL(
      'https://www.classprofessional.co.uk/faqs#parapass-app',
    );
  }

  async requestAccountDeletion() {
    this.props.screenProps.showAccountDeletionAlert(true);
  }

  render() {
    var emailAddress = '';
    var trustLogoLayout = null;

    if (this.props.screenProps.user !== null) {
      emailAddress = this.props.screenProps.user.email;

      if (
        this.props.screenProps.user.plusTrusts != null &&
        this.props.screenProps.user.plusTrusts.length > 0
      ) {
        var firstUserTrust = this.props.screenProps.user.plusTrusts[0];

        var trustLogo = DataController.getLogoForTrust(firstUserTrust);

        if (trustLogo != null) {
          trustLogoLayout = (
            <View
              style={{
                backgroundColor: colors.white,
                padding: 10,
                justifyContent: 'center',
              }}>
              <Image style={{width: 229, height: 42}} source={trustLogo} />
            </View>
          );
        }
      }
    }

    return (
      <View style={styles.container}>
        <ScrollView
          style={{flex: 1}}
          scrollIndicatorInsets={{right: 1}}
          contentContainerStyle={{alignItems: 'stretch', marginBottom: 10}}>
          {trustLogoLayout}

          <View
            style={{
              marginTop: 10,
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
              marginHorizontal: 10,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 12}}>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 17,
                  letterSpacing: -0.5,
                  fontWeight: '800',
                  color: colors.Grey900,
                }}>
                Account information
              </DynamicText>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 15,
                  letterSpacing: -0.24,
                  color: colors.Grey600,
                }}>
                Account name:
              </DynamicText>
              <DynamicText
                style={{
                  fontSize: 16,
                  letterSpacing: -0.41,
                  fontWeight: '500',
                  color: colors.Grey900,
                }}>
                {emailAddress}
              </DynamicText>
            </View>

            <TouchableOpacity onPress={this.logOut.bind(this)}>
              <View
                style={{
                  backgroundColor: colors.GreyA00,
                  borderTopWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '500',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.Red500,
                  }}>
                  Log out
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
              marginHorizontal: 10,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 12}}>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 17,
                  letterSpacing: -0.5,
                  fontWeight: '800',
                  color: colors.Grey900,
                }}>
                Reload Data
              </DynamicText>
              <DynamicText
                style={{
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                If you are having issues with the app loading properly, you can
                try reloading your data. Please make sure you have an internet
                connection when trying this.
              </DynamicText>
            </View>

            <TouchableOpacity onPress={this.resyncAllData.bind(this)}>
              <View
                style={{
                  backgroundColor: colors.GreyA00,
                  borderTopWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '600',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.ButtonBlue,
                  }}>
                  Reload Data
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
              marginHorizontal: 10,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 12}}>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 17,
                  letterSpacing: -0.5,
                  fontWeight: '800',
                  color: colors.Grey900,
                }}>
                Support
              </DynamicText>
              <DynamicText
                style={{
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                If you need any help with or have any questions about your
                subscription, please get in touch with us through the button
                below.
              </DynamicText>
            </View>

            <TouchableOpacity onPress={this.contactSupport.bind(this)}>
              <View
                style={{
                  backgroundColor: colors.GreyA00,
                  borderTopWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '600',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.ButtonBlue,
                  }}>
                  Contact support
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>

          <View
            style={{
              marginBottom: 10,
              marginHorizontal: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 12}}>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 17,
                  letterSpacing: -0.5,
                  fontWeight: '800',
                  color: colors.Grey900,
                }}>
                Request account deletion
              </DynamicText>

              <DynamicText
                style={{
                  marginBottom: 16,
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                If you wish to delete your account, do so through the button
                below. You will be asked to confirm your request.
              </DynamicText>
              <DynamicText
                style={{
                  marginBottom: 16,
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                Please note that the account deletion is not immediate and may
                take a few days. We will inform you via email once your account
                has been deleted.
              </DynamicText>
              <DynamicText
                style={{
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                Deleting your account will not cancel your subscription. You
                will need to manage your subscription to cancel.
              </DynamicText>
            </View>

            <TouchableOpacity onPress={this.requestAccountDeletion.bind(this)}>
              <View
                style={{
                  backgroundColor: colors.GreyA00,
                  borderTopWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '600',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.Red500,
                  }}>
                  Request account deletion
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>

          <View style={{marginTop: 20, marginBottom: 30, alignItems: 'center'}}>
            <TouchableOpacity onPress={this.readFAQ.bind(this)}>
              <Text
                style={{
                  marginHorizontal: 10,
                  fontSize: 15,
                  fontWeight: '600',
                  color: colors.ButtonBlue,
                }}>
                Read the FAQs
              </Text>
            </TouchableOpacity>

            <Text
              style={{
                marginTop: 16,
                marginHorizontal: 10,
                fontSize: 13,
                color: colors.Grey800,
                fontWeight: '600',
              }}>
              Version {DeviceInfoController.getAppVersion()}
            </Text>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
