import React from 'react';

import Markdown from 'react-native-markdown-display';

import {colors} from '../classes/Colors';

const markdownStyles = {
  body: {color: colors.black, fontSize: 17},
  heading1: {color: colors.black, fontSize: 32, fontWeight: '700'},
  heading2: {color: colors.black, fontSize: 24, fontWeight: '700'},
  heading3: {color: colors.black, fontSize: 18, fontWeight: '700'},
  heading4: {color: colors.black, fontSize: 16, fontWeight: '700'},
  heading5: {color: colors.black, fontSize: 14, fontWeight: '700'},
  heading6: {color: colors.black, fontSize: 12, fontWeight: '700'},
};

const CustomMarkdown = ({children, ...rest}) => {
  return (
    <Markdown mergeStyle style={markdownStyles} {...rest}>
      {children}
    </Markdown>
  );
};

export default CustomMarkdown;
