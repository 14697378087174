/**
 * NIV App
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Image,
  Platform,
  Linking,
  BackHandler,
  Dimensions,
} from 'react-native';

import AMWebView from '../components/AMWebView';

import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';

export default class HtmlInfoScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', 'Info'),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props) {
    super(props);

    var html = '';

    if (props.navigation != null) {
      html = props.navigation.getParam('html', '');
    }

    if (props.html != null) {
      html = props.html;
    }

    this.state = {
      html: html,
      loadedPage: false,
    };
  }

  async componentDidMount() {
    // if (Platform.OS === 'android' && this.props.navigation != null) {
    //   var that = this;
    //   this.backHandler = BackHandler.addEventListener('hardwareBackPress', function() {
    //     that.props.navigation.goBack();
    //     return true;
    //   });
    // }
  }

  componentWillUnmount() {
    // if (Platform.OS === 'android') {
    //   this.backHandler.remove();
    // }
  }

  onLayout(event) {
    var that = this;

    setTimeout(() => {
      that.setState({loadedPage: true});
    }, 500);
  }

  render() {
    var baseUrl = 'assets/';
    var shouldScalePageToFit = false;

    if (Platform.OS === 'android') {
      baseUrl = 'file:///android_asset/';
      shouldScalePageToFit = true;
    }

    var contentMargin = 14;

    if (Platform.OS === 'web') {
      contentMargin = 0;
    }

    var contentHTML =
      `<!DOCTYPE html>
    <html><head>
    <meta name="viewport" content="width=device-width" initial-scale=1>
    <style>

    body {
      margin-top: ` +
      contentMargin +
      `px;
      margin-left: ` +
      contentMargin +
      `px;
      margin-right: ` +
      contentMargin +
      `px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 17px;
      line-height: 160%;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    </style>

    </head><body>` +
      this.state.html +
      '</body></html>';

    var baseUrl = 'web';

    if (Platform.OS === 'android') {
      baseUrl = 'file:///android_asset/web/';
    }

    var that = this;

    // change margin to resize webview to prevent iPhoneX bug where scrollview is in center of webview
    var webViewMargin = 1;

    if (this.state.loadedPage) {
      webViewMargin = 0;
    }

    var webContentLayout = (
      <AMWebView
        style={{marginRight: webViewMargin}}
        ref={ref => (this._mainWebView = ref)}
        originWhitelist={['*']}
        source={{html: contentHTML, baseUrl: baseUrl}}
        useWebKit={true}
        scrollIndicatorInsets={{right: 1}}
        dataDetectorTypes={'none'}
        allowsFullscreenVideo={true}
        onShouldStartLoadWithRequest={event => {
          console.log(event.url);
          if (
            event.url.includes('http') ||
            event.url.includes('www') ||
            event.url.includes('mailto')
          ) {
            PlatformController.openURL(event.url);
            return false;
          }
          return true;
        }}
        onNavigationStateChange={event => {
          console.log(event.url);
          if (event.url.includes('data:text/html')) {
          } else if (event.url.includes('mailto')) {
            PlatformController.openURL(event.url);
            if (that.props.navigation != null) {
              that.props.navigation.goBack();
            }
          } else if (event.url.includes('http') || event.url.includes('www')) {
            PlatformController.openURL(event.url);
            if (that.props.navigation != null) {
              that.props.navigation.goBack();
            }
          }
        }}
        scalesPageToFit={shouldScalePageToFit}
      />
    );

    if (Platform.OS === 'web') {
      webContentLayout = (
        <ScrollView styles={{flex: 1}} contentContainerStyle={{padding: 10}}>
          <div
            className="content"
            dangerouslySetInnerHTML={{__html: contentHTML}}></div>
        </ScrollView>
      );
    }

    return (
      <View style={[styles.sceneContainer]} onLayout={this.onLayout.bind(this)}>
        {webContentLayout}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  sceneContainer: {
    flex: 1,
    backgroundColor: '#EDF1F4',
  },
});
