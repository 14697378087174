module.exports.TableHelper = class TableHelper {
  constructor() {}
  getElement(htmlText, elementName) {
    var startString = '<' + elementName + '>';
    var startPos = htmlText.indexOf(startString);

    if (startPos == -1) {
      startString = '<' + elementName + ' ';
      startPos = htmlText.indexOf(startString);
    }

    var endString = '</' + elementName + '>';
    var endPos = htmlText.indexOf(endString);

    if (startPos != -1 && endPos != -1 && endPos > startPos) {
      var result = htmlText.substr(startPos, endPos + endString.length);
      return result;
    }

    return '';
  }

  reorderDrugTable(rowColsData) {
    try {
      var last = rowColsData[rowColsData.length - 1];
      if (last['columns'][0].text == '9999') {
        rowColsData.unshift(rowColsData.pop());
      }
    } catch (error) {
      console.log('reorderDrugTable: ' + error);
    }
  }

  doesTableHaveNoteText(glTable) {
    var result = false;

    try {
      for (let i = 0; i < glTable.length; i++) {
        var entry = glTable[i];
        var columns = entry.columns;
        columns.forEach(function (aColumn) {
          if (aColumn['dataType'] === 'drug-note') {
            if (aColumn['text'].length > 0 && aColumn['text'] !== 'NOTE') {
              result = true;
            }
          }
        });
        if (result) break;
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  doesTableHaveWeightText(glTable) {
    var result = false;

    try {
      for (let i = 0; i < glTable.length; i++) {
        var entry = glTable[i];
        var columns = entry.columns;
        columns.forEach(function (aColumn) {
          if (aColumn['dataType'] === 'drug-weight') {
            if (aColumn['text'].length > 0 && aColumn['text'] !== 'WEIGHT') {
              result = true;
            }
          }
        });
        if (result) break;
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  isDateInRange(range, selectedAge) {
    return true;
  }

  removeNote(columnText, notes) {
    var result = columnText;
    var note = this.getElement(columnText, 'note');
    var noteRef = this.getElement(columnText, 'note_ref');

    var count = 0;

    while (note.length > 0) {
      if (noteRef.length > 0) {
        // notes.push(noteRef);
      }

      // notes.push(note);
      result = result.replace(note, '');
      note = this.getElement(result, 'note');

      if (note.length > 0) {
        var nextNoteRef = result.slice(
          result.indexOf(noteRef) + noteRef.length,
        );
        noteRef = this.getElement(nextNoteRef, 'note_ref');
      }

      count++;
    }

    return result;
  }

  columnForDataType(columns, dataType) {
    try {
      for (let i = 0; i < columns.length; i++) {
        var column = columns[i];

        if (column['dataType'] === dataType) {
          return column;
        }
      }
    } catch (error) {
      console.log('columnForDataType: ' + error);
    }

    return null;
  }
  isPageForAge(guidelineID) {
    if (guidelineID.startsWith('P0')) return true;

    return false;
  }
  tableAndBoxesReverse(
    tableAndBoxes,
    width,
    pos,
    guidelineID,
    showTitle,
    reverse,
    dataController,
  ) {
    var FEED2 = false;

    if (tableAndBoxes['FEED2'] === 'YES') {
      FEED2 = true;
    }

    var guidelineTable = tableAndBoxes['rowColData'];
    this.reorderDrugTable(guidelineTable);

    var tableHtml = '';
    var columnCount = guidelineTable.length;
    var selectedAge = {
      name: 'All',
      age: 'All',
      months: -1,
      PFA: 'none',
      range: '',
    };

    var pageForAge = this.isPageForAge(guidelineID);

    if (selectedAge.months >= 0) {
      columnCount = 2;
    }
    if (pageForAge) {
      columnCount = guidelineTable.length;
    }
    if (columnCount > 8) {
      columnCount = 'Wide';
      width = 799;
    }

    tableHtml +=
      '<div class=AACEDrugTableContainer id=' +
      guidelineID +
      'dosageTable' +
      tableAndBoxes['ID'] +
      '>';

    if (columnCount > 8) {
      columnCount = 'Wide';
    }

    var ageRangeCol = 0;

    if (showTitle) {
      tableHtml +=
        '<div class=AACETableTitle>' + tableAndBoxes['title'] + '</div>';
    }

    var idAdded = true;
    tableHtml +=
      '<ion-scroll style="padding-bottom: 15px;" has-bouncing="false" direction="x">';

    var rowsAdded = 0;
    tableHtml += '<table class=AACEDrugTablePFA' + columnCount + '>';

    var firstRow = guidelineTable[0];
    var skipRowPos = 0;

    if (
      firstRow.columns[0].text == '999' ||
      firstRow.columns[0].text == '9999'
    ) {
      skipRowPos = 1;
    }

    var notes = [];

    if (FEED2) {
      var columnsToUseInOrder = [];
      var hasNoteText = this.doesTableHaveNoteText(guidelineTable);
      var hasWeight = this.doesTableHaveWeightText(guidelineTable);

      skipRowPos = 0;
      if (tableAndBoxes['figuretype'] === 'dosage8') {
        columnsToUseInOrder = [
          'drug-age',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
        if (hasWeight) {
          columnsToUseInOrder.splice(1, 0, 'drug-weight');
        }
      }
      if (tableAndBoxes['figuretype'] === 'dosage9') {
        columnsToUseInOrder = [
          'drug-age',
          'drug-weight',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
      }
      if (
        tableAndBoxes['figuretype'] === 'quickref' ||
        tableAndBoxes['figuretype'] === 'intravascular' ||
        tableAndBoxes['figuretype'] === 'reversal' ||
        tableAndBoxes['figuretype'] === 'cardiac'
      ) {
        columnsToUseInOrder = [
          'drug-name',
          'drug-route',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }

        skipRowPos = 0;
        reverse = true;
      }
      if (dataController.addTableAges) {
        columnsToUseInOrder.push('drug-age');
        columnsToUseInOrder.push('drug-months');
        columnsToUseInOrder.push('drug-start-age');
        columnsToUseInOrder.push('drug-end-age');
      }

      if (!reverse) {
        for (let i = skipRowPos; i < columnsToUseInOrder.length; i++) {
          tableHtml += '<tr>';

          for (let j = 0; j < guidelineTable.length; j++) {
            var row = guidelineTable[j];
            var range = -1;

            var column = this.columnForDataType(
              row.columns,
              columnsToUseInOrder[i],
            );

            range = row.columns[0].text;

            if (column) {
              if (pageForAge || this.isDateInRange(range, selectedAge)) {
                if (i == skipRowPos) {
                  tableHtml += '<th>';
                  var columnText = this.removeNote(column.text, notes);
                  tableHtml += columnText;
                  tableHtml += '</th>\n';
                } else {
                  tableHtml += '<td>';
                  columnText = this.removeNote(column.text, notes);
                  tableHtml += columnText;
                  tableHtml += '</td>\n';
                }

                if (j > 0) {
                  rowsAdded++;
                }
              }
            }
          }
          tableHtml += '</tr>\n';
        }
      } else {
        for (let i = 0; i < guidelineTable.length; ++i) {
          row = guidelineTable[i];
          tableHtml +=
            '<tr ng-click="tableRow(\'' +
            tableAndBoxes['ID'] +
            "'," +
            i +
            ')">';

          for (let j = 0; j < columnsToUseInOrder.length; ++j) {
            column = this.columnForDataType(
              row.columns,
              columnsToUseInOrder[j],
            );

            if (column) {
              if (i == 0) {
                tableHtml += '<th>';
                columnText = this.removeNote(column.text, notes);
                tableHtml += columnText;
                tableHtml += '</th>\n';
              } else {
                if (j == 0) {
                  tableHtml += '<td class="blueUnderLine">';
                } else {
                  tableHtml += '<td>';
                }

                columnText = this.removeNote(column.text, notes);
                tableHtml += columnText;
                tableHtml += '</td>\n';
              }
            }
          }
        }
        tableHtml += '</tr>';
        rowsAdded++;
      }
    } else {
      if (!reverse) {
        for (let i = skipRowPos; i < firstRow.columns.length; i++) {
          tableHtml += '<tr>';

          for (let j = 0; j < guidelineTable.length; j++) {
            row = guidelineTable[j];
            range = -1;

            if (skipRowPos > 0) {
              range = row.columns[0].text;
            }

            if (pageForAge || this.isDateInRange(range, selectedAge)) {
              if (i == skipRowPos) {
                tableHtml += '<th>';
                columnText += this.removeNote(row.columns[i].text, notes);
                tableHtml += columnText;
                tableHtml += '</th>\n';
              } else {
                tableHtml += '<td>';
                columnText = this.removeNote(row.columns[i].text, notes);
                tableHtml += columnText;
                tableHtml += '</td>\n';
              }

              if (j > 0) {
                rowsAdded++;
              }
            }
          }
          tableHtml += '</tr>\n';
        }
      } else {
        for (let i = 0; i < guidelineTable.length; i++) {
          row = guidelineTable[i];
          tableHtml +=
            '<tr ng-click="tableRow(\'' +
            tableAndBoxes['ID'] +
            "'," +
            i +
            ')">';

          for (let j = 0; j < row.columns.length; ++j) {
            if (i == 0) {
              tableHtml += '<th>';
              columnText = this.removeNote(row.columns[j].text, notes);
              tableHtml += columnText;
              tableHtml += '</th>\n';
            } else {
              if (j == 0) {
                tableHtml += '<td class="blueUnderLine">';
              } else {
                tableHtml += '<td>';
              }

              columnText = this.removeNote(row.columns[j].text, notes);
              tableHtml += columnText;
              tableHtml += '</td>\n';
            }
          }
          tableHtml += '</tr>\n';
          rowsAdded++;
        }
      }
    }

    if (rowsAdded == 0) {
      var pointToReplace = tableHtml.indexOf('<table class=ACCEDrugTablePFA');
      tableHtml = tableHtml.substr(0, pointToReplace);
      tableHtml +=
        '<div>It is NOT recommended to administer this drug to a person in the age range you have selected. See the full guideline for age restrictions</div>';
    } else {
      tableHtml += '</table>';
      tableHtml +=
        '<div class=AACETableTitle>' + tableAndBoxes['table_note'] + '</div>';
    }

    tableHtml += '</ion-scroll>';

    if (notes.length > 0) {
      tableHtml += '<div class=AACEDrugTableNotes>NOTES: ';

      for (let i = 0; i < notes.length; i++) {
        tableHtml += notes[i];
      }
      tableHtml += '</div>';
    }

    tableHtml += '</div>';
    return tableHtml;
  }
};
