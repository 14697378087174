import React, {Component} from 'react';
import {View} from 'react-native';

export default class AMWebView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <View />;
  }
}
