/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
  Linking,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import StandbyQuizCell from '../components/StandbyQuizCell';
import ProgressView from '../components/ProgressView';
import LoadingBar from '../components/LoadingBar';

import PlatformController from '../classes/PlatformController';
import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

import {firebase} from '../classes/Firebase';

var _ = require('lodash');

type Props = {};

type State = {};

export default class BookDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{marginLeft: 10, width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      title: 'Books',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerLeft: leftButtonsLayout,
      headerRight: rightButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
        searchButtonTapped: that.searchButtonTapped.bind(that),
      });
    }, 100);
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  goToBook(book: Object) {
    this.props.navigation.navigate(
      'BookContents',
      {
        book: book,
      },
      this.state,
    );

    let bookName = book.name;
    if (
      this.props.screenProps.user &&
      this.props.screenProps.user.paraPassTrusts &&
      this.props.screenProps.user.paraPassTrusts.length > 0
    ) {
      let trust = this.props.screenProps.user.paraPassTrusts[0];
      bookName = bookName + ' - ' + trust;
    }

    try {
      firebase.analytics().logEvent('book_viewed', {bookViewedName: bookName});
    } catch (error) {
      console.log(error);
    }
  }

  searchButtonTapped() {
    console.log('search');
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
        startingFilter: 'Book',
      },
      this.state,
    );
  }

  render() {
    let bookCellsLayout = [];

    for (
      var bookIndex = 0;
      bookIndex < this.props.screenProps.books.length;
      bookIndex++
    ) {
      let book = this.props.screenProps.books[bookIndex];
      let bookStatus = '';

      for (let bookReadingProgressItem of this.props.screenProps
        .bookReadingProgress) {
        if (bookReadingProgressItem === book.ID) {
          bookStatus = 'Read';
          break;
        } else if (
          bookReadingProgressItem.includes(book.ID) &&
          bookStatus !== 'Read'
        ) {
          bookStatus = 'In Progress';
        }
      }

      let bookStatusColor = colors.Orange400;
      if (bookStatus === 'Read') {
        bookStatusColor = colors.Green500;
      }

      bookCellsLayout.push(
        <TouchableOpacity
          key={book.name}
          onPress={this.goToBook.bind(this, book)}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              padding: 16,
              borderBottomColor: '#dee2e6',
              borderBottomWidth: 1,
            }}>
            <Image
              style={{width: 50, height: 72}}
              resizeMode="contain"
              source={book.coverImageSource}
            />
            <View style={{flex: 1}}>
              <DynamicText
                style={{
                  flex: 1,
                  marginLeft: 16,
                  marginRight: 4,
                  fontSize: 17,
                  fontWeight: '600',
                  lineHeight: 24,
                  color: '#212529',
                }}>
                {book.name}
              </DynamicText>
              {bookStatus !== '' ? (
                <Text
                  style={{
                    flex: 1,
                    marginLeft: 16,
                    marginRight: 4,
                    fontSize: 14,
                    fontWeight: '600',
                    lineHeight: 24,
                    color: bookStatusColor,
                  }}>
                  {bookStatus}
                </Text>
              ) : null}
            </View>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right.png')}
            />
          </View>
        </TouchableOpacity>,
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <View
          style={{
            flexDirection: 'row',
            height: 60,
            backgroundColor: '#57B0AB',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 42,
              height: 42,
              borderRadius: 21,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: '#F0FAF9',
            }}>
            <Image
              style={{width: 20, height: 20}}
              source={require('../assets/Icon_Books_Banner.png')}
            />
          </View>
          <Text
            style={{
              marginLeft: 13,
              fontSize: 30,
              fontWeight: 'bold',
              color: colors.white,
            }}>
            Books
          </Text>
        </View>

        <LoadingBar screenProps={this.props.screenProps} />

        <ScrollView styles={{flex: 1}} contentContainerStyle={{padding: 16}}>
          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            {bookCellsLayout}
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
