import {firebase} from '../classes/Firebase';

export const logAnalyticsEvent = (eventName, eventParams = {}) => {
  console.log('logAnalyticsEvent', eventName, eventParams);
  try {
    firebase?.analytics().logEvent(eventName, eventParams);
  } catch (error) {
    console.log(error);
  }
};
