/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
  Linking,
} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';
import PlatformController from '../classes/PlatformController';

// import Onboarding from 'react-native-onboarding-swiper';
import Swiper from 'react-native-web-swiper';

var _ = require('lodash');

type Props = {};

type State = {
  alreadyCompletedOnboarding: boolean,
  reloadKey: number,
};

export default class OnboardingScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: '500',
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        alreadyCompletedOnboarding: false,
        reloadKey: 0,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      var completedOnboarding = await DataController.hasCompletedOnboarding();

      if (Platform.OS === 'web') {
        completedOnboarding = true;
      }

      this.setState({
        alreadyCompletedOnboarding: completedOnboarding,
        reloadKey: this.state.reloadKey + 1,
      });
    }
  }

  goToNext() {
    this._swiper.goToNext();
  }

  openParaPassSite() {
    PlatformController.openURL('http://www.parapass.co.uk');
  }

  login() {
    this.props.screenProps.switchToLogin();
    this.props.screenProps.hideOnboarding();
  }

  register() {
    this.props.screenProps.switchToRegister();
    this.props.screenProps.hideOnboarding();
  }

  render() {
    var logoMarginTop = 0;
    var mainMarginBottom = 0;

    if (Platform.OS === 'ios') {
      logoMarginTop = 20;
    }

    if (PlatformController.deviceHasBottomSafeArea()) {
      logoMarginTop = 44;
      mainMarginBottom = 20;
    }

    var buttonAlignment = 'center';
    var buttonMaxWidth = 1024;
    var buttonMinWidth = 500;

    if (LayoutController.isCompact()) {
      buttonAlignment = 'stretch';
      buttonMaxWidth = 1024;
      buttonMinWidth = 100;
    }

    var getStartedScreenLayout = null;

    // if (this.state.alreadyCompletedOnboarding !== true) {
    //   getStartedScreenLayout = (
    //     <View style={{flex: 1, backgroundColor: colors.white}}>
    //       <View style={{flex: 1, margin: 10, marginBottom: 35, paddingHorizontal: 10, paddingVertical: 20, alignItems: 'center', backgroundColor: colors.white}}>
    //         <Text style={{fontSize: 28, fontWeight: '700', textAlign: 'center', color: colors.Grey900, marginBottom: 20}}>Get Started</Text>
    //         <Image style={{flex: 1, width: 300, height: 178, marginBottom: 10}} resizeMode={'contain'} source={require('../assets/Image_OB_06.png')} />

    //         <TouchableOpacity activeOpacity={0.7} style={{alignSelf: buttonAlignment, minWidth: buttonMinWidth, maxWidth: buttonMaxWidth}} onPress={this.login.bind(this)}>
    //           <View style={{marginTop: 15, paddingVertical: 12, backgroundColor: colors.ButtonBlue, justifyContent: 'center', alignItems: 'center', borderRadius: 8}}>
    //             <DynamicText style={{fontSize: 16, fontWeight: '700', letterSpacing: -0.39, color: colors.white}}>Login</DynamicText>
    //           </View>
    //         </TouchableOpacity>

    //         <Text style={{textAlign: 'center', fontSize: 15, color: colors.Grey900, marginTop: 20}}>If you are a new user, register here</Text>

    //         <TouchableOpacity style={{alignSelf: buttonAlignment, minWidth: buttonMinWidth, maxWidth: buttonMaxWidth}} onPress={this.register.bind(this)}>
    //           <View style={{marginTop: 15, paddingVertical: 12, borderWidth: 1, justifyContent: 'center', alignItems: 'center', borderColor: colors.ButtonBlue, borderRadius: 8}}>
    //             <DynamicText style={{fontSize: 16, textAlign: 'center', fontWeight: 'bold', color: colors.ButtonBlue}}>Register</DynamicText>
    //           </View>
    //         </TouchableOpacity>

    //         <Text style={{textAlign: 'center', fontSize: 15, color: colors.Grey900, marginTop: 20}}>Monthly and annual subscription options are available.</Text>

    //       </View>
    //     </View>
    //   );
    // }

    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
          backgroundColor: colors.white,
          marginBottom: mainMarginBottom,
        }}>
        <View style={{height: logoMarginTop, backgroundColor: colors.NavBar}} />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.NavBar,
            height: 44,
          }}>
          <Image
            style={{width: 150, height: 24}}
            resizeMode={'contain'}
            source={require('../assets/Logo_ParaPass_Short.png')}
          />
        </View>

        {/* <Onboarding
            pages={[
              {
                backgroundColor: '#fff',
                image: (
                  <View style={{alignItems: 'center'}}>
                    <Text>Welcome to</Text>
                    <Text>ParaPass</Text>
                    <Image style={{width: 300, height: 178}} source={require('../assets/Image_OB_01.png')} />
                  </View>
                  ),
                title: "Test",
                subtitle: 'JRCALC CPD has been relaunched as ParaPass. The new app includes more quizzes, more case studies and Standby CPD articles to support your learning.',
              },
              {
                backgroundColor: '#FFF',
                // image: <Image source={require('./images/circle.png')} />,
                title: 'Onboarding',
                subtitle: 'JRCALC CPD has been relaunched as ParaPass. The new app includes more quizzes, more case studies and Standby CPD articles to support your learning.',
              },
            ]}
            showSkip={false}
            showNext={false}
            showDone={false}
            bottomBarHighlight={false}
          /> */}

        <Swiper
          ref={ref => (this._swiper = ref)}
          key={this.state.reloadKey}
          springConfig={{bounciness: 0}}
          controlsProps={{
            nextTitle: '',
            prevTitle: '',
            dotsTouchable: true,
            nextPos: false,
          }}>
          <View style={{flex: 1, backgroundColor: colors.white}}>
            <View
              style={{
                flex: 1,
                margin: 10,
                marginBottom: 35,
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: colors.white,
              }}>
              <Image
                style={{flex: 1, alignSelf: 'center', width: 350}}
                resizeMode={'contain'}
                source={require('../assets/Image_OB_Daily.png')}
              />
              <Text
                style={{
                  alignSelf: 'flex-start',
                  paddingHorizontal: 5,
                  paddingVertical: 3,
                  fontSize: 11,
                  fontWeight: '700',
                  color: colors.white,
                  marginBottom: 4,
                  backgroundColor: '#ED242B',
                  borderRadius: 10,
                  overflow: 'hidden',
                }}>
                NEW
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  fontWeight: '700',
                  textAlign: 'left',
                  color: colors.Grey900,
                  marginBottom: 12,
                }}>
                Daily content straight to your dashboard
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  textAlign: 'left',
                  fontSize: 16,
                  color: colors.Grey900,
                }}>
                Read the regularly updated featured articles or try your hand at
                the daily CPD quiz.
              </Text>
            </View>
          </View>

          <View style={{flex: 1, backgroundColor: colors.white}}>
            <View
              style={{
                flex: 1,
                margin: 10,
                marginBottom: 35,
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: colors.white,
              }}>
              <Image
                style={{flex: 1, alignSelf: 'center', width: 350}}
                resizeMode={'contain'}
                source={require('../assets/Image_OB_Quizzes.png')}
              />
              <Text
                style={{
                  fontSize: 28,
                  fontWeight: '700',
                  textAlign: 'left',
                  color: colors.Grey900,
                  marginBottom: 12,
                }}>
                A huge library of CPD quizzes
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  textAlign: 'left',
                  fontSize: 16,
                  color: colors.Grey900,
                }}>
                Take quizzes on JRCALC guidelines, Standby CPD topics and
                exclusive Case Studies.
              </Text>
            </View>
          </View>

          <View style={{flex: 1, backgroundColor: colors.white}}>
            <View
              style={{
                flex: 1,
                margin: 10,
                marginBottom: 35,
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: colors.white,
              }}>
              <Image
                style={{flex: 1, alignSelf: 'center', width: 350}}
                resizeMode={'contain'}
                source={require('../assets/Image_OB_Standby.png')}
              />
              <Text
                style={{
                  fontSize: 28,
                  fontWeight: '700',
                  textAlign: 'left',
                  color: colors.Grey900,
                  marginBottom: 12,
                }}>
                Monthly Standby CPD issues
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  textAlign: 'left',
                  fontSize: 16,
                  color: colors.Grey900,
                }}>
                Stay up to date with your CPD by reading up on the latest issue
                in under 10 minutes.
              </Text>
            </View>
          </View>

          <View style={{flex: 1, backgroundColor: colors.white}}>
            <View
              style={{
                flex: 1,
                margin: 10,
                marginBottom: 35,
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: colors.white,
              }}>
              <Image
                style={{flex: 1, alignSelf: 'center', width: 350}}
                resizeMode={'contain'}
                source={require('../assets/Image_OB_Plus.png')}
              />
              <Text
                style={{
                  alignSelf: 'flex-start',
                  paddingHorizontal: 5,
                  paddingVertical: 3,
                  fontSize: 11,
                  fontWeight: '700',
                  color: colors.white,
                  marginBottom: 4,
                  backgroundColor: '#ED242B',
                  borderRadius: 10,
                  overflow: 'hidden',
                }}>
                NEW
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  fontWeight: '700',
                  textAlign: 'left',
                  color: colors.Grey900,
                  marginBottom: 12,
                }}>
                Sync up with JRCALC Plus
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  textAlign: 'left',
                  fontSize: 16,
                  color: colors.Grey900,
                }}>
                Get suggested quizzes based on what you’ve been viewing in
                JRCALC Plus.
              </Text>
            </View>
          </View>

          <View style={{flex: 1, backgroundColor: colors.white}}>
            <View
              style={{
                flex: 1,
                margin: 10,
                marginBottom: 35,
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: colors.white,
              }}>
              <Image
                style={{flex: 1, alignSelf: 'center', width: 350}}
                resizeMode={'contain'}
                source={require('../assets/Image_OB_Results.png')}
              />
              <Text
                style={{
                  fontSize: 28,
                  fontWeight: '700',
                  textAlign: 'left',
                  color: colors.Grey900,
                  marginBottom: 12,
                }}>
                Keep track of your CPD
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  textAlign: 'left',
                  fontSize: 16,
                  color: colors.Grey900,
                }}>
                See your quiz results in detail, reviewing what you got correct
                and incorrect, and download your certificates as evidence of
                CPD.
              </Text>
            </View>
          </View>

          <View style={{flex: 1, backgroundColor: colors.white}}>
            <View
              style={{
                flex: 1,
                margin: 10,
                marginBottom: 35,
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: colors.white,
              }}>
              <Image
                style={{flex: 1, alignSelf: 'center', width: 350}}
                resizeMode={'contain'}
                source={require('../assets/Image_OB_Notifications.png')}
              />
              <Text
                style={{
                  fontSize: 28,
                  fontWeight: '700',
                  textAlign: 'left',
                  color: colors.Grey900,
                  marginBottom: 12,
                }}>
                Stay up to date with notifications
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  textAlign: 'left',
                  fontSize: 16,
                  color: colors.Grey900,
                }}>
                Get useful reminders about important updates and information.
              </Text>
            </View>
          </View>

          {getStartedScreenLayout}
        </Swiper>

        <TouchableOpacity
          activeOpacity={0.7}
          style={{
            marginHorizontal: 20,
            marginBottom: 20,
            alignSelf: buttonAlignment,
            minWidth: buttonMinWidth,
            maxWidth: buttonMaxWidth,
          }}
          onPress={this.props.screenProps.hideOnboarding.bind(this)}>
          <View
            style={{
              marginTop: 15,
              paddingVertical: 20,
              backgroundColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <DynamicText
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Get started
            </DynamicText>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
});
