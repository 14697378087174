import React from 'react';
import {Text, Pressable, StyleSheet, Image, View} from 'react-native';

export default function CustomButton({
  onPress,
  title,
  buttonStyle,
  titleStyle,
  iconComponent,
  iconImageUrl,
  disabled,
}) {
  return (
    <Pressable style={buttonStyle} onPress={onPress} disabled={disabled}>
      {title && <Text style={titleStyle}>{title}</Text>}
      {iconComponent && (
        <View style={styles.iconContainer}>{iconComponent}</View>
      )}
      {iconImageUrl && <Image style={styles.icon} source={iconImageUrl} />}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
  iconContainer: {
    position: 'absolute',
    right: 14,
  },
});
