/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
  Linking,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import StandbyQuizCell from '../components/StandbyQuizCell';
import ProgressView from '../components/ProgressView';
import LoadingBar from '../components/LoadingBar';

import PlatformController from '../classes/PlatformController';
import CommunicationsController from '../classes/CommunicationsController';
import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

var _ = require('lodash');

type Props = {};

type State = {
  quizSets: [],
};

export default class StandbyDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{marginLeft: 10, width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>
      </View>
    );

    let navOptions = {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };

    let showMenuButton = navigation.getParam('showMenuButton', false);
    if (showMenuButton) {
      navOptions.headerLeft = leftButtonsLayout;
    }

    return navOptions;
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        quizSets: [],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.props.screenProps.quizSets != null) {
        this.updateQuizSets(this.props.screenProps.quizSets);
      }
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
        showMenuButton: !that.props.screenProps.showBooksTab,
        searchButtonTapped: that.searchButtonTapped.bind(that),
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEqual(
        this.props.screenProps.quizSets,
        prevProps.screenProps.quizSets,
      ) === false
    ) {
      this.updateQuizSets(this.props.screenProps.quizSets);
    }
  }

  updateQuizSets(quizSets) {
    var standbyQuizSets = [];

    for (var quizSetIndex = 0; quizSetIndex < quizSets.length; quizSetIndex++) {
      var quizSet = quizSets[quizSetIndex];

      if (quizSet.type === 'standby') {
        standbyQuizSets.push(quizSet);
      }
    }

    this.setState({quizSets: standbyQuizSets});
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  keyExtractor(item, index) {
    return item.name;
  }

  renderQuizSet({item, latestIssue}) {
    var quizSet = item;

    var results = this.props.screenProps.latestResultsForQuizSets[quizSet.name];
    var selectedIssue = {};

    for (
      var issueIndex = 0;
      issueIndex < this.props.screenProps.issues.length;
      issueIndex++
    ) {
      var issue = this.props.screenProps.issues[issueIndex];

      if (issue.Name === quizSet.issueName) {
        selectedIssue = issue;
      }
    }

    var isLatestIssue = false;

    if (latestIssue != null) {
      isLatestIssue = latestIssue;
    }

    return (
      <TouchableOpacity
        onPress={this.goToStandbyVolume.bind(this, quizSet, selectedIssue)}>
        <StandbyQuizCell
          key={quizSet.name}
          issue={selectedIssue}
          isLatestIssue={isLatestIssue}
          quizSet={quizSet}
          results={results}
          fontSize={this.props.screenProps.fontSize}
        />
      </TouchableOpacity>
    );
  }

  goToStandbyVolume(quizSet: Object, issue: Object) {
    this.props.navigation.navigate(
      'StandbyVolume',
      {
        quizSet: quizSet,
        issue: issue,
      },
      this.state,
    );
  }

  searchButtonTapped() {
    console.log('search');
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
        startingFilter: 'Standby CPD',
      },
      this.state,
    );
  }

  sendStandbySuggestionFeedback() {
    var emailAddress = 'appfeedback@class.co.uk';
    var subject = 'Standby CPD - Suggest a topic';

    var body = '';

    if (Platform.OS === 'android') {
      body = body.replace(/\n/g, '<br>');
    }

    CommunicationsController.sendEmail(
      [emailAddress],
      [],
      [],
      subject,
      body,
      [],
    );
  }

  render() {
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var questionCount = 0;

    for (
      var quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.state.quizSets[quizSetIndex];

      var results =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      questionCount += quizSet.questions.length;

      if (results != null) {
        for (var resultIndex = 0; resultIndex < results.length; resultIndex++) {
          var result = results[resultIndex];

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }
        }
      }

      unansweredCount = questionCount - correctCount - incorrectCount;
    }

    var correctPercentage = (correctCount / questionCount) * 100;
    var incorrectPercentage = (incorrectCount / questionCount) * 100;
    var unansweredPercentage = (unansweredCount / questionCount) * 100;

    if (isNaN(correctPercentage)) {
      var correctPercentage = 0;
      var incorrectPercentage = 0;
      var unansweredPercentage = 0;
    }

    correctPercentage = correctPercentage.toFixed(1);
    incorrectPercentage = incorrectPercentage.toFixed(1);
    unansweredPercentage = unansweredPercentage.toFixed(1);

    var quizSets = this.state.quizSets.slice();

    var latestQuizSetLayout = null;

    if (quizSets.length > 1) {
      var latestQuizSet = quizSets.shift();

      var latestQuizSetCell = this.renderQuizSet({
        item: latestQuizSet,
        latestIssue: true,
      });

      latestQuizSetLayout = (
        <View
          style={{
            marginBottom: 10,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          }}>
          <View style={{backgroundColor: colors.CardHeader}}>
            <DynamicText
              style={{
                marginHorizontal: 10,
                marginVertical: 6,
                fontSize: 14,
                fontWeight: 'bold',
                fontStyle: 'normal',
                letterSpacing: -0.2,
                color: colors.white,
              }}>
              LATEST STANDBY CPD
            </DynamicText>
          </View>

          {latestQuizSetCell}
        </View>
      );
    }

    var emptyLayout = null;

    var showSelfRegistrationCard =
      this.props.screenProps.user &&
      this.props.screenProps.user.plusTrusts.includes('SWAST') &&
      !this.props.screenProps.user.paraPassTrusts.includes('SWAST');

    if (quizSets.length === 0 && !showSelfRegistrationCard) {
      emptyLayout = (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            Don't have a subscription to ParaPass?{' '}
            <Text
              style={{color: colors.ButtonBlue}}
              onPress={this.props.screenProps.openSubscriptionMenu.bind(this)}>
              Subscribe now
            </Text>{' '}
            for access to JRCALC CPD, Standby CPD and Case Scenarios. Contact{' '}
            <Text
              style={{color: colors.ButtonBlue}}
              onPress={() =>
                PlatformController.openURL(
                  'mailto:apps@class.co.uk?subject=ParaPass%20Ambulance%20Trust%20PGD%20Query',
                )
              }>
              apps@class.co.uk
            </Text>{' '}
            for more information.
          </DynamicText>
        </View>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView>
          <LoadingBar screenProps={this.props.screenProps} />

          <View
            style={{
              height: 60,
              backgroundColor: colors.StandbyYellow,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image
              style={{width: 325, height: 49}}
              source={require('../assets/Logo_Standby_Large.png')}
            />
          </View>

          {showSelfRegistrationCard && (
            <TouchableOpacity
              onPress={() =>
                PlatformController.openURL(
                  'https://swastcpd.co.uk/events/para-pass-app/',
                )
              }
              style={{
                backgroundColor: '#dcfbec',
                borderWidth: 1,
                borderColor: '#7cd2b9',
                padding: 12,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                margin: 10,
              }}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image
                  style={{
                    width: 20,
                    height: '100%',
                    resizeMode: 'contain',
                  }}
                  source={require('../assets/Icon_Lock_with_shadow.png')}
                />
                <Text
                  style={{
                    fontWeight: 'bold',
                    marginLeft: 6,
                  }}>
                  Get the full library of Standby CPD issues
                </Text>
              </View>
              <Text style={{marginTop: 6}}>
                Standby CPD is a monthly publication written by professionals in
                paramedic practice. Explore detailed articles and take a related
                quiz on a new clinical topic each month.
              </Text>
            </TouchableOpacity>
          )}

          {emptyLayout}

          <View
            style={{
              padding: 10,
            }}>
            {latestQuizSetLayout}

            <View
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <FlatList
                data={quizSets}
                extraData={[
                  this.props.screenProps.latestResultsForQuizSets,
                  this.props.screenProps.issues,
                  this.props.screenProps.fontSize,
                ]}
                keyExtractor={this.keyExtractor.bind(this)}
                renderItem={this.renderQuizSet.bind(this)}
              />
            </View>

            {quizSets.length > 0 ? (
              <View
                style={{
                  marginBottom: 10,
                  marginBottom: 10,
                  paddingHorizontal: 12,
                  paddingVertical: 16,
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                  backgroundColor: colors.white,
                }}>
                <DynamicText style={{fontSize: 17, fontWeight: '700'}}>
                  Got a Topic you think Standby CPD should cover?
                </DynamicText>
                <DynamicText style={{marginTop: 6, fontSize: 16}}>
                  Use the button below to provide your thoughts on any topics
                  you would like to read about with Standby CPD. You can also
                  send any feedback you may have to us as well. All feedback and
                  suggestions are greatly appreciated.
                </DynamicText>
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={{alignSelf: 'flex-end'}}
                  onPress={this.sendStandbySuggestionFeedback.bind(this)}>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 15,
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      backgroundColor: colors.ButtonBlue,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 6,
                      shadowOffset: {width: 0, height: 1},
                      shadowRadius: 1,
                      shadowColor: 'black',
                      shadowOpacity: 0.2,
                    }}>
                    <DynamicText
                      style={{
                        fontSize: 16,
                        fontWeight: '700',
                        letterSpacing: -0.39,
                        color: colors.white,
                      }}>
                      Suggest a topic
                    </DynamicText>
                  </View>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
