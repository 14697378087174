import React from 'react';

import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import BackgroundContainer from './BackgroundContainer';
import {colors} from '../../classes/Colors';
import {useAcknowledgements} from '../../context/AcknowledgementsContext';
import {logAnalyticsEvent} from '../../utils/firebase';
import {psirfAnalyticsEventNames, buttonEvents} from '../../constants/psirf';

// Acknowledgment
//   {
//     "ContentID": "1",
//     "Title": "test",
//     "TimeStamp": "0000-00-00 00:00:00",
//     "Trust": "NEAS",
//     "Type": "psirf"
// }

const Acknowledgment = ({psirfMetaData, user, title}) => {
  const {updateAcknowledgementsHandler, isAcknowledged} =
    useAcknowledgements('psirf');
  const isPsirfAcknowledged = isAcknowledged(psirfMetaData.psirfId);
  const trust = user?.paraPassTrusts?.[0] || user?.plusTrusts?.[0] || 'N/A';
  return (
    <BackgroundContainer color="white">
      <View style={styles.container}>
        <TouchableOpacity
          style={[styles.button, isPsirfAcknowledged && styles.ackButton]}
          onPress={() => {
            logAnalyticsEvent(psirfAnalyticsEventNames.button, {
              event: buttonEvents.acknowledged,
              psirf_id: psirfMetaData.psirfId,
            });

            updateAcknowledgementsHandler({
              ContentID: psirfMetaData.psirfId,
              TimeStamp: new Date().toISOString(),
              Trust: trust,
              Type: 'psirf',
              Title: title,
            });
          }}
          disabled={isPsirfAcknowledged}>
          <Text
            style={[
              styles.buttonText,
              isPsirfAcknowledged && styles.ackButtonText,
            ]}>
            {isPsirfAcknowledged ? 'Acknowledged' : 'Acknowledge'}
          </Text>
        </TouchableOpacity>
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    paddingVertical: 40,
    alignSelf: 'center',
  },
  text: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'center',
    color: colors.black,
  },
  button: {
    alignSelf: 'center',
    backgroundColor: colors.PrimaryBlue,
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  buttonText: {
    color: colors.white,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
  ackButton: {
    alignSelf: 'center',
    backgroundColor: '#F1F3F5',
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  ackButtonText: {
    color: colors.Grey900,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

export default Acknowledgment;
