'use strict';

import React, {Component, PureComponent} from 'react';
import {View, Text, Platform, Image} from 'react-native';

import ProgressView from './ProgressView';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';
import DynamicText from '../components/DynamicText';

var moment = require('moment');

export default class BookCell extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let bookName = '';
    let name = '';
    let chapterNumber = '';

    if (this.props.bookName != null) {
      bookName = this.props.bookName;
    }
    if (this.props.chapterNumber) {
      chapterNumber = this.props.chapterNumber;
    }

    if (this.props.sectionName != null) {
      name = this.props.sectionName;
    } else if (this.props.chapterName != null) {
      name = this.props.chapterName;
    }

    // highlight the name
    let nameLayout = [];
    if (
      name.length > 0 &&
      this.props.searchText &&
      this.props.searchText.length > 0
    ) {
      let highlightedNameParts = name
        .toLowerCase()
        .split(this.props.searchText.toLowerCase());

      let index = 0;

      for (
        let highlightedNamePartIndex = 0;
        highlightedNamePartIndex < highlightedNameParts.length;
        highlightedNamePartIndex++
      ) {
        let highlightedNamePart =
          highlightedNameParts[highlightedNamePartIndex];

        if (highlightedNamePartIndex !== 0) {
          let correctCaseHighlight = name.substr(
            index,
            this.props.searchText.length,
          );

          nameLayout.push(
            <Text
              key={'bookCellHighlight-' + highlightedNamePartIndex}
              style={{backgroundColor: '#DDEBFD'}}>
              {correctCaseHighlight}
            </Text>,
          );
          index += this.props.searchText.length;
        }

        let correctCaseNamePart = name.substr(
          index,
          highlightedNamePart.length,
        );
        nameLayout.push(<Text>{correctCaseNamePart}</Text>);

        index += highlightedNamePart.length;
      }
    } else {
      nameLayout = <Text>{name}</Text>;
    }

    let matchingText = '';
    if (this.props.titleMatch) {
      matchingText += 'Title match';
    }
    if (this.props.pageMatches > 0) {
      if (matchingText !== '') {
        matchingText += ' & ';
      }
      matchingText += this.props.pageMatches + ' page ';
      matchingText += this.props.pageMatches === 1 ? 'match' : 'matches';
    }

    return (
      <View
        style={{
          paddingLeft: 10,
          paddingRight: 6,
          paddingVertical: 15,
          borderBottomWidth: 1,
          borderColor: colors.Grey100,
        }}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{flex: 1}}>
            <View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image
                  style={{width: 24, height: 24}}
                  source={require('../assets/Icon_Quizzes_Blue.png')}
                />
                <View
                  style={{
                    marginHorizontal: 6,
                    width: 2,
                    height: 2,
                    backgroundColor: colors.Grey700,
                    borderRadius: 1,
                  }}
                />
                <DynamicText
                  numberOfLines={1}
                  style={{
                    flexShrink: 1,
                    fontSize: 12,
                    fontWeight: '600',
                    color: '#00736d',
                  }}>
                  {bookName}
                </DynamicText>
                <View
                  style={{
                    marginHorizontal: 6,
                    width: 2,
                    height: 2,
                    backgroundColor: colors.Grey700,
                    borderRadius: 1,
                  }}
                />
                <DynamicText
                  style={{
                    marginRight: 10,
                    fontSize: 12,
                    fontWeight: '600',
                    color: colors.Grey800,
                  }}>
                  Chapter {chapterNumber}
                </DynamicText>
              </View>

              <View style={{flexDirection: 'row', marginTop: 4}}>
                <View style={{flex: 1}}>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 19,
                      fontWeight: '600',
                      letterSpacing: -0.04,
                      color: colors.Grey900,
                    }}>
                    {nameLayout}
                  </DynamicText>
                  {matchingText !== '' ? (
                    <DynamicText
                      style={{
                        flex: 1,
                        marginTop: 8,
                        fontSize: 15,
                        fontWeight: '600',
                        letterSpacing: -0.04,
                        color: colors.Grey700,
                      }}>
                      {matchingText}
                    </DynamicText>
                  ) : null}
                </View>
                <Image
                  style={{width: 26, height: 26}}
                  source={require('../assets/Button_Disclosure_Right_Blue.png')}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
