import React, {useState, forwardRef, useRef, useEffect} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  UIManager,
  Platform,
} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import {colors} from '../../classes/Colors';
import BackgroundContainer from './BackgroundContainer';
import {logAnalyticsEvent} from '../../utils/firebase';
import {psirfAnalyticsEventNames, buttonEvents} from '../../constants/psirf';
import CustomMarkdown from '../CustomMarkdown';

const Learning = forwardRef(({content, psirfMetaData}, ref) => {
  const [showButton, setShowButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const maxHeight = 265;

  const backgroundRef = useRef();
  const contentRef = useRef();

  // Enable LayoutAnimation on Android
  if (
    Platform.OS === 'android' &&
    UIManager.setLayoutAnimationEnabledExperimental
  ) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
    logAnalyticsEvent(psirfAnalyticsEventNames.button, {
      event: isExpanded
        ? buttonEvents.learningShowLess
        : buttonEvents.learningShowMore,
      psirf_id: psirfMetaData.psirfId,
    });
  };

  return (
    <BackgroundContainer ref={backgroundRef} color="white">
      <View style={styles.container}>
        <View
          onLayout={event => {
            const {height} = event.nativeEvent.layout || {};
            setShowButton(Math.ceil(height) >= maxHeight);
          }}
          style={[
            styles.contentContainer,
            {maxHeight: isExpanded ? undefined : maxHeight},
          ]}
          ref={contentRef}>
          <CustomMarkdown>{content}</CustomMarkdown>
        </View>
        {showButton && (
          <TouchableOpacity onPress={toggleExpand} style={styles.button}>
            <Text style={styles.buttonText}>
              {isExpanded ? 'Show Less' : 'Show More'}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </BackgroundContainer>
  );
});

const styles = StyleSheet.create({
  container: {
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 27,
    alignSelf: 'center',
  },
  contentContainer: {
    overflow: 'hidden',
  },
  button: {
    margin: 20,
    alignSelf: 'center',
    backgroundColor: '#F1F3F5',
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  buttonText: {
    color: colors.PrimaryBlue,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

export default Learning;
