import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {containerWidths} from '../../constants/containerWidths';
import {colors} from '../../classes/Colors';

const Summary = ({content}) => {
  return (
    <View style={styles.background}>
      <View style={styles.container}>
        <Text style={styles.title}>{content.Title}</Text>
        <View style={styles.bodyContainer}>
          <Text style={styles.body}>{content.Body}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: colors.PSIRFBackground,
    paddingHorizontal: 16,
  },
  container: {
    paddingTop: 15,
    paddingBottom: 32,
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    color: colors.white,
    paddingBottom: 16,
  },
  body: {
    fontSize: 17,
    fontWeight: '400',
    lineHeight: 20.57,
    color: colors.white,
  },
  bodyContainer: {
    width: '100%',
    padding: 14,
    backgroundColor: 'rgba(0, 115, 109, 0.5)',
    borderRadius: 7,
    justifyContent: 'center',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {width: 0, height: 4},
    shadowRadius: 4,
    shadowOpacity: 1,
  },
});

export default Summary;
