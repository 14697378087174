'use strict';

import React, {Component} from 'react';
import {Text, Platform} from 'react-native';

import {EventRegister} from 'react-native-event-listeners';
import LayoutController from '../classes/LayoutController';

class DynamicText extends Component {
  state: {
    overallStyle: [],
    fontSize: number,
  };

  constructor(props) {
    super(props);
    this.state = {
      overallStyle: [],
      fontSize: 17,
    };
    // Put your default font styles here.
    this.style = this.defaultStyle();
    if (props.style) {
      if (Array.isArray(props.style)) {
        this.style = this.style.concat(props.style);
      } else {
        this.style.push(props.style);
      }
    }
  }

  async componentDidMount() {
    var that = this;
  }

  componentWillUnmount() {
    // EventRegister.removeEventListener(this.fontSizeUpdatedListener);
  }

  defaultStyle() {
    if (Platform.OS === 'web') {
      return [];
    } else {
      return [];
    }
  }

  async checkFontSize() {
    var fontSize = global.customFontSize;

    var newFontSize = fontSize;

    if (
      this.style != null &&
      this.style.length != null &&
      this.style.length > 0
    ) {
      if (this.style[0].fontSize != null) {
        newFontSize =
          fontSize +
          (this.style[0].fontSize - LayoutController.defaultFontSize()); // do this so fonts that are bigger remain bigger when scaled
      }
    }

    if (newFontSize != this.state.fontSize) {
      this.setState({fontSize: newFontSize});
    }
  }

  render() {
    // this.checkFontSize();

    var fontSize = global.customFontSize;

    var newFontSize = fontSize;

    if (
      this.style != null &&
      this.style.length != null &&
      this.style.length > 0
    ) {
      if (this.style[0].fontSize != null) {
        newFontSize =
          fontSize +
          (this.style[0].fontSize - LayoutController.defaultFontSize()); // do this so fonts that are bigger remain bigger when scaled
      }
    }

    return (
      <Text
        {...this.props}
        allowFontScaling={false}
        style={[this.style, {fontSize: newFontSize}]}>
        {this.props.children}
      </Text>
    );
  }
}

export default DynamicText;
