import React, {useState} from 'react';

import {View, Text, StyleSheet, TouchableOpacity, Image} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import {colors} from '../../classes/Colors';
import BackgroundContainer from './BackgroundContainer';

import {logAnalyticsEvent} from '../../utils/firebase';
import {psirfAnalyticsEventNames, buttonEvents} from '../../constants/psirf';

const FurtherReading = ({content, psirfMetaData}) => {
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? content : content.slice(0, 3);
  const iconSource = require('../../assets/PSIRF/PSIRF_book_green.png');
  const lastItemIndex = itemsToShow.length - 1;
  const handleToggleShowAll = () => {
    logAnalyticsEvent(psirfAnalyticsEventNames.button, {
      event: showAll
        ? buttonEvents.furtherReadingShowLess
        : buttonEvents.furtherReadingShowMore,
      psirf_id: psirfMetaData.psirfId,
    });
    setShowAll(prev => !prev);
  };
  return (
    <BackgroundContainer color="white">
      <View style={styles.container}>
        <Text style={styles.subtitle}>REFERENCES & RECOMMENDATIONS</Text>
        <Text style={styles.title}>For Further Reading</Text>
        <View style={styles.readingContainer}>
          {itemsToShow.map((reading, index) => {
            const isNotLastItem = lastItemIndex !== index;
            return (
              <View
                key={reading.ID}
                style={[styles.row, isNotLastItem && styles.rowBorder]}>
                <Image style={styles.icon} source={iconSource} />
                <Text style={styles.reading}>{reading.Title}</Text>
              </View>
            );
          })}
        </View>
        {content.length > 3 && (
          <TouchableOpacity
            style={styles.button}
            underlayColor="#F1F3F5"
            onPress={handleToggleShowAll}>
            <Text style={styles.buttonText}>
              {showAll ? 'Show Less' : 'Show More'}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
    color: colors.black,
    paddingBottom: 4,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'left',
    color: colors.black,
    paddingBottom: 20,
  },
  container: {
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    alignSelf: 'center',
  },
  readingContainer: {},
  row: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 18,
  },
  rowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#F2F2F2',
  },
  icon: {
    marginRight: 12,
    width: 36,
    height: 36,
  },
  reading: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 17,
    flexShrink: 1,
    color: colors.black,
  },
  button: {
    margin: 20,
    alignSelf: 'center',
    backgroundColor: '#F1F3F5',
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  buttonText: {
    color: colors.PrimaryBlue,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

export default FurtherReading;
