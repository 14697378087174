import React from 'react';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export default function AppWrapper(props) {
  const insets = useSafeAreaInsets();

  return (
    <View style={{flex: 1}}>
      {props.children}
      <View style={{backgroundColor: 'white', height: insets.bottom}}></View>
    </View>
  );
}
