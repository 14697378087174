/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Image,
} from 'react-native';

import NavigationStack from './NavigationStack';
import {colors} from '../classes/Colors';
import {ScreensWithTabBarHidden} from '../classes/Tabs';

type Props = {};

type State = {
  selectedTabIndex: number,
  navigationKey: number,
  showTabBar: boolean,
};

export default class Navigation extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
      navigationKey: 0,
      showTabBar: true,
      tabs: this.getTabs(),
    };
  }

  componentDidMount() {}

  componentDidUpdate(previousProps) {
    if (
      this.props.screenProps.showBooksTab !==
      previousProps.screenProps.showBooksTab
    ) {
      this.setState({tabs: this.getTabs()});
    }
  }

  getTabs() {
    let tabs = [];

    for (let tabIndex = 0; tabIndex < this.props.tabs.length; tabIndex++) {
      var tab = this.props.tabs[tabIndex];

      // show books tab and hide standby tab if trust has books
      if (this.props.screenProps.showBooksTab) {
        if (tab.Name === 'Standby CPD' || tab.Name === 'Case Studies') {
          continue; // don't include tab
        }
      } else {
        if (tab.Name === 'Books' || tab.Name === 'Case Studies and Standby') {
          continue;
        }
      }

      tabs.push(tab);
    }

    return tabs;
  }

  tabSelected(index: number) {
    if (this.state.selectedTabIndex !== index) {
      this.setState({
        selectedTabIndex: index,
        navigationKey: this.state.navigationKey + 1,
      });
    }
  }

  switchToTabWithScreen(screenName: string, params, currentScreenState) {
    for (let tabIndex = 0; tabIndex < this.state.tabs.length; tabIndex++) {
      var tab = this.state.tabs[tabIndex];

      if (tab.Screens[screenName] != null) {
        this.tabSelected(tabIndex);
        setTimeout(() => {
          this.navigate(screenName, params, {});
        }, 100);
        break;
      }
    }
  }

  showTabBar(shouldShow) {
    if (this.state.showTabBar !== shouldShow) {
      this.setState({showTabBar: shouldShow});
    }
  }

  navigate(screenName, params, currentScreenState) {
    let selectedTabIndex = this.state.selectedTabIndex;

    if (this.props.screenProps.showBooksTab) {
      if (selectedTabIndex === 0) {
        this._dashboardNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 1) {
        this._quizNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 2) {
        this._caseStudiesNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 3) {
        this._booksNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 4) {
        this._resultsNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      }
    } else {
      if (selectedTabIndex === 0) {
        this._dashboardNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 1) {
        this._quizNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 2) {
        this._standbyNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 3) {
        this._caseStudiesNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      } else if (selectedTabIndex === 4) {
        this._resultsNavigationStack.navigate(
          screenName,
          params,
          currentScreenState,
        );
      }
    }
  }

  push(screenName, params, currentScreenState) {
    this.navigate(screenName, params, currentScreenState);
  }

  setNavigationStackReference(tabIndex, c) {
    if (this.props.screenProps.showBooksTab) {
      if (tabIndex === 0) {
        this._dashboardNavigationStack = c;
      } else if (tabIndex === 1) {
        this._quizNavigationStack = c;
      } else if (tabIndex === 2) {
        this._caseStudiesNavigationStack = c;
      } else if (tabIndex === 3) {
        this._booksNavigationStack = c;
      } else if (tabIndex === 4) {
        this._resultsNavigationStack = c;
      }
    } else {
      if (tabIndex === 0) {
        this._dashboardNavigationStack = c;
      } else if (tabIndex === 1) {
        this._quizNavigationStack = c;
      } else if (tabIndex === 2) {
        this._standbyNavigationStack = c;
      } else if (tabIndex === 3) {
        this._caseStudiesNavigationStack = c;
      } else if (tabIndex === 4) {
        this._resultsNavigationStack = c;
      }
    }
  }

  tabIsVisible() {}

  render() {
    var tabsLayout = [];
    var navigationStacksLayout = [];

    var selectedTab = this.state.tabs[this.state.selectedTabIndex];

    for (let tabIndex = 0; tabIndex < this.state.tabs.length; tabIndex++) {
      var tab = this.state.tabs[tabIndex];

      var isActive = tabIndex === this.state.selectedTabIndex ? true : false;

      // show books tab and hide standby tab if trust has books
      if (this.props.screenProps.showBooksTab) {
        if (tab.Name === 'Standby CPD' || tab.Name === 'Case Studies') {
          continue; // don't include tab
        }
      } else {
        if (tab.Name === 'Books' || tab.Name === 'Case Studies and Standby') {
          continue;
        }
      }

      var tabTextColor = colors.Grey500;

      if (isActive) {
        tabTextColor = colors.ButtonBlue;
      }

      var tabImageLayout = null;

      if (tab.Name === 'Dashboard') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Dashboard_1_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Dashboard_2_Inactive.png')}
          />
        );
      } else if (tab.Name === 'Quizzes') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Quizzes_1_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Quizzes_2_Inactive.png')}
          />
        );
      } else if (tab.Name === 'Standby CPD') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Standby_1_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Standby_2_Inactive.png')}
          />
        );
      } else if (tab.Name === 'Case Studies') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Case_Studies_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Case_Studies_Inactive.png')}
          />
        );
      } else if (tab.Name === 'Case Studies and Standby') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Case_Studies_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Case_Studies_Inactive.png')}
          />
        );
      } else if (tab.Name === 'Books') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Books_1_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Books_2_Inactive.png')}
          />
        );
      } else if (tab.Name === 'Results') {
        tabImageLayout = isActive ? (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Results_Active.png')}
          />
        ) : (
          <Image
            style={{width: 48, height: 32}}
            source={require('../assets/Tab_Results_Inactive.png')}
          />
        );
      }

      var tabLayout = (
        <TouchableOpacity
          key={tabIndex + ''}
          activeOpacity={0.8}
          style={{flexDirection: 'row', justifyContent: 'center'}}
          onPress={this.tabSelected.bind(this, tabIndex)}>
          <View
            style={{
              paddingHorizontal: 20,
              marginHorizontal: 10,
              paddingVertical: 4,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {tabImageLayout}
            <Text style={{color: tabTextColor, fontSize: 12}}>{tab.Name}</Text>
          </View>
        </TouchableOpacity>
      );

      tabsLayout.push(tabLayout);

      var navigationStackLayout = (
        <NavigationStack
          ref={c => this.setNavigationStackReference(tabIndex, c)}
          key={tabIndex}
          isActive={isActive}
          showTabBar={this.showTabBar.bind(this)}
          screens={tab.Screens}
          tab={tab}
          tabIndex={tabIndex}
          switchToTabWithScreen={this.switchToTabWithScreen.bind(this)}
          screenProps={this.props.screenProps}
        />
      );

      navigationStacksLayout.push(navigationStackLayout);
    }

    var tabBarLayout = null;

    if (this.state.showTabBar) {
      tabBarLayout = (
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: '#FFF',
            justifyContent: 'center',
            alignItems: 'stretch',
            borderTopWidth: 1,
            borderColor: '#D8D8D8',
          }}>
          {tabsLayout}
        </View>
      );
    }

    return (
      <View style={styles.container}>
        {navigationStacksLayout}
        {tabBarLayout}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
});
