/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import ProgressView from '../components/ProgressView';
import DropDown from '../components/DropDown';
import LoadingBar from '../components/LoadingBar';
import DynamicText from '../components/DynamicText';

import {colors} from '../classes/Colors';

var _ = require('lodash');

type Props = {};

type State = {
  quizSets: [],
};

export default class ResultsDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: 'Quiz Results',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        quizSets: this.props.screenProps.quizSets,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.props.screenProps.quizSets != null) {
        this.updateQuizSets(this.props.screenProps.quizSets);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEqual(
        this.props.screenProps.quizSets,
        prevProps.screenProps.quizSets,
      ) === false ||
      _.isEqual(
        this.props.screenProps.results,
        prevProps.screenProps.results,
      ) === false
    ) {
      this.updateQuizSets(this.props.screenProps.quizSets);
    }
  }

  updateQuizSets(quizSets) {
    this.setState({quizSets: quizSets}, function () {});
  }

  goToAllQuizResultsList() {
    this.props.navigation.navigate('AllResults', {}, this.state);
  }

  quizTypeTapped(quizType: Object) {
    this.props.navigation.navigate(
      'QuizTypeResults',
      {
        title: quizType.title,
        quizSets: quizType.quizSets,
        questionCount: quizType.questionCount,
      },
      this.state,
    );
  }

  render() {
    var topicQuizSets = [];
    var guidelineQuizSets = [];
    var sectionQuizSets = [];
    var standbyQuizSets = [];
    var caseStudyQuizSets = [];
    var pgdQuizSets = [];
    var pmaQuizSets = [];

    var topicQuestionCount = 0;
    var guidelineQuestionCount = 0;
    var sectionQuestionCount = 0;
    var standbyQuestionCount = 0;
    var caseStudyQuestionCount = 0;
    var pgdQuestionCount = 0;
    var pmaQuestionCount = 0;

    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var totalQuestionCount = 0;

    // total question count is number of questions in topics (general and PGD), standby and case studies combined
    for (
      let topicIndex = 0;
      topicIndex < this.props.screenProps.topics.length;
      topicIndex++
    ) {
      var topic = this.props.screenProps.topics[topicIndex];

      if (
        this.props.screenProps.validPPPSubscription ||
        topic.Type === 'PGD' ||
        topic.Type === 'PMA'
      ) {
        totalQuestionCount = totalQuestionCount + topic.Questions.length;
        topicQuestionCount += topic.Questions.length;

        for (
          let questionIndex = 0;
          questionIndex < topic.Questions.length;
          questionIndex++
        ) {
          var question = topic.Questions[questionIndex];

          if (question.Reference != null) {
            guidelineQuestionCount++;
          }

          if (question.Section != null) {
            sectionQuestionCount++;
          }
        }

        if (topic.Type === 'PGD') {
          pgdQuestionCount += topic.Questions.length;
        } else if (topic.Type === 'PMA') {
          pmaQuestionCount += topic.Questions.length;
        }
      }
    }

    for (
      let issueIndex = 0;
      issueIndex < this.props.screenProps.issues.length;
      issueIndex++
    ) {
      var issue = this.props.screenProps.issues[issueIndex];

      if (this.props.screenProps.validPPPSubscription) {
        totalQuestionCount = totalQuestionCount + issue.Questions.length;
        standbyQuestionCount += issue.Questions.length;
      }
    }

    for (
      let caseStudyIndex = 0;
      caseStudyIndex < this.props.screenProps.caseStudies.length;
      caseStudyIndex++
    ) {
      var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];

      if (this.props.screenProps.validPPPSubscription) {
        totalQuestionCount = totalQuestionCount + caseStudy.Questions.length;
        caseStudyQuestionCount += caseStudy.Questions.length;
      }
    }

    var percentageText = '0%';

    for (
      var quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.state.quizSets[quizSetIndex];

      // don't include quiz sets that haven't had any quizzes taken
      var quizFoundForQuizSet = false;

      for (
        let quizIndex = 0;
        quizIndex < this.props.screenProps.quizzes.length;
        quizIndex++
      ) {
        let quiz = this.props.screenProps.quizzes[quizIndex];

        if (quiz.quizSet === quizSet.name) {
          quizFoundForQuizSet = true;
          break;
        }
      }

      if (quizFoundForQuizSet === false) {
        continue;
      }

      if (quizSet.type === 'topic') {
        // totalQuestionCount += quizSet.questions.length;

        for (
          var topicIndex = 0;
          topicIndex < this.props.screenProps.topics.length;
          topicIndex++
        ) {
          var topic = this.props.screenProps.topics[topicIndex];

          if (quizSet.topicName === topic.PackName) {
            if (topic.Type === 'PGD') {
              pgdQuizSets.push(quizSet);
            } else if (topic.Type === 'PMA') {
              pmaQuizSets.push(quizSet);
            } else {
              topicQuizSets.push(quizSet);
            }
          }
        }
      } else if (quizSet.type === 'guideline') {
        guidelineQuizSets.push(quizSet);
      } else if (quizSet.type === 'section') {
        sectionQuizSets.push(quizSet);
      } else if (quizSet.type === 'standby') {
        standbyQuizSets.push(quizSet);
      } else if (quizSet.type === 'caseStudy') {
        caseStudyQuizSets.push(quizSet);
      }

      var latestResultsForQuizSet =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      if (latestResultsForQuizSet != null) {
        for (
          let resultIndex = 0;
          resultIndex < latestResultsForQuizSet.length;
          resultIndex++
        ) {
          var result = latestResultsForQuizSet[resultIndex];

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }
        }
      }
    }

    unansweredCount = totalQuestionCount - correctCount - incorrectCount;

    if (correctCount + incorrectCount > 0) {
      percentageText =
        Math.floor((correctCount / (correctCount + incorrectCount)) * 100) +
        '%';
    }

    const keys = ['unanswered', 'incorrect', 'correct'];
    const values = [unansweredCount, incorrectCount, correctCount];
    const pieChartColors = [colors.Grey400, colors.Red500, colors.Green500];

    var quizTypes = [
      {
        title: 'Topics',
        subtitle: 'Topics',
        subtitleSingular: 'Topic',
        icon: require('../assets/Results_Glyph_Quiz.png'),
        quizSets: topicQuizSets,
        questionCount: topicQuestionCount,
      },
      {
        title: 'JRCALC Guidelines',
        subtitle: 'Guidelines',
        subtitleSingular: 'Guideline',
        icon: require('../assets/Results_Glyph_Quiz.png'),
        quizSets: guidelineQuizSets,
        questionCount: guidelineQuestionCount,
      },
      {
        title: 'Sections',
        subtitle: 'Sections',
        subtitleSingular: 'Section',
        icon: require('../assets/Results_Glyph_Quiz.png'),
        quizSets: sectionQuizSets,
        questionCount: sectionQuestionCount,
      },
      {
        title: 'Standby CPDs',
        subtitle: 'Standby CPDs',
        subtitleSingular: 'Standby CPD',
        icon: require('../assets/Results_Glyph_Standby.png'),
        quizSets: standbyQuizSets,
        questionCount: standbyQuestionCount,
      },
      {
        title: 'Case Studies',
        subtitle: 'Case Studies',
        subtitleSingular: 'Case Study',
        icon: require('../assets/Results_Glyph_CaseStudy.png'),
        quizSets: caseStudyQuizSets,
        questionCount: caseStudyQuestionCount,
      },
      {
        title: 'PGDs',
        subtitle: 'PGDs',
        subtitleSingular: 'PGD',
        icon: require('../assets/Results_Glyph_PGD.png'),
        quizSets: pgdQuizSets,
        questionCount: pgdQuestionCount,
      },
      {
        title: 'PMAs',
        subtitle: 'PMAs',
        subtitleSingular: 'PMA',
        icon: require('../assets/Results_Glyph_PGD.png'),
        quizSets: pmaQuizSets,
        questionCount: pmaQuestionCount,
      },
    ];

    var quizTypesLayout = [];

    for (
      let quizTypeIndex = 0;
      quizTypeIndex < quizTypes.length;
      quizTypeIndex++
    ) {
      let quizType = quizTypes[quizTypeIndex];

      var subtitle = quizType.subtitle;
      if (quizType.quizSets.length === 1) {
        subtitle = quizType.subtitleSingular;
      }

      quizTypesLayout.push(
        <TouchableOpacity
          activeOpacity={0.5}
          key={quizType.title}
          onPress={this.quizTypeTapped.bind(this, quizType)}>
          <View
            style={{
              padding: 14,
              backgroundColor: colors.white,
              borderBottomWidth: 1,
              borderColor: colors.Grey300,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View
                style={{
                  width: 40,
                  height: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f0faf9',
                  borderWidth: 2,
                  borderColor: '#72b1ad',
                  borderRadius: 20,
                }}>
                <Image style={{width: 32, height: 32}} source={quizType.icon} />
              </View>

              <View style={{flex: 1, marginLeft: 15}}>
                <DynamicText
                  style={{
                    marginBottom: 4,
                    fontSize: 19,
                    fontWeight: '600',
                    color: colors.Grey900,
                  }}>
                  {quizType.title}
                </DynamicText>
                <DynamicText
                  style={{
                    fontSize: 14,
                    fontWeight: '500',
                    color: colors.Grey700,
                  }}>
                  {quizType.quizSets.length} {subtitle}
                </DynamicText>
              </View>

              <Image
                style={{width: 24, height: 24}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </View>
          </View>
        </TouchableOpacity>,
      );
    }

    return (
      <ScreenComponent style={styles.container}>
        <LoadingBar screenProps={this.props.screenProps} />

        <ScrollView
          ref={ref => (this._scrollView = ref)}
          style={{flex: 1}}
          contentContainerStyle={{paddingHorizontal: 10, paddingTop: 10}}>
          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingHorizontal: 10, paddingVertical: 10}}>
              <DynamicText
                style={{
                  fontSize: 15,
                  letterSpacing: -0.24,
                  color: colors.darkGrey,
                  fontWeight: '700',
                }}>
                Results overview
              </DynamicText>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 16,
                }}>
                <View>
                  <DynamicText
                    style={{
                      fontSize: 12,
                      fontWeight: '700',
                      letterSpacing: -0.31,
                      color: colors.Grey700,
                    }}>
                    Correct
                  </DynamicText>
                  <DynamicText
                    style={{
                      marginTop: 8,
                      fontSize: 34,
                      fontWeight: '700',
                      color: colors.Green500,
                    }}>
                    {correctCount}{' '}
                    <DynamicText
                      style={{
                        fontSize: 15,
                        fontWeight: '600',
                        color: colors.Grey700,
                      }}>
                      ({percentageText})
                    </DynamicText>
                  </DynamicText>
                </View>
                <View style={{alignItems: 'flex-end'}}>
                  <DynamicText
                    style={{
                      fontSize: 12,
                      fontWeight: '700',
                      letterSpacing: -0.31,
                      color: colors.Grey700,
                    }}>
                    Incorrect
                  </DynamicText>
                  <DynamicText
                    style={{
                      marginTop: 8,
                      fontSize: 34,
                      fontWeight: '600',
                      color: colors.Red500,
                    }}>
                    {incorrectCount}
                  </DynamicText>
                </View>
              </View>

              <ProgressView
                style={{
                  flex: 1,
                  marginTop: 8,
                  height: 4,
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
                correctAnswers={correctCount}
                incorrectAnswers={incorrectCount}
                unansweredQuestions={0}
              />

              <DynamicText
                style={{
                  marginTop: 16,
                  fontSize: 12,
                  fontWeight: '700',
                  letterSpacing: -0.31,
                  color: colors.Grey700,
                }}>
                Total questions answered
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 4,
                  fontSize: 20,
                  fontWeight: '600',
                  color: colors.darkGrey,
                }}>
                {correctCount + incorrectCount} of {totalQuestionCount}
              </DynamicText>

              <View
                style={{
                  width: '50%',
                  height: 1,
                  marginTop: 15,
                  marginBottom: 7,
                  backgroundColor: colors.Grey300,
                }}
              />

              <DynamicText
                style={{
                  marginTop: 16,
                  fontSize: 12,
                  fontWeight: '700',
                  letterSpacing: -0.31,
                  color: colors.Grey700,
                }}>
                Total quizzes taken
              </DynamicText>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 4,
                }}>
                <DynamicText
                  style={{
                    fontSize: 20,
                    fontWeight: '600',
                    color: colors.darkGrey,
                  }}>
                  {this.props.screenProps.quizzes.length}
                </DynamicText>

                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'flex-end',
                  }}
                  onPress={this.goToAllQuizResultsList.bind(this)}>
                  <DynamicText
                    style={{
                      marginRight: 0,
                      fontSize: 14,
                      fontWeight: 'bold',
                      letterSpacing: -0.2,
                      color: colors.ButtonBlue,
                      textAlign: 'right',
                    }}>
                    View all results
                  </DynamicText>
                  <Image
                    style={{width: 24, height: 24}}
                    source={require('../assets/Button_Disclosure_Right_Blue.png')}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View
              style={{
                marginTop: 4,
                padding: 14,
                borderBottomWidth: 1,
                borderColor: colors.Grey300,
              }}>
              <DynamicText
                style={{
                  fontSize: 15,
                  fontWeight: '700',
                  color: colors.darkGrey,
                }}>
                View results by category
              </DynamicText>
            </View>
            {quizTypesLayout}
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
