const buttons = {
  moreInfo: 'More information',
  collected: 'Collected',
  completed: 'Claimed my free hot drink',
  initial: 'Take a quiz',
};

const titles = {
  initial: 'Claim a free hot drink!',
  completed: 'Congratulations!\nHave a free hot drink on us.',
  collected: 'How was your hot drink?',
};

const subtitles = {
  initial:
    'Complete any quiz and be rewarded with a free Regular Hot Drink from Greggs.',
  completed:
    'Simply pop into your local Greggs, order your Regular Hot Drink and show this code at the till.',
  collected:
    'Thanks for taking the ParaPass quiz, come back soon to help progress your CPD.',
};

export const greggsPromotionStrings = {
  header: 'REWARD',
  buttons,
  titles,
  subtitles,
};

export const getStringsForPromoCard = (promoData, hasCollected) => {
  if (hasCollected) {
    return {
      title: titles.collected,
      subtitle: subtitles.collected,
      buttonTitle: buttons.collected,
    };
  }

  if (promoData?.promoCode) {
    return {
      title: titles.completed,
      subtitle: subtitles.completed,
      buttonTitle: buttons.completed,
    };
  }

  return {
    title: titles.initial,
    subtitle: subtitles.initial,
    buttonTitle: buttons.initial,
  };
};

export const formatPromotionData = date => {
  return date
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(',', ' -');
};

export const getGreggsPromotionDateStr = expiryDate => {
  const date = new Date(expiryDate);
  return expiryDate
    ? ` \u2022 Offer valid until ${formatPromotionData(date)}`
    : '';
};

// info and qrCode are associated to the modal in handlePromoModalToShow
// double check these when changing them
export const analyticsEventNames = {
  claimed: 'neas_greggs_promotion_claimed',
  info: 'neas_greggs_promotion_more_info',
  takeQuiz: 'neas_greggs_promotion_take_quiz',
  qrCode: 'neas_greggs_promotion_qr_code_viewed',
};
