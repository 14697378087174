import React from 'react';

import {View, Text, StyleSheet} from 'react-native';

import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';

const TrustTag = ({trust}) => {
  return (
    <View
      style={[
        styles.tag,
        {backgroundColor: DataController.getColorForTrust(trust)},
      ]}>
      <Text style={styles.tagText}>
        {DataController.getTrustDisplayName(trust)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  tag: {
    marginBottom: 5,
    alignSelf: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 6,
    height: 16,
    borderRadius: 2,
  },
  tagText: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.1,
    color: colors.white,
  },
});

export default TrustTag;
