import React from 'react';

import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import LayoutController from '../../classes/LayoutController';
import {colors} from '../../classes/Colors';
import BackgroundContainer from './BackgroundContainer';

const Quiz = ({content, onPress}) => {
  const isTablet = LayoutController.isTablet();
  const styles = isTablet ? tabletStyles : mobileStyles;
  return (
    <BackgroundContainer color="green">
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={sharedStyles.subtitle}>QUIZ</Text>
          <Text style={sharedStyles.quizSubTitle}>{content.QuizSubTitle}</Text>
          <Text style={sharedStyles.quizTitle}>{content.QuizTitle}</Text>
        </View>
        <TouchableOpacity style={sharedStyles.button} onPress={onPress}>
          <Text style={sharedStyles.buttonText}>Take the quiz</Text>
        </TouchableOpacity>
      </View>
    </BackgroundContainer>
  );
};

const sharedStyles = StyleSheet.create({
  quizSubTitle: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'left',
    color: colors.white,
    paddingBottom: 20,
  },
  subtitle: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
    color: 'rgba(255, 255, 255, 0.8)',
    paddingBottom: 4,
  },
  quizTitle: {
    fontSize: 30,
    fontWeight: '700',
    lineHeight: 36.31,
    textAlign: 'left',
    color: colors.white,
  },
  button: {
    marginTop: 20,
    alignSelf: 'center',
    backgroundColor: colors.white,
    width: '100%',
    padding: 14,
    borderRadius: 7,
    maxWidth: 336,
  },
  buttonText: {
    color: colors.black,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    marginVertical: 32,
    width: '100%',
  },
});

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    marginVertical: 66,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  textContainer: {
    maxWidth: 336,
  },
});

export default Quiz;
