import React from 'react';

import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import {colors} from '../../classes/Colors';
import BackgroundContainer from './BackgroundContainer';
import {logAnalyticsEvent} from '../../utils/firebase';
import {psirfAnalyticsEventNames, buttonEvents} from '../../constants/psirf';

const getLinkType = link => {
  switch (link.Type) {
    case 'url':
      return Linking.openURL(link.url);
    case 'email':
      return Linking.openURL(`mailto:${link.email}`);
    default:
      return;
  }
};

const getIconSource = link => {
  switch (link.Type) {
    case 'url':
      return require('../../assets/PSIRF/PSIRF_book_green.png');
    case 'email':
      return require('../../assets/PSIRF/PSIRF_mail_green.png');
    default:
      return require('../../assets/PSIRF/PSIRF_book_green.png');
  }
};

const UsefulLinks = ({content}) => {
  const lastItemIndex = content.length - 1;
  return (
    <BackgroundContainer color="grey">
      <View style={styles.container}>
        <Text style={styles.title}>Useful Links</Text>
        <View style={styles.linkContainer}>
          {content.map((link, index) => {
            const isNotLastItem = lastItemIndex !== index;
            const iconSource = getIconSource(link);
            const onPressHandler = () => {
              logAnalyticsEvent(psirfAnalyticsEventNames.button, {
                event: buttonEvents.usefulLinkPress,
                link_id: link.ID,
              });
              return getLinkType(link);
            };
            return (
              <TouchableOpacity
                key={link.ID}
                style={[styles.row, isNotLastItem && styles.rowBorder]}
                onPress={onPressHandler}>
                <Image style={styles.icon} source={iconSource} />
                <Text style={styles.link}>{link.Title}</Text>
                <Image
                  style={styles.linkIcon}
                  source={require('../../assets/PSIRF/PSIRF_link_blue.png')}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'left',
    color: colors.black,
    padding: 16,
  },
  container: {
    shadowColor: 'rgba(0, 0, 0, 0.09)',
    shadowOffset: {width: 0, height: 4},
    shadowRadius: 4,
    shadowOpacity: 1,
    backgroundColor: colors.white,
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    borderRadius: 5,
    alignSelf: 'center',
  },
  linkContainer: {},
  row: {
    width: '100%',
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
  },
  rowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#F2F2F2',
  },
  icon: {
    marginRight: 12,
    width: 36,
    height: 36,
  },
  linkIcon: {
    marginLeft: 'auto',
    width: 36,
    height: 36,
  },
  link: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 17,
    flexShrink: 1,
    color: colors.black,
  },
  button: {
    margin: 20,
    alignSelf: 'center',
    backgroundColor: '#F1F3F5',
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  buttonText: {
    color: colors.PrimaryBlue,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

export default UsefulLinks;
