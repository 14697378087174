import React from 'react';

import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import {colors} from '../../classes/Colors';
import BackgroundContainer from './BackgroundContainer';
import {addToParafolio} from '../../utils/links';
import {logAnalyticsEvent} from '../../utils/firebase';
import {psirfAnalyticsEventNames, buttonEvents} from '../../constants/psirf';

const description = 'Questions To Prompt Reflection';

const ReflectiveQuestions = ({content, psirfMetaData}) => {
  const onPressHandler = () => {
    logAnalyticsEvent(psirfAnalyticsEventNames.button, {
      event: buttonEvents.parafolioReflection,
      psirf_id: psirfMetaData.psirfId,
    });
    const reflection = content
      .map(({QuestionBody}) => QuestionBody)
      .join('<br /><br />');

    addToParafolio(
      description,
      null,
      psirfMetaData.title,
      new Date(),
      reflection, // This reflection string is now URL-safe
    );
  };

  return (
    <BackgroundContainer color="white">
      <View style={styles.container}>
        <Text style={styles.title}>{description}</Text>
        {content.map((question, index) => (
          <View key={question.ID} style={styles.questionBox}>
            <Text style={styles.number}>{index + 1}</Text>
            <Text style={styles.question}>{question.QuestionBody}</Text>
          </View>
        ))}
        <TouchableOpacity style={styles.button} onPress={onPressHandler}>
          <Text style={styles.buttonText}>Write a reflection in ParaFolio</Text>
        </TouchableOpacity>
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'left',
    color: colors.black,
    paddingBottom: 20,
  },
  container: {
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    alignSelf: 'center',
  },
  questionBox: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#D6EBFF',
    padding: 12,
    marginVertical: 12,
    borderWidth: 1,
    borderColor: '#6E9ECC',
  },
  number: {
    fontSize: 48,
    fontWeight: '600',
    lineHeight: 58.09,
    color: '#6E9ECC',
  },
  question: {
    paddingLeft: 12,
    color: colors.black,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 18.15,
    textAlign: 'left',
    flex: 1, // Allows the text to wrap and take available space
  },
  button: {
    marginTop: 12,
    alignSelf: 'center',
    backgroundColor: colors.PrimaryBlue,
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  buttonText: {
    color: colors.white,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

export default ReflectiveQuestions;
