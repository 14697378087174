export const colors = {
  Grey900: '#374044',
  Grey800: '#5f6e74',
  Grey700: '#798b93',
  Grey600: '#91a2a9',
  Grey500: '#a6b5ba',
  Grey400: '#bbc5ca',
  Grey300: '#ccd4d7',
  Grey200: '#dce1e3',
  Grey000: '#f8f9f9',
  GreyA00: '#fbfcfc',
  Blue900: '#090e10',
  Blue500: '#547f91',
  Blue400: '#6e99aa',
  Blue800: '#1c2a30',
  Blue700: '#2f4650',
  Blue600: '#436371',
  Blue300: '#8eafbd',
  Blue200: '#afc6d0',
  Blue100: '#cedde3',
  Blue000: '#eff4f6',
  PromoGrey: '#99A4AEB2',
  PromoBluePale: '#95a5afd9',
  PromoBlueLight: '#2F92C0',
  PromoBlue: '#0B588F',
  PromoBlueCollected: '#0e3043d9',
  PromoBlueDark: '#07253C',
  PromoBlueDarkest: '#205E8F',
  ButtonBlue: '#1581ea',
  PrimaryBlue: '#007AFF',
  Green900: '#01180c',
  Green800: '#044924',
  Green500: '#09ab55',
  Red900: '#190001',
  Red700: '#6a1518',
  Red500: '#d42a30',
  white: '#ffffff',
  black: '#000000',
  darkGrey: '#212529',
  Orange400: '#d4962a',
  Orange300: '#e0af58',
  SearchBar: '#004d49',
  CardHeader: '#3f9e99',
  QuizSummary: '#ebfaf9',
  SectDefault: '#00b3a9',
  SectAlgorithms: '#73667a',
  SectDrugs: '#EB6C16',
  SectGeneral: '#4d3ea2',
  SectMatObs: '#b42199',
  SectMedical: '#2ea100',
  SectPage: '#e91581',
  SectResuscitation: '#00a9b8',
  SectSpecial: '#7b6f18',
  SectTrauma: '#ed1d25',
  SubSectTrauma: '#fdeded',
  SubSectAlgorithms: '#f4f3f4',
  SubSectDrugs: '#fef6ed',
  SubSectGeneral: '#f1eff7',
  SubSectMatObs: '#f9edf7',
  SubSectMedical: '#eef7eb',
  SubSectPage: '#fdecf5',
  SubSectResuscitation: '#ebf8f9',
  SubSectSpecial: '#f4f3ec',
  AnswerCorrect: '#81d37e',
  AnswerField: '#d6ebff',
  AnswerIncorrect: '#e98a8d',
  StandbyGreen: '#239161',
  StandbyYellow: '#eee22b',
  Grey100: '#eaedef',
  NavBar: '#00736d',
  DisclaimerRed: '#F9E3D2',
  BlueHighlight: '#2777f6',
  MultiSelectHighlight: '#406b9e',
  AudioButtonBg: '#e8ecee',
  AudioButtonColor: '#2a7df6',
  PSIRFHeader: '#E1E000',
  PSIRFHeaderText: '#04322E',
  PSIRFBackground: '#272727',
  PSIRFForeground: '#00736D',
  PSIRFButtonDisabled: 'background: rgba(255, 255, 255, 0.3)',
  PSIRFReflectionBackground: '#6E9ECC',
  PSIRFAuthorTagline: '#bfbfbf',
  PSIRFLearningButton: '#F1F3F5',
  PSIRFBorderWhite: '#F2F2F2',
  PSIRFBorderGreen: 'rgb(51, 143, 141)',
};
