/**
 * CPDQuiz App
 * @flow
 */
'use strict';

import {Platform} from 'react-native';

export default {
  sendEmail(
    emailAddresses: [],
    ccAddresses: [],
    bccAddresses: [],
    subject: string,
    body: string,
    attachments: [],
  ) {
    console.log('sending web email');

    var emailBody = encodeURIComponent(body);

    var mailToLink =
      'mailto:' +
      emailAddresses[0] +
      '?subject=' +
      subject +
      '&body=' +
      emailBody;

    window.location.href = mailToLink;
  },
};
