/**
 * PPP
 * https://github.com/facebook/react-native
 * @flow
 */
'use strict';

import {Platform, Dimensions, Linking} from 'react-native';

module.exports = {
  getCSSForBook(book: Object) {
    let css = '';

    if (book.ID === 'decisionMaking') {
      css = `
            .fmt {
                font-size: 15px;
                color: #9578a8;
                font-weight: bold;
            }
            
            .dedi {
                text-align: center;
            }
            
            .nor {
                font-weight: normal !important;
            }
            
            .h3 {
                color: #9578a8;
                font-size: 14px;
                font-weight: bold;
            }
            
            .h3x {
                color: #9578a8;
                font-size: 14px;
                font-weight: bold;
            }
            
            .h3a {
                color: #9578a8;
                font-size: 14px;
                font-weight: bold;
            }
            
            .box {
                background-color: #e1dae8;
                padding: 12px;
            }
            
            .right {
                text-align: right;
            }
            
            .hh {
                color: #9578a8;
            }
            
            .block {
                padding-left: 12px;
            }
            
            .bor2g {
                background: #e1dae8;
                color: black;
            }
            
            table.bor1 {
                border: 1px solid #9578a8;
                border-collapse: collapse;
            }
            
            tr {
                border: 1px solid #9578a8;
            }
            
            td {
                border: 1px solid #9578a8;
            }
            
            td.bor2 {
                background: white;
            }
            
            .c1 {
                color: #9578a8;
            }
            
            .c2 {
                color: #9578a8;
            }
            `;
    } else if (book.ID === 'dermatology') {
      css = `
            /* Dermatology */
            
            .usefulLink {
                min-height: 75px;
                border: 1px solid black;
                border-radius: 5px;
                margin-bottom: 16px;
            }
            
            H2 {
                color: #569797;
            }
            
            .subParagraph{
                font-size: 11px;
            }
            
            .furtherReading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            
            .furtherReadingHeader{
                font-weight: bold;
                color: #9cc7c4;
            }
            
            .blueHeader {
                color: #669999;
                font-weight: bold;
            }
            
            .blueHeader2 {
                color: #669999;
                font-weight: bold;
                text-align: center;
            }
            
            .redHeader {
                color: #cc0000;
                font-weight: bold;
                text-align: center;
            }
            
            .blackHeader {
                color: #000000;
                font-weight: bold;
                text-align: center;
            }
            
            .blueBox {
                background: #82B5AC;
                border-radius: 5px;
                padding: 12px;
                /* margin-bottom: 16px; */
            }
            
            .blueBoxHeader{
                font-weight: bold;
            }
            
            .blueBoxBody1 {
                text-align: right;
            }
            
            .blueBoxBody2 {
                font-weight: bold;
            }
            
            .blueBoxBody3 {
                font-weight: bold;
                text-align: center;
            }
            
            .blueBoxBody4 {
                text-align: center;
            }
            
            .greyBox {
                background:  #BAB9B7;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .greyBoxBody1{
                text-align: right;
            }
            
            .greyBoxBody2{
                font-weight: bold;
            }
            
            .greyBoxBody3{
                font-weight: bold;
                text-align: center;
            }
            
            .greyBoxBody4{
                text-align: center;
            }
            
            .greenBox {
                background: #CBDC9E;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .greenBoxBody1{
                text-align: center;
            }
            
            .greenBoxBody2{
                text-align: center;
                font-weight: bold;
            }
            
            .greenBoxBody3{
                text-align: right;
            }
            
            .greenBoxBody4{
                font-weight: bold;
            }
            
            .greenText {
                color: #51a101
            }
            
            .amberBox {
                background: #ff9a17;
                border-radius: 5px;
                color: black;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .amberBoxBody1 {
                font-weight: bold;
            }
            
            .amberBoxBody2 {
                text-align: center;
            }
            
            .amberBoxBody3 {
                font-weight: bold;
                text-align: center;
            }
            
            .amberBoxBody4 {
                text-align: right;
            }
            
            .redFlag {
                content: url('bookImages/dermatology-10.png');
                height: 50px;
                width: 40px;
            }
            
            .amberFlag {
                content: url('bookImages/dermatology-11.png');
                height: 50px;
                width: 40px;
            }
            
            .lightRedBox {
                background: #cc6f6f;
                border-radius: 5px;
                color: #000000;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .lightRedBoxBody1 {
                
            }
            
            .lightRedBoxBody2 {
                font-weight: bold;
            }
            
            .lightRedBoxBody3 {
                font-weight: bold;
                text-align: center;
            }
            
            .lightRedBoxBody4 {
                text-align: center;
            }
            
            .redBox {
                background: #D4333D;
                border-radius: 5px;
                color: white;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .redBoxBody1 {
                
            }
            
            .redBoxBody2 {
                font-weight: bold;
            }
            
            .redBoxBody3 {
                text-align: right;
            }
            
            .redBoxBody4{
                font-weight: bold;
                text-align: center;
            }
            
            .redBoxBody5{
                text-align: center;
            }
            
            .yellowBoxContainer {
                display: flex;
                flex-direction: row;
            }
            
            .yellowBox {
                background: #F8E86D;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .yellowBoxHeader {
                font-weight: bold;
            }
            
            .yellowBoxBody1{
                font-weight: bold;
            }
            
            .yellowBoxBody2 {
                text-align: right;
            }
            
            .yellowBoxBody3{
                text-align: center;
            }
            
            .yellowBoxBody4{
                font-weight: bold;
                text-align: center;
            }
            
            .usefulLinkHeader{
                font-size: 16px;
            }
            
            .usefulLinkBody1{
                font-size: 16px;
                padding-top: 6px;
                padding-left: 10px;
                padding-right: 10px;
            }
            
            .usefulLinkBody2{
                font-size: 12px;
                padding-bottom: 6px;
                padding-left: 10px;
                padding-right: 10px;
            }
            
            .htuBoxHeader{
                font-style: bold;
            }
            
            ul.greenBoxList {
                list-style-type: square;
            }
            
            ul.greenBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.greenBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.greenBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.greyBoxList {
                list-style-type: square;
            }
            
            ul.greyBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.greyBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.greyBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.blueBoxList {
                list-style-type: square;
            }
            
            ul.blueBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.blueBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.blueBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.yellowBoxList{
                list-style-type: square;
            }
            
            ul.yellowBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.yellowBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.yellowBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.redBoxList{
                list-style-type: square;
            }
            
            ul.redBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.redBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.redBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.lightRedBoxList{
                list-style-type: square;
            }
            
            ul.lightRedBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.lightRedBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.lightRedBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            tr.tableHeaderRow1 {
                border-spacing: 2px 0;
            }
            
            th.tableHeader1 {
                background: #669999;
            }
            
            th.tableHeader2 {
                background: #ffdb4d;
            }
            
            td.tableData1 {
                background: #ffdb4d;
            }
            
            td.tableData2 {
                background: #a6a6a6;
            }
            
            td.tableData3{
                background: #a6a6a6;
                color: #cc0000;
                font-weight: bold;
            }
            
            table.verticalCellSpacing{
                border-spacing: 2px 0;
            }
            
            `;
    } else if (book.ID === 'emergencyBirth') {
      css = `
            .hh {
                color: #017a88
            }
            
            table.bor1{
                border: 1px solid #84bab5;
                border-collapse: collapse;
            }
            
            tr {
                border: 1px solid #84bab5;
            }
            
            td {
                border: 1px solid #84bab5;
            }
            
            .bor2 {
                background: #eef4f3;
            }
            
            .bor2n {
                background: #eef4f3;
            }
            
            .bor3 {
                background: #e1eeea;
            }
            
            .bor2gw {
                border-right: 1px solid white;
                background: #9ac5c1;
            }
            
            .bor2g {
                background: #9ac5c1;
            }
            
            .c1 {
                color: #017a88;
            }
            .bull1 {
                padding-left: 18px;
            }
            
            .bull2 {
                padding-left: 36px;
            }
            
            `;
    } else if (book.ID === 'humanFactors') {
      css = `
            .h2f{
                color: #c65107;
            }
            
            .separator-1 {
                width: 100%;
                height: 1px;
                background-color: #c65107;
            }
            
            .lot {
                background: #f3e9e6;
                border: 1px solid #a34c46;
                border-radius: 8px;
                padding: 12px;
            }
            
            .lot_bl1 {
                padding-left: 12px;
            }
            
            .lot_bl1b {
                color: #a34c46;
                font-size: 16px;
            }
            
            .fig_num {
                color: #67924d;
                font-weight: bold;
            }
            
            .tbn {
                color: #45a1c6;
                font-weight: bold;
            }
            
            table {
                border: 1px solid #45a1c6;
                border-collapse: collapse;
            }
            
            th {
                background: #45a1c6;
                color: white;
                padding: 8px;
            }
            
            tr {
                border: 1px solid #45a1c6;
            }
            
            td {
                border: 1px solid #45a1c6;
                padding: 8px;
                min-width: 100px;
            }
            
            .tr1 {
                background: #ebf2f7;
            }
            
            .tr2 {
                background: #dbe9f2;
            }
            
            .box2 {
                background: #f0f4ef;
                border-radius: 8px;
                padding: 12px;
            }
            
            .h4 {
                color: #65914a;
                font-weight: bold;
            }
            
            .bl1f {
                padding-left: 12px;
            }
            
            .bl1 {
                padding-left: 12px;
            }
            
            .bl1b {
                color: #c65107;
            }
            
            .nl1f {
                padding-left: 12px;
            }
            
            .nl1 {
                padding-left: 12px;
            }
            
            .nl1n {
                color: #c65107;
                font-weight: bold;
            }
            
            .ext {
                padding: 12px;
            }
            
            .ext_src {
                text-align: right;
            }
            
            .h3 {
                color: #27385c;
                font-weight: bold;
            }
            
            .src {
                text-align: right;
            }
            
            .tb_blb {
                color: #45a1c6;
            }
            
            .bx1_t {
                background: #a34c46;
                color: white;
                font-weight: bold;
                padding: 12px 12px 18px 12px;
                border-radius: 8px 8px 0px 0px;
            
            }
            
            .box1 {
                background: #f3e9e6;
                border: 1px solid #a34c46;
                padding: 12px;
                border-radius: 8px 8px 8px 8px;
                margin-top: -22px;
            }
            
            .bx1_bl {
                padding-left: 12px;
            }
            
            .bx1_blb {
                color: #a34c46;
            }
            
            .ulf {
                padding-left: 12px;
            }
            
            .bl2 {
                padding-left: 24px;
            }
            
            .bl2b {
                font-size: 9px;
                color: #a34c46;
                padding-right: 12px;
            }
            
            p.ul {
                padding-left: 12px;
            }
            
            `;
    } else if (book.ID === 'lawAndEthics') {
      css = `
            .bold {
                font-weight: bold;
            }
            
            .ital {
                font-style: italic;
            }
            
            .h2 {
                color: #003ba4;
                font-weight: bold;
                font-size: 16px;
            }
            
            .h3 {
                color: #003ba4;
                font-weight: bold;
                font-size: 16px;
            }
            
            .bx1 {
                background: #e5e7f4;
                border: 1px solid #003ba4;
                padding: 12px;
                margin-bottom: 12px;
            }
            
            .cs {
                background: #003ba4;
                display: flex;
                align-items: center;
                color: white;
                font-weight: bold;
            }
            
            .bx1_t {
                font-weight: bold;
                text-align: center;
            }
            
            .bx1_txl {
            
            }
            
            .bx2 {
                background: #b0b4d4;
                padding-top: 12px;
                padding-right: 12px;
                padding-bottom: 12px;
                padding-left: 12px;
                margin-bottom: 12px;
            }
            
            .bx2_t {
                font-weight: bold;
                
            }
            
            .bx2_txl {
                padding-left: 6px;
            }
            
            .bx3_t {
                text-align: center;
            }
            
            .bx3_txl {
                text-align: center;
            }
            
            .bld {
                color: #003ba4;
                font-size: 9px;
                padding-left: 12px;
                padding-right: 12px;
            }
            
            .bnl {
                color: #003ba4;
                font-weight: bold;
                padding-left: 12px;
                padding-right: 12px;
            }
            
            .fgn {
                color: #003ba4;
                font-weight: bold;
            }
            
            .obj {
                background: #003ba4;
                color: white;
                font-size: 14px;
                font-weight: bold;
            }
            
            .tn {
                font-weight: bold;
            }
            
            .tch1 {
                background: #003ba4;
                color: white;
                font-weight: bold;
                font-size: 14px;
                /* padding: 8px; */
            }
            
            .tb {
                background: #dddfec;
                /* padding: 8px; */
            }
            
            table.tt {
                border: 1px solid #003ba4;
            }
            
            table.cs {
                border: 1px solid #003ba4;
            }
            
            .bx2-h1 {
                font-weight: bold;
            }
            
            .nln {
                color: #003ba4;
                font-weight: bold;
                padding-left: 8px;
                padding-right: 8px;
            }
            
            .br {
                color: #003ba4;
            }
            
            .br1 {
                padding-left: 16px;
            }
            
            .br2 {
                padding-left: 16px;
            }
            
            .cnn {
                border-bottom: 1px solid #013CA6;
                height: 200px;
                border-collapse: collapse;
            }
            
            .cn {
                background-color: #013CA6;
                color: white;
                right: 0;
            }
            
            .ct {
                background-color: #E7E8F5;
                color: #013CA6
            }
            
            .epi {
                font-style: italic;
            }
            
            .boxhead {
                background-color: #0E4B90;
                padding: 12px;
                color: #FFFFFF;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                width: 170px;
                text-align: center;
                margin: 0 0 0 24px;
            }
            
            .box {
                background-color: #E7E8F5;
                border: 2px solid #0E4B90;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 12px;
            }
            
            .box2 {
                background-color: #B9BEE0;
                border-radius: 5px 0px 5px 0px;
                padding: 12px;
            }
            
            .boxhead1 {
                font-weight: bold;
                font-style: italic;
            }
            
            .h4a {
                font-weight: bold;
                color: #003ba4;
            }
            
            .h4 {
                font-weight: bold;
                color: #003ba4;
                font-size: 16px;
            }
            
            .tch1 {
                font-weight: bold;
                background-color: #003ba4;
                color: #FFFFFF;
                padding: 4px;
            }
            
            .td1 {
                background: #F1F3F7;
                border: 1px solid #003ba4;
                padding: 4px;
            }
            
            .tab1 {
                border-collapse: collapse;
            }
            
            .bl {
                list-style: none; /* Remove default bullets */
            }
              
            .bl li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            .box3 {
                background-color: #E7E8F5;
                border: 1px solid #0E4B90;
                padding: 12px;
            }
            
            .boxhead3 {
                font-weight: bold;
                text-align: center;
            }
            
            .box4 {
                background-color: #E7E8F5;
                border: 1px solid #0E4B90;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 12px;
            }
            
            .boxhead4 {
                font-weight: bold;
                text-align: center;
            }
            
            .h5 {
                font-weight: bold;
                color: #003ba4;
                font-size: 16px;
            }
            
            .bl-1 {
                list-style: none; /* Remove default bullets */
            }
              
            .bl-1 li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            .bx-r {
                text-align: right;
            }
            
            .boxhead5 {
                background-color: #0E4B90;
                padding: 12px;
                color: #FFFFFF;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                width: 285px;
                text-align: center;
                margin: 0 0 0 24px;
            }
            
            .h5a {
                font-weight: bold;
                color: #003ba4;
            }
            
            .boxhead4a {
                font-weight: bold;
            }
            
            .fig {
                font-weight: bold;
                color: #003ba4;
            }
            
            .epi-r {
                text-align: right;
            }
            
            .bl2-1 {
                list-style: none; /* Remove default bullets */
            }
              
            .bl2-1 li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            .bl1 {
                list-style: none; /* Remove default bullets */
            }
              
            .bl1 li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            `;
    } else if (book.ID === 'palliativeCare') {
      css = `
            /* Palliative Life and End of Life Care */

            .bold {
                font-weight: bold;
            }

            .ital {
                font-style: italic;
            }

            .bl {
                padding-left: 12px;
            }

            .blbl {
                padding-left: 24px;
            }

            .bl_f {
                padding-left: 12px;
            }

            .blb {
                font-size: 9px;
                color: #c85519;
                padding-right: 8px;
            }

            .st_bl {
                padding-left: 12px;
            }

            .st_blbl {
                padding-left: 24px;
            }

            .st_blbl_bl {
                padding-left: 36px;
            }

            .st_blb {
                font-size: 9px;
                color: #c85519;
                padding-right: 8px;
            }

            .separator-1 {
                width: 100%;
                height: 1px;
                background-color: #c85519;
            }

            .h1 {
                font-size: 14px;
                color: #c85519;
                font-weight: bold;
            }

            .h2 {
                font-size: 14px;
                color: #294268;
                font-weight: bold;
            }

            .h3 {
                color: #679555;
                font-weight: bold;
            }

            .bx1 {
                background: #f4e5c8;
                border-radius: 5px 0px 0px 5px;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
            }

            .bx1_t {
                font-size: 14px;
                font-weight: bold;
                font-style: italic;
                color: #ae364f;
            }

            .bx2 {
                background: #e3ede3;
                border-radius: 5px;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
                border: 1px solid #2e8948;
                margin-bottom: 12px;
            }

            .bx2_t {
                font-size: 14px;
                font-weight: bold;
                color: #2e8948;
                text-align: center;
            }

            .bx2_parac{
                text-align: center;
            }

            .bx2_bl {
                padding-left: 12px;
            }

            .bx2_blb {
                font-size: 9px;
                color: #2e8948;
                padding-right: 8px;
            }

            .bx3_t {
                background: #a14942;
                color: white;
                font-size: 14px;
                font-weight: bold;
                border-radius: 5px 5px 0px 0px;
                padding: 12px;
                margin: 0;
            }

            .bx3 {
                background: #f2e4e2;
                border: 1px solid #a14942;
            }

            .bx3_bl {
                padding-left: 12px;
            }

            .bx3_blb {
                font-size: 9px;
                color: #a14942;
                padding-right: 8px;
            }

            .bx4 {
                background: #e5ebf2;
                border: 1px solid #3684ad;
            }

            .cs_t {
                display: flex;
                align-items: center;
                background: #006b9f;
                color: white;
                font-size: 14px;
                font-weight: bold;
                border-radius: 5px 5px 0px 0px;
                width: 175px;
                margin: 0 0 0 20px
            }

            .cs {
                background: #dfe5ef;
                border: 1px solid #006b9f;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
                margin-bottom: 12px;
            }

            .cs_blb {
                font-size: 9px;
                color: #006b9f;
                padding-right: 8px;
            }

            .cs_bl{
                padding-left: 12px;
            }

            /* Tables */

            table {
                border: 1px solid #31a0c5;
                border-collapse: collapse;
            }

            .tbn {
                font-weight: bold;
                color: #31a0c5;
            }

            .vb {
                background: #31a0c5;
                color: white;
                font-weight: bold;
            }

            .cen {
                background: #31a0c5;
                color: white;
                font-weight: bold;
                text-align: center;
            }

            .tb_bl {
                padding-left: 12px;
            }

            .tb_blb {
                font-size: 9px;
                color: #31a0c5;
                padding-right: 8px;
            }

            .bc {
                background: #e5f0f6;
            }

            .bc1 {
                background: #d2e5f0;
            }

            /* Number lists */

            .nlnl {
                padding-left: 24px;
            }

            .nlnl_nl {
                padding-left: 36px;
            }

            .nl {
                padding-left: 12px;
            }

            .nl_f {
                padding-left: 12px;
            }

            .nl_l {
                padding-left: 12px;
            }

            .nln {
                color: #c85519;
                font-weight: bold;
            }
            
            `;
    } else if (book.ID === 'mentalHealth') {
      css = `
            /* Mental Health */

            .AHead {
                color: #0E4B90;
                font-size: 18px;
                font-weight: bold;
            }
            
            .BHead {
                color: #0E4B90;
                font-weight: bold;
                font-size: 14px;
            }
            
            a:link {
                color: #008080;
            }
            
            .thead > tr {
                background-color: #0E4B90 !important;
                color: #FFFFFF;
            }
            
            .TableBody {
                background-color: #DFE0EC;
                color: #000000;
                border: 1px solid #0E4B90;
                padding: 4px;
            }
            
            .Tlabel {
                font-weight: bold;
            }
            
            .ClassList {
                color: #0E4B90;
                font-size: 18px;
                padding-left: 12px;
                padding-right: 6px;
                font-weight: bold;
            }
            
            .listnum {
                color: #0E4B90;
                padding-left: 12px;
                padding-right: 6px;
                font-weight: bold;
            }
            
            table {
                border-collapse: collapse;
            }
            
            .box {
                background-color: #DFE0EC;
                border: 1px solid #0E4B90;
                border-radius: 5px;
                padding: 12px;
            }
            
            .boxTitle {
                font-weight: bold;
                color: #000000;
                text-align: center;
                font-size: 18px;
            }
            
            .list2 {
                padding-left: 12px;
            }
            
            .list3 {
                padding-left: 12px;
            }
            
            .boxTitle-t {
                background-color: #0E4B90;
                padding: 12px;
                color: #FFFFFF;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                width: 110px;
                text-align: center;
                margin: 0 0 0 24px;
            }
            
            .boxAhead {
                font-weight: bold;
            }
            
            .Tparar {
                text-align: right;
            }
            
            .extract {
                background-color: #DFE0EC;
                padding: 12px;
                border-radius: 12px;
            }
            
            `;
    }

    return css;
  },
};
