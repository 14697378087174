/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import LayoutController from '../classes/LayoutController';
import PlatformController from '../classes/PlatformController';

var _ = require('lodash');

type Props = {};

type State = {};

export default class SettingsScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: 'Settings',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: '500',
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
    }
  }

  async decreaseFontSize() {
    await LayoutController.decreaseFontSize();
    this.forceUpdate();
  }

  async increaseFontSize() {
    await LayoutController.increaseFontSize();
    this.forceUpdate();
  }

  async resetFontSize() {
    await LayoutController.resetFontSize();
    this.forceUpdate();
  }

  render() {
    var bottomPadding = 0;

    if (PlatformController.deviceHasBottomSafeArea()) {
      bottomPadding = 20;
    }

    return (
      <ScrollView style={styles.container}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 10,
            backgroundColor: colors.Grey000,
            borderWidth: 1,
            borderRadius: 3,
            borderColor: colors.Grey400,
          }}>
          <TouchableOpacity
            activeOpacity={0.5}
            style={{flex: 1, height: 50, justifyContent: 'center'}}
            onPress={this.decreaseFontSize.bind(this)}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                letterSpacing: -0.34,
                textAlign: 'center',
                color: colors.Grey900,
              }}>
              Aa
            </Text>
          </TouchableOpacity>

          <View
            style={{width: 1, height: 50, backgroundColor: colors.Grey400}}
          />

          <TouchableOpacity
            activeOpacity={0.5}
            style={{flex: 1, height: 50, justifyContent: 'center'}}
            onPress={this.increaseFontSize.bind(this)}>
            <Text
              style={{
                fontSize: 22,
                fontWeight: '600',
                letterSpacing: -0.53,
                textAlign: 'center',
                color: colors.Grey900,
              }}>
              Aa
            </Text>
          </TouchableOpacity>
        </View>

        <DynamicText
          style={{
            marginBottom: 16,
            fontSize: 17,
            letterSpacing: -0.41,
            color: colors.Grey900,
          }}>
          Change the font size for menus and quiz text.
        </DynamicText>

        <TouchableOpacity
          activeOpacity={0.5}
          style={{
            justifyContent: 'center',
            height: 50,
            marginBottom: bottomPadding,
            borderWidth: 1,
            borderRadius: 3,
            borderColor: colors.ButtonBlue,
          }}
          onPress={this.resetFontSize.bind(this)}>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '600',
              letterSpacing: -0.39,
              textAlign: 'center',
              color: colors.ButtonBlue,
            }}>
            Reset to default settings
          </Text>
        </TouchableOpacity>

        <DynamicText
          style={{
            marginTop: 16,
            marginBottom: 56,
            fontSize: 17,
            letterSpacing: -0.41,
            color: colors.Grey900,
          }}>
          <DynamicText style={{fontWeight: 'bold'}}>
            Text-to-speech options
          </DynamicText>
          {`
You can have the text of Standby CPD issues, case studies or questions read out to you. Instructions on how to use this feature on your device are below.

`}
          <DynamicText style={{fontStyle: 'italic'}}>iOS devices</DynamicText>
          {`
1. Go to your device's Settings.
2. Click on the Accessibility option, then on 'Spoken Content'.
3. Toggle on 'Speak Selection' and 'Speak Screen'.
4. Now go back to the ParaPass app, highlight the text you would like read out, and press 'Speak' when it comes up in the pop-up menu above your selected text.

`}
          <DynamicText style={{fontStyle: 'italic'}}>
            Android devices
          </DynamicText>
          {`
1. Download the 'Android Accessibility Suite' app (don't worry, it's completely free!) on the Google Play store.
2. Then head over to your device's Settings.
3. Click on the Accessibility option.
4. Click on 'Installed apps', then on 'Select to Speak'.
5. Toggle on the Select to Speak shortcut - an Accessibility icon will come up in the corner of your screen.
6. Now go back to the ParaPass app, click on the Accessibility icon and then click on the text you would like to have read out to you. You can also have an entire page read out to you by simply pressing the Play button.


`}
          <DynamicText style={{fontWeight: 'bold'}}>
            More improvements coming soon
          </DynamicText>
          {`
We are planning more Accessibility improvements in future updates, so please do keep an eye out for updates down the line.`}
        </DynamicText>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
});
