import React, {memo} from 'react';
import {colors} from '../classes/Colors';
import RenderHTML from 'react-native-render-html';
import {Platform, View} from 'react-native';

function ignoreThis(node, parent) {
  const classToIgnore = Platform.OS === 'web' ? 'mobile' : 'web';
  return (
    node.type === 'tag' &&
    node.name === 'a' &&
    node.attribs.class === classToIgnore
  );
}

const HTMLRenderer = props => {
  return (
    <RenderHTML
      tagsStyles={{
        p: {
          fontSize: 17,
          color: colors.Grey900,
          lineHeight: 25,
        },
      }}
      contentWidth={200}
      source={{html: props.html}}
      ignoreDomNode={ignoreThis}
    />
  );
};

export default memo(HTMLRenderer);
