/**
 * PPP
 * https://github.com/facebook/react-native
 * @flow
 */
'use strict';

import {Platform, Dimensions} from 'react-native';

export default {
  replaceScreen(navigation, params) {
    // try {
    //   let previousRoutes = navigation.dangerouslyGetParent().state.routes;
    //   let keyToReplace = null;
    //   for (let routeIndex = 0; routeIndex < previousRoutes.length; routeIndex++) {
    //     let route = previousRoutes[routeIndex];
    //     if (route.routeName === 'Quiz') {
    //       keyToReplace = route.key;
    //     }
    //   }
    //   if (keyToReplace != null) {
    //     const replaceAction = StackActions.replace({
    //       key: keyToReplace,
    //       routeName: 'Quiz',
    //       params: params,
    //     })
    //     navigation.dispatch(replaceAction);
    //   }
    // } catch (error) {
    //   // console.log("Couldn't navigate to route: " + error);
    // }
  },
};
