import React from 'react';

import {View, Text, Image, StyleSheet} from 'react-native';

import {colors} from '../../classes/Colors';

const PSIRFHeader = () => {
  const header = 'PSIRF LEARNING';
  const headerIconSource = require('../../assets/PSIRF/PSIRF_alert_green.png');
  return (
    <View style={styles.header}>
      <Image style={styles.icon} source={headerIconSource} />
      <Text style={styles.headerText}>{header}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.PSIRFHeader,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: colors.PSIRFHeaderText,
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
    padding: 7,
  },
  icon: {
    marginRight: 3,
    width: 14,
    height: 14,
  },
});
export default PSIRFHeader;
