import React from 'react';

import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native';

import DynamicText from '../DynamicText';
import TrustTag from '../TrustTag';
import {colors} from '../../classes/Colors';
import LayoutController from '../../classes/LayoutController';
import {useAcknowledgements} from '../../context/AcknowledgementsContext';

const moment = require('moment');

const InfoBar = ({trust, value, isAcknowledged, isCompressed}) => (
  <View style={[infoStyles.infoBar, isCompressed && {paddingBottom: 4}]}>
    <TrustTag trust={trust} />
    <Text style={infoStyles.infoBarValue}>{`${value} \u2022`}</Text>
    <Text
      style={[infoStyles.infoBarValue, !isAcknowledged && {color: '#ED242B'}]}>
      {isAcknowledged ? 'Acknowledged' : 'Unacknowledged'}
    </Text>
  </View>
);

const PSIRFNavCard = ({
  image,
  fallbackImage,
  title,
  subtitle,
  onPress,
  trust,
  isHero,
  id,
  isCompressed,
  displayDate,
}) => {
  const {isAcknowledged} = useAcknowledgements('psirf');
  const isTablet = LayoutController.isTablet();
  const showHeroImage = !isTablet && isHero;
  const date = displayDate
    ? `\u2022 ${moment(displayDate).format('DD MMM YYYY')}`
    : '';
  const styles = showHeroImage ? heroStyles : barStyles;
  const imageSource = {uri: image};
  return (
    <TouchableOpacity onPress={onPress}>
      {isHero && (
        <View style={{backgroundColor: colors.CardHeader}}>
          <DynamicText
            style={{
              marginHorizontal: 10,
              marginVertical: 6,
              fontSize: 14,
              fontWeight: 'bold',
              fontStyle: 'normal',
              letterSpacing: -0.2,
              color: colors.white,
            }}>
            LATEST PSIRF
          </DynamicText>
        </View>
      )}
      <View style={[styles.container, isHero && styles.shadowContainer]}>
        {showHeroImage && (
          <Image
            source={imageSource}
            style={styles.image}
            defaultSource={fallbackImage}
            objectFit="cover"
          />
        )}
        <InfoBar
          trust={trust}
          value={date}
          isAcknowledged={isAcknowledged(id)}
          isCompressed={isCompressed}
        />
        <View style={styles.content}>
          <View style={styles.textContainer}>
            <Text
              ellipsizeMode="tail"
              numberOfLines={2}
              style={[styles.title, isCompressed && {paddingBottom: 0}]}>
              {title}
            </Text>
            {!isCompressed && (
              <Text
                ellipsizeMode="tail"
                numberOfLines={2}
                style={styles.subtitle}>
                {subtitle}
              </Text>
            )}
          </View>
          {!isCompressed && !showHeroImage && (
            <Image
              source={imageSource}
              style={styles.image}
              objectFit="cover"
              defaultSource={fallbackImage}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const barStyles = StyleSheet.create({
  shadowContainer: {},
  container: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#DEE2E6',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 23.97,
    paddingRight: 8,
    paddingBottom: 8,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 18.15,
    color: '#6D7378',
  },
  image: {
    width: 80,
    height: 80,
    marginLeft: 12,
  },
});

const heroStyles = StyleSheet.create({
  shadowContainer: {
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
  },
  container: {
    padding: 12,
    flexDirection: 'column',
    backgroundColor: colors.white,
    marginBottom: 16,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 23.97,
    paddingRight: 8,
    paddingBottom: 8,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 18.15,
    color: '#6D7378',
  },
  image: {
    width: '100%',
    height: 170,
    marginBottom: 12,
  },
});

const infoStyles = StyleSheet.create({
  infoBar: {
    paddingBottom: 12,
    flexDirection: 'row',
  },
  infoBarTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  infoBarValue: {
    fontSize: 12,
    paddingLeft: 8,
    color: '#636669',
    fontWeight: '600',
    lineHeight: 14.52,
  },
});

export default PSIRFNavCard;
