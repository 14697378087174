'use strict';

import React, {Component, PureComponent} from 'react';
import {View, Text, Platform, Image} from 'react-native';

import ProgressView from './ProgressView';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';
import DynamicText from '../components/DynamicText';

var striptags = require('striptags');
var he = require('he');

var moment = require('moment');

export default class CaseStudyQuizCell extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageURL: '',
    };
  }

  async componentDidMount() {
    if (this.props.caseStudy.CoverImage != null) {
      var imageURL = await DataController.getStandbyIssueImagePath(
        this.props.caseStudy,
        this.props.caseStudy.CoverImage[0].filename,
      );
      this.setState({imageURL: imageURL});
    }
  }

  render() {
    var quizSetName = '';
    var quizAuthor = '';
    var quizSetDate = '';
    var typeLayout = null;
    var questionCount = 0;
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var borderLeftColor = colors.white;
    var borderLeftWidth = 0;

    if (this.props.quizSet.questions != null) {
      questionCount = this.props.quizSet.questions.length;
    }

    if (this.props.quiz != null) {
      questionCount = this.props.quiz.questions.length;
    }

    if (this.props.results != null) {
      for (
        var resultIndex = 0;
        resultIndex < this.props.results.length;
        resultIndex++
      ) {
        var result = this.props.results[resultIndex];

        if (result.correct) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      }
    }

    unansweredCount = questionCount - correctCount - incorrectCount;

    if (this.props.quizSet.type === 'caseStudy') {
      quizSetName = this.props.caseStudy.Title;
      quizSetDate = moment(this.props.quizSet.date).format('DD MMM YYYY');
      if (this.props.caseStudy.Author != null) {
        quizAuthor = this.props.caseStudy.Author.join(', ');
      }

      if (correctCount === questionCount) {
        typeLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '500', color: colors.Green500}}>
            Completed
          </DynamicText>
        );
      } else {
        typeLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '500', color: colors.Grey700}}>
            {questionCount} Questions
          </DynamicText>
        );
      }
    }

    var typeDotSeparatorLayout = null;
    if (typeLayout != null) {
      typeDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
    }

    let trustLayout = null;
    if (this.props.caseStudy.Trust != null) {
      trustLayout = (
        <View
          style={{
            justifyContent: 'center',
            paddingHorizontal: 6,
            height: 16,
            borderRadius: 2,
            backgroundColor: DataController.getColorForTrust(
              this.props.caseStudy.Trust,
            ),
          }}>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '500',
              letterSpacing: -0.1,
              color: colors.white,
            }}>
            {DataController.getTrustDisplayName(this.props.caseStudy.Trust)}
          </Text>
        </View>
      );
    }

    var trustDotSeparatorLayout = null;
    if (trustLayout != null) {
      trustDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
    }

    var caseStudyTextLayout = null;

    if (this.props.caseStudy != null && this.props.caseStudy.Notes != null) {
      var html = this.props.caseStudy.Notes;
      html = he.decode(html);
      html = striptags(html);

      var numberOfLines = 2;

      if (Platform.OS === 'web') {
        numberOfLines = 1; // react native web currently doesn't support number of lines > 1
      }

      caseStudyTextLayout = (
        <DynamicText
          style={{
            marginTop: 4,
            fontSize: 15,
            letterSpacing: -0.36,
            color: colors.Grey700,
          }}
          numberOfLines={numberOfLines}>
          {html}
        </DynamicText>
      );
    }

    if (this.props.quiz != null) {
      var dateString = moment(this.props.quiz.time).format('DD MMM YYYY');
      var timeString = moment(this.props.quiz.time).format('HH:mm');
    }

    var infoLayout = (
      <View style={{flex: 1}}>
        <View style={{flex: 1}}>
          <DynamicText
            style={{
              marginTop: 4,
              fontSize: 19,
              fontWeight: '600',
              letterSpacing: -0.04,
              color: colors.Grey900,
            }}>
            {quizSetName}
          </DynamicText>
          <DynamicText
            style={{
              marginTop: 4,
              fontSize: 17,
              letterSpacing: -0.04,
              color: colors.Grey700,
            }}>
            {quizAuthor}
          </DynamicText>
          {caseStudyTextLayout}
        </View>
      </View>
    );

    var rightImageLayout = null;
    var headerImageLayout = null;

    if (this.props.isLatestCaseStudy && LayoutController.isCompact()) {
      var imageSource = {uri: this.state.imageURL};
      var defaultSource = require('../assets/Image_Loading_Placeholder_Large.png');

      if (this.state.imageURL == null || this.state.imageURL === '') {
        defaultSource = require('../assets/Image_No_Image_Placeholder_Large.png');
        imageSource = defaultSource;
      }

      headerImageLayout = (
        <View style={{marginBottom: 10}}>
          <Image
            style={{maxWidth: 500, height: 170}}
            source={imageSource}
            defaultSource={defaultSource}
          />
        </View>
      );
    } else {
      var rightImageLayout = null;

      var imageSource = {uri: this.state.imageURL};
      var defaultSource = require('../assets/Image_Loading_Placeholder.png');
      if (this.state.imageURL == null || this.state.imageURL === '') {
        defaultSource = require('../assets/Image_No_Image_Placeholder.png');
        imageSource = defaultSource;
      }

      rightImageLayout = (
        <Image
          style={{width: 90, height: 90, marginLeft: 10}}
          source={imageSource}
          defaultSource={defaultSource}
        />
      );

      rightImageLayout = (
        <View style={{justifyContent: 'center'}}>{rightImageLayout}</View>
      );
    }

    return (
      <View
        style={{
          paddingLeft: 10,
          paddingRight: 6,
          paddingVertical: 15,
          borderBottomWidth: 1,
          borderColor: colors.Grey100,
          borderLeftWidth: borderLeftWidth,
          borderLeftColor: borderLeftColor,
        }}>
        {headerImageLayout}

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 10,
          }}>
          {typeLayout}
          {typeDotSeparatorLayout}
          {trustLayout}
          {trustDotSeparatorLayout}
          <DynamicText style={{fontSize: 12, color: colors.Grey700}}>
            {quizSetDate}
          </DynamicText>
        </View>

        <View style={{flexDirection: 'row'}}>
          {infoLayout}

          {rightImageLayout}
        </View>
      </View>
    );
  }
}
