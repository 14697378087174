/**
 * CPDQuiz App
 * @flow
 */
'use strict';

var emptyVar = null;

export {
  emptyVar as Iap,
  emptyVar as IapPurchaseErrorListener,
  emptyVar as IapPurchaseUpdatedListener,
  emptyVar as IapAcknowledgePurchaseAndroid,
};
