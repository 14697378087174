import React from 'react';

import PSIRFRenderer from '../components/PSIRF/PSIRFRenderer';
import {colors} from '../classes/Colors';

const PSIRFScreen = ({navigation, screenProps}) => {
  const data = navigation.getParam('data');
  const quizOnPressHandler = navigation.getParam('quizOnPressHandler');

  return (
    <PSIRFRenderer
      data={data}
      screenProps={screenProps}
      quizOnPressHandler={quizOnPressHandler}
    />
  );
};

// Use static navigationOptions to set the header options in React Navigation v4
PSIRFScreen.navigationOptions = ({navigation}) => {
  return {
    title: '',
    headerStyle: navigation.getParam('headerStyle', {
      backgroundColor: colors.NavBar,
    }),
    headerTintColor: navigation.getParam('headerTintColor', colors.white),
    headerTitleStyle: navigation.getParam('headerTitleStyle', {
      fontFamily: 'InterUI-Regular',
      fontSize: 17,
      fontWeight: '600',
      letterSpacing: -0.41,
      color: colors.white,
    }),
  };
};

export default PSIRFScreen;
