/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
} from 'react-native';

import {colors} from '../classes/Colors';
import {ScreensWithTabBarHidden} from '../classes/Tabs';

var _ = require('lodash');

type Props = {};

type State = {
  navigationStack: [],
  navigationOptions: [],
};

export default class NavigationStack extends Component {
  constructor(props: Props) {
    super(props);

    var screens = Object.keys(this.props.screens);
    var firstScreen = screens[0];

    var navigationStack = [{Name: firstScreen, params: {}, state: {}}];

    this.state = {
      navigationStack: navigationStack,
      navigationOptions: {},
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.isActive === false && this.props.isActive === true) {
      this.updateHeader();
    }
  }

  navigate(screenName, params, currentScreenState) {
    if (this.props.screens[screenName] == null) {
      this.props.switchToTabWithScreen(screenName, params, currentScreenState);
      return;
    }

    var navigationStack = Array.from(this.state.navigationStack);

    navigationStack[navigationStack.length - 1].state = currentScreenState;

    navigationStack.push({Name: screenName, params: params, state: {}});

    this.setState(
      {navigationStack: navigationStack, navigationOptions: {}},
      function () {
        this.updateHeader();
      },
    );
  }

  goBack() {
    var navigationStack = Array.from(this.state.navigationStack);

    if (navigationStack.length > 1) {
      navigationStack.pop();

      var lastScreen = navigationStack[navigationStack.length - 1];

      this.setState({navigationStack: navigationStack}, function () {
        this.updateHeader();
      });
    }
  }

  pop(numberToPop = 1) {
    var navigationStack = Array.from(this.state.navigationStack);

    if (navigationStack.length > numberToPop) {
      for (let popIndex = 0; popIndex < numberToPop; popIndex++) {
        navigationStack.pop();
      }

      var lastScreen = navigationStack[navigationStack.length - 1];

      this.setState({navigationStack: navigationStack}, function () {
        this.updateHeader();
      });
    }
  }

  getParam(paramName: String, fallback) {
    var navigationStack = Array.from(this.state.navigationStack);

    var params = navigationStack[navigationStack.length - 1].params;

    if (params[paramName] != null) {
      return params[paramName];
    } else {
      return fallback;
    }
  }

  setParams(newParms: Object) {
    var navigationStack = Array.from(this.state.navigationStack);

    var currentParams = navigationStack[navigationStack.length - 1].params;

    _.extend(currentParams, newParms);

    this.updateHeader();
  }

  updateHeader() {
    var currentScreen =
      this.state.navigationStack[this.state.navigationStack.length - 1];
    var selectedScreenComponent = this.props.screens[currentScreen.Name];

    if (selectedScreenComponent.navigationOptions != null) {
      var navigationOptions = selectedScreenComponent.navigationOptions({
        navigation: {
          getParam: this.getParam.bind(this),
        },
      });

      this.setState({navigationOptions: navigationOptions});
    }

    if (this.props.showTabBar != null) {
      if (ScreensWithTabBarHidden.includes(currentScreen.Name)) {
        this.props.showTabBar(false);
      } else {
        this.props.showTabBar(true);
      }
    }
  }

  render() {
    var currentScreen =
      this.state.navigationStack[this.state.navigationStack.length - 1];
    var selectedScreenComponent = this.props.screens[currentScreen.Name];

    var navigation = {
      navigate: this.navigate.bind(this),
      goBack: this.goBack.bind(this),
      pop: this.pop.bind(this),
      getParam: this.getParam.bind(this),
      setParams: this.setParams.bind(this),
    };

    var headerLeftLayout = <View />;

    if (this.state.navigationOptions.headerLeft != null) {
      headerLeftLayout = this.state.navigationOptions.headerLeft;
    } else if (this.state.navigationStack.length > 1) {
      headerLeftLayout = (
        <TouchableOpacity onPress={this.goBack.bind(this)}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 10,
            }}>
            <Image
              style={{width: 12, height: 21}}
              source={require('../assets/Button_iOS_Back.png')}
            />
            <Text style={{color: '#FFF', marginLeft: 8, fontSize: 17}}>
              Back
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    var titleLayout = null;
    var headerTintColor = '#000';

    if (this.state.navigationOptions.headerTitle != null) {
      titleLayout = this.state.navigationOptions.headerTitle;
    } else if (this.state.navigationOptions.title != null) {
      var headerTitleStyle = {};

      if (this.state.navigationOptions.headerTitleStyle != null) {
        headerTitleStyle = this.state.navigationOptions.headerTitleStyle;
      }

      if (this.state.navigationOptions.headerTintColor != null) {
        headerTintColor = this.state.navigationOptions.headerTintColor;
      }

      titleLayout = (
        <Text
          style={[
            {
              position: 'absolute',
              left: 10,
              right: 10,
              textAlign: 'center',
              color: headerTintColor,
              fontSize: 17,
            },
            headerTitleStyle,
          ]}
          pointerEvents={'none'}>
          {this.state.navigationOptions.title}
        </Text>
      );
    }

    var headerRightLayout = <View />;

    if (this.state.navigationOptions.headerRight != null) {
      headerRightLayout = this.state.navigationOptions.headerRight;
    }

    var navBarLayout = (
      <View
        style={{
          flexDirection: 'row',
          height: 50,
          backgroundColor: colors.NavBar,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {headerLeftLayout}
        {titleLayout}
        {headerRightLayout}
      </View>
    );

    if (currentScreen.Name === 'MainMenu') {
      navBarLayout = null;
    }

    var hideStyle = {};

    if (this.props.isActive === false) {
      hideStyle = {display: 'none'};
    }

    return (
      <View style={[styles.container, hideStyle]}>
        {navBarLayout}
        {React.createElement(selectedScreenComponent, {
          navigation: navigation,
          initialState: currentScreen.state,
          screenProps: this.props.screenProps,
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
});
