import React from 'react';

import {ImageBackground, Text, StyleSheet, Image} from 'react-native';

import {colors} from '../classes/Colors';
import CustomButton from './CustomButton';
import {
  greggsPromotionStrings,
  formatPromotionData,
} from '../constants/greggsPromotion';
import LayoutController from '../classes/LayoutController';

// type Props = {
//   title: string,
//   subtitle: string,
//   promoCode: string,
//   icon: string,
//   onPress: () => void,
//    isButtonDisabled: boolean,
// };

const PromoBanner = ({
  promoCode,
  title,
  subtitle,
  onPress,
  isButtonDisabled,
  expiryDate,
}) => {
  const isTablet = LayoutController.isTablet();
  const backgroundImage = isTablet
    ? require('../assets/promo/results_page_landscape.png')
    : require('../assets/promo/results_page_portrait.png');
  const button = isButtonDisabled ? (
    <CustomButton
      title={greggsPromotionStrings.buttons.collected}
      buttonStyle={styles.collectedButton}
      titleStyle={styles.collectedButtonTitle}
      disabled={true}
    />
  ) : (
    <CustomButton
      title={promoCode}
      buttonStyle={styles.promoButton}
      titleStyle={styles.promoButtonTitle}
      iconComponent={
        <Image
          style={styles.icon}
          source={require('../assets/promo/qr_code_white.png')}
        />
      }
      onPress={onPress}
    />
  );
  const dateString = expiryDate
    ? `Offer valid until - ${formatPromotionData(new Date(expiryDate))}`
    : '';
  return (
    <ImageBackground
      style={styles.image}
      resizeMode="cover"
      source={backgroundImage}>
      <Image
        style={styles.logo}
        resizeMode="contain"
        source={require('../assets/promo/greggs_logo_white.png')}
      />
      <>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{`${subtitle} ${dateString}`}</Text>
        {button}
      </>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  logo: {
    marginBottom: 10,
    height: 15,
    width: 65,
  },
  icon: {
    height: 24,
    width: 24,
  },
  image: {
    alignSelf: 'stretch',
    paddingTop: 18,
    paddingHorizontal: 16,
    paddingBottom: 26,
    justifyContent: 'space-between',
    backgroundColor: colors.PromoBlue,
  },
  title: {
    color: colors.white,
    marginBottom: 8,
    fontSize: 20,
    fontWeight: '700',
  },
  subtitle: {
    color: colors.white,
    marginBottom: 16,
    fontSize: 13,
    fontWeight: '600',
  },
  promoButton: {
    borderColor: colors.white,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 6,
    padding: 14,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.PromoBlueLight,
  },
  promoButtonTitle: {
    color: colors.white,
    fontWeight: '700',
    fontSize: 17,
  },
  collectedButton: {
    backgroundColor: colors.PromoBlueLight,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
  },
  collectedButtonTitle: {
    color: colors.Grey300,
    fontWeight: '700',
    fontSize: 17,
  },
});

export default PromoBanner;
