import React from 'react';

import {
  StyleSheet,
  Modal,
  View,
  Image,
  Pressable,
  Text,
  SafeAreaView,
} from 'react-native';

import {colors} from '../classes/Colors';

const PromoModal = ({title, isModalVisible, onClose, children}) => {
  return (
    <Modal
      animationType="slide"
      visible={isModalVisible}
      presentationStyle="fullScreen">
      {/* Double safe area view for the navbar and the white background */}
      <SafeAreaView style={{flex: 0, backgroundColor: colors.NavBar}} />
      <SafeAreaView style={{flex: 1, backgroundColor: colors.white}}>
        <View style={styles.navbar}>
          <Pressable onPress={onClose}>
            <Image
              style={styles.closeButton}
              source={require('../assets/Button_Close.png')}
            />
          </Pressable>
          {title && <Text style={styles.title}>{title}</Text>}
          <View style={{width: 40}}></View>
        </View>
        {children}
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  navbar: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: colors.NavBar,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    marginLeft: 10,
    height: 30,
    width: 30,
  },
  title: {
    color: colors.white,
    fontSize: 17,
    fontWeight: '600',
  },
});

export default PromoModal;
