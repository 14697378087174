/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizResultCell from '../components/QuizResultCell';
import ProgressView from '../components/ProgressView';
import DropDown from '../components/DropDown';
import LoadingBar from '../components/LoadingBar';
import DynamicText from '../components/DynamicText';

import DataController from '../classes/DataController';

import {colors} from '../classes/Colors';

var _ = require('lodash');

type Props = {};

type State = {
  quizSet: object,
  quizzes: [],
  showOlderQuizzes: boolean,
};

export default class QuizSetResultsScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var quizSet = props.navigation.getParam('quizSet', null);
      var quizzesForQuizSet = [];

      for (
        let quizIndex = 0;
        quizIndex < this.props.screenProps.quizzes.length;
        quizIndex++
      ) {
        let quiz = this.props.screenProps.quizzes[quizIndex];

        if (quiz.quizSet === quizSet.name) {
          quizzesForQuizSet.push(quiz);
        }
      }

      this.state = {
        quizSet: quizSet,
        quizzes: quizzesForQuizSet,
        showOlderQuizzes: true,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
    }
  }

  keyExtractor(item, index) {
    return item.name;
  }

  renderQuiz({item}) {
    var quiz = item;

    var results = this.props.screenProps.resultsForQuizzes[quiz.name];

    return (
      <TouchableOpacity
        key={quiz.name}
        onPress={this.goToQuizResults.bind(this, quiz, this.state.quizSet)}>
        <QuizResultCell
          quiz={quiz}
          quizSet={this.state.quizSet}
          results={results}
          fontSize={this.props.screenProps.fontSize}
        />
      </TouchableOpacity>
    );
  }

  goToQuizResults(quiz: Object, quizSet: Object) {
    var resultsForQuiz = [];

    for (
      var resultIndex = 0,
        resultsLength = this.props.screenProps.results.length;
      resultIndex < resultsLength;
      resultIndex++
    ) {
      var result = this.props.screenProps.results[resultIndex];

      if (result.quizName === quiz.name) {
        resultsForQuiz.push(result);
      }
    }

    var questionsForQuiz = [];

    for (
      var quizQuestionIndex = 0;
      quizQuestionIndex < quiz.questions.length;
      quizQuestionIndex++
    ) {
      var quizQuestionName = quiz.questions[quizQuestionIndex];

      for (
        var questionIndex = 0,
          questionsLength = this.props.screenProps.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.props.screenProps.questions[questionIndex];

        if (question.Name === quizQuestionName) {
          questionsForQuiz.push(question);
        }
      }
    }

    this.props.navigation.navigate(
      'QuizResults',
      {
        quiz: quiz,
        quizSet: quizSet,
        questions: questionsForQuiz,
        results: resultsForQuiz,
      },
      this.state,
    );
  }

  takeQuiz() {
    this.props.navigation.navigate(
      'QuizOverview',
      {
        quizSet: this.state.quizSet,
        from: 'Quiz Results',
      },
      this.state,
    );
  }

  toggleOlderQuizzes() {
    this.setState({showOlderQuizzes: !this.state.showOlderQuizzes});
  }

  render() {
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var totalQuestionCount = this.state.quizSet.questions.length;
    var totalQuestionAttemptsCount = 0;
    var percentageText = '0%';
    var resultsCorrectCount = 0;
    var resultsIncorrectCount = 0;
    var averageCorrectPercentage = 0;
    var averageIncorrectPercantage = 0;

    let pgdQuizType = 'PGD';
    if (this.state.quizSet && this.state.quizSet.pma === true) {
      pgdQuizType = 'PMA';
    }

    var results =
      this.props.screenProps.latestResultsForQuizSets[this.state.quizSet.name];

    if (results == null) {
      results = [];
    }

    // get current results count
    for (let resultIndex = 0; resultIndex < results.length; resultIndex++) {
      let result = results[resultIndex];

      if (result.correct) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    }

    // get total attempts count
    for (
      let resultIndex = 0;
      resultIndex < this.props.screenProps.results.length;
      resultIndex++
    ) {
      let result = this.props.screenProps.results[resultIndex];
      let resultQuizName = result.quizName.split('-')[0];

      if (resultQuizName === this.state.quizSet.name) {
        totalQuestionAttemptsCount++;

        if (result.correct) {
          resultsCorrectCount++;
        } else {
          resultsIncorrectCount++;
        }
      }
    }

    // totalQuestionAttemptsCount = correctCount + incorrectCount;

    unansweredCount = totalQuestionCount - correctCount - incorrectCount;

    if (totalQuestionCount > 0) {
      percentageText =
        Math.floor((correctCount / (correctCount + incorrectCount)) * 100) +
        '%';
    }

    if (totalQuestionAttemptsCount > 0) {
      averageCorrectPercentage = Math.floor(
        (resultsCorrectCount / totalQuestionAttemptsCount) * 100,
      );
      averageIncorrectPercantage = Math.floor(
        (resultsIncorrectCount / totalQuestionAttemptsCount) * 100,
      );
    }

    const keys = ['unanswered', 'incorrect', 'correct'];
    const values = [unansweredCount, incorrectCount, correctCount];
    const pieChartColors = [colors.Grey400, colors.Red500, colors.Green500];

    var questionsHaveBeenModified = false;
    var modifiedQuestionsText = '';

    for (
      let quizIndex = 0;
      quizIndex < this.state.quizzes.length;
      quizIndex++
    ) {
      var quiz = this.state.quizzes[quizIndex];
      if (quiz.questions.length > totalQuestionCount) {
        questionsHaveBeenModified = true;
        break;
      }
    }

    if (questionsHaveBeenModified) {
      modifiedQuestionsText =
        ' This quiz has been modified since you took it - so you might have more answers than questions.';
    }

    var zeroQuizzesDisclaimerLayout = null;

    if (
      this.state.quizzes.length === 0 &&
      (this.state.quizSet.type === 'topic' ||
        this.state.quizSet.type === 'guideline' ||
        this.state.quizSet.type === 'section')
    ) {
      zeroQuizzesDisclaimerLayout = (
        <DynamicText
          style={{
            marginTop: 8,
            fontSize: 15,
            fontWeight: '600',
            color: colors.Grey700,
          }}>
          There are no quizzes taken for this {typeTitle} yet but results from
          quizzes in other categories (e.g. guidelines or sections) can count
          towards your results here.
        </DynamicText>
      );
    }

    var emptyLayout = null;

    if (this.state.quizzes.length === 0) {
      emptyLayout = (
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            There are no quizzes taken for this category. Complete more quizzes
            to see your results.
          </DynamicText>
        </View>
      );
    }

    var typeLayout = null;
    var typeTitle = '';
    var typeTitleCapitalised = '';

    if (this.state.quizSet.type === 'topic') {
      typeTitle = 'topic';

      let trust = null;
      if (this.state.quizSet.trust != null && this.state.quizSet.trust !== '') {
        trust = this.state.quizSet.trust;
      }

      if (this.state.quizSet.pgd === true && trust != null) {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                alignSelf: 'flex-start',
                justifyContent: 'center',
                paddingHorizontal: 4,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)} {pgdQuizType}
              </Text>
            </View>
            <View
              style={{
                marginHorizontal: 6,
                width: 2,
                height: 2,
                backgroundColor: colors.Grey700,
                borderRadius: 1,
              }}
            />
            <Image
              style={{width: 16, height: 16}}
              source={require('../assets/Glyph_Small_PGD.png')}
            />
          </View>
        );
      } else if (trust != null) {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                alignSelf: 'flex-start',
                justifyContent: 'center',
                paddingHorizontal: 4,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)}
              </Text>
            </View>
          </View>
        );
      } else {
        typeLayout = (
          <Image
            style={{width: 38, height: 14}}
            source={require('../assets/Logo_JRCALC_Small.png')}
          />
        );
      }
    }

    if (this.state.quizSet.type === 'standby') {
      typeTitle = 'Standby CPD';
      typeLayout = (
        <Image
          style={{width: 85, height: 13}}
          source={require('../assets/Logo_Standby_Small.png')}
        />
      );
    }

    if (this.state.quizSet.type === 'caseStudy') {
      typeTitle = 'case study';
      typeLayout = (
        <DynamicText
          style={{fontSize: 12, fontWeight: '500', color: colors.SectDefault}}>
          Case Study
        </DynamicText>
      );
    }

    var summaryText =
      'View your results below, and your most recent 2 quizzes. Older quizzes can be found at the bottom of this page. Tap on a quiz to view your results and get your certificate for that quiz.';

    if (this.state.quizSet.type === 'section') {
      typeTitle = 'section';
      if (this.state.quizzes.length > 0) {
        summaryText =
          'Results shown below are for questions taken in topic, section and guideline quizzes.';
      } else {
        summaryText =
          "Results shown below are for questions taken in topic and guideline quizzes. You haven't yet taken a quiz for this section.";
      }
    } else if (this.state.quizSet.type === 'guideline') {
      typeTitle = 'guideline';
      if (this.state.quizzes.length > 0) {
        summaryText =
          'Results shown below are for questions taken in topic, section and guideline quizzes.';
      } else {
        summaryText =
          "Results shown below are for questions taken in topic and section quizzes. You haven't yet taken a quiz for this guideline.";
      }
    }

    if (typeTitle !== '') {
      typeTitleCapitalised =
        typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1);
    }

    summaryText += modifiedQuestionsText;

    let pgdAuthorisedLayout = null;
    if (this.state.quizSet.pgdInfo && this.state.quizSet.pgdInfo.acknowledged) {
      pgdAuthorisedLayout = (
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            marginBottom: 10,
            paddingVertical: 8,
            backgroundColor: '#F8F9F9',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 6,
            borderWidth: 1,
            borderColor: colors.Grey500,
          }}>
          <Image
            style={{
              width: 30,
              height: 30,
              marginRight: 6,
              tintColor: colors.Grey700,
            }}
            source={require('../assets/Answer_Tick_Correct.png')}
          />
          <DynamicText
            style={{
              fontSize: 16,
              fontWeight: '700',
              letterSpacing: -0.39,
              color: colors.Grey700,
            }}>
            Authorised
          </DynamicText>
        </View>
      );
    }

    // split quizzes into 2 most recent and older quizzes
    var recentQuizzes = this.state.quizzes.slice(0, 2);
    var olderQuizzes = this.state.quizzes.slice(2);

    var olderQuizzesLayout = null;
    var olderQuizzesDisclosureSource = require('../assets/Button_Disclosure_Right.png');

    if (this.state.showOlderQuizzes) {
      olderQuizzesLayout = (
        <FlatList
          data={olderQuizzes}
          extraData={
            (this.props.screenProps.resultsForQuizzes,
            this.props.screenProps.fontSize)
          }
          keyExtractor={this.keyExtractor.bind(this)}
          renderItem={this.renderQuiz.bind(this)}
        />
      );

      olderQuizzesDisclosureSource = require('../assets/Button_Disclosure_Down.png');
    }

    return (
      <ScreenComponent style={styles.container}>
        <LoadingBar screenProps={this.props.screenProps} />

        <ScrollView
          ref={ref => (this._scrollView = ref)}
          style={{flex: 1}}
          contentContainerStyle={{paddingHorizontal: 10, paddingTop: 10}}>
          <View
            style={{
              marginBottom: 10,
              paddingHorizontal: 10,
              paddingVertical: 20,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            {typeLayout}

            <DynamicText
              style={{
                marginTop: 10,
                fontSize: 23,
                fontWeight: 'bold',
                letterSpacing: -0.4,
                color: colors.Grey900,
              }}>
              {this.state.quizSet.title}
            </DynamicText>
            <DynamicText
              style={{
                marginTop: 10,
                fontSize: 15,
                fontWeight: '500',
                letterSpacing: -0.24,
              }}>
              {summaryText}
            </DynamicText>
          </View>

          {pgdAuthorisedLayout}

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingHorizontal: 10, paddingVertical: 20}}>
              <DynamicText
                style={{
                  fontSize: 15,
                  letterSpacing: -0.24,
                  color: colors.darkGrey,
                  fontWeight: '600',
                }}>
                Overall results
              </DynamicText>

              <DynamicText
                style={{
                  marginTop: 8,
                  fontSize: 34,
                  fontWeight: '700',
                  color: colors.Green500,
                }}>
                {percentageText}{' '}
                <DynamicText
                  style={{
                    fontSize: 15,
                    fontWeight: '600',
                    color: colors.Grey700,
                  }}>
                  of {correctCount + incorrectCount} questions answered
                </DynamicText>
              </DynamicText>

              <ProgressView
                style={{flex: 1, marginTop: 8, height: 4, overflow: 'hidden'}}
                correctAnswers={correctCount}
                incorrectAnswers={incorrectCount}
                unansweredQuestions={0}
              />

              <View style={{marginTop: 15}}>
                <View style={{flexDirection: 'row'}}>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: -0.31,
                      color: colors.Grey900,
                    }}>
                    Correct
                  </DynamicText>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: 0.36,
                      color: colors.Green500,
                    }}>
                    {correctCount}
                  </DynamicText>
                </View>

                <View style={{flexDirection: 'row', marginTop: 8}}>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: -0.31,
                      color: colors.Grey900,
                    }}>
                    Incorrect
                  </DynamicText>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: 0.36,
                      color: colors.Red500,
                    }}>
                    {incorrectCount}
                  </DynamicText>
                </View>
              </View>

              <View
                style={{
                  width: '50%',
                  height: 1,
                  marginTop: 15,
                  marginBottom: 7,
                  backgroundColor: colors.Grey300,
                }}
              />

              <DynamicText
                style={{
                  marginTop: 8,
                  fontSize: 12,
                  fontWeight: '700',
                  letterSpacing: -0.31,
                  color: colors.Grey700,
                }}>
                Quizzes taken
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 8,
                  fontSize: 34,
                  fontWeight: '600',
                  color: colors.darkGrey,
                }}>
                {this.state.quizzes.length}
              </DynamicText>
              {zeroQuizzesDisclaimerLayout}
            </View>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingHorizontal: 10, paddingVertical: 20}}>
              <DynamicText
                style={{
                  fontSize: 15,
                  letterSpacing: -0.24,
                  color: colors.darkGrey,
                  fontWeight: '600',
                }}>
                Average results
              </DynamicText>

              <DynamicText
                style={{
                  marginTop: 8,
                  fontSize: 12,
                  fontWeight: '700',
                  letterSpacing: -0.31,
                  color: colors.Grey700,
                }}>
                Average % per quiz
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 8,
                  fontSize: 34,
                  fontWeight: '700',
                  color: colors.Green500,
                }}>
                {averageCorrectPercentage}%
              </DynamicText>

              <ProgressView
                style={{flex: 1, marginTop: 8, height: 4, overflow: 'hidden'}}
                correctAnswers={averageCorrectPercentage}
                incorrectAnswers={averageIncorrectPercantage}
                unansweredQuestions={0}
              />

              <View style={{marginTop: 15}}>
                <View style={{flexDirection: 'row'}}>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: -0.31,
                      color: colors.Grey900,
                    }}>
                    Correct
                  </DynamicText>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: 0.36,
                      color: colors.Green500,
                    }}>
                    {averageCorrectPercentage}%
                  </DynamicText>
                </View>

                <View style={{flexDirection: 'row', marginTop: 8}}>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: -0.31,
                      color: colors.Grey900,
                    }}>
                    Incorrect
                  </DynamicText>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: 0.36,
                      color: colors.Red500,
                    }}>
                    {averageIncorrectPercantage}%
                  </DynamicText>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingHorizontal: 10, paddingVertical: 20}}>
              <DynamicText
                style={{
                  fontSize: 15,
                  letterSpacing: -0.24,
                  color: colors.darkGrey,
                  fontWeight: '600',
                }}>
                {typeTitleCapitalised} overview
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 8,
                  fontSize: 15,
                  letterSpacing: -0.24,
                  color: colors.Grey900,
                }}>
                You have so far answered{' '}
                <DynamicText style={{fontSize: 15, fontWeight: '600'}}>
                  {correctCount} {correctCount === 1 ? 'question' : 'questions'}{' '}
                  out of a possible {totalQuestionCount}
                </DynamicText>{' '}
                on this {typeTitle}.
              </DynamicText>

              <TouchableOpacity
                activeOpacity={0.7}
                style={{flex: 1}}
                onPress={this.takeQuiz.bind(this)}>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 1,
                    marginTop: 15,
                    paddingVertical: 16,
                    backgroundColor: colors.white,
                    borderWidth: 1,
                    borderColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 6,
                    shadowOffset: {width: 0, height: 1},
                    shadowRadius: 1,
                    shadowColor: 'black',
                    shadowOpacity: 0.2,
                  }}>
                  <DynamicText
                    style={{
                      fontSize: 16,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.ButtonBlue,
                    }}>
                    Take quiz
                  </DynamicText>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{backgroundColor: colors.CardHeader}}>
              <DynamicText
                style={{
                  marginHorizontal: 10,
                  marginVertical: 6,
                  fontSize: 14,
                  fontWeight: 'bold',
                  fontStyle: 'normal',
                  letterSpacing: -0.2,
                  color: colors.white,
                }}>
                RECENT QUIZZES
              </DynamicText>
            </View>

            <FlatList
              data={recentQuizzes}
              extraData={
                (this.props.screenProps.resultsForQuizzes,
                this.props.screenProps.fontSize)
              }
              keyExtractor={this.keyExtractor.bind(this)}
              renderItem={this.renderQuiz.bind(this)}
            />
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <TouchableOpacity onPress={this.toggleOlderQuizzes.bind(this)}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: colors.CardHeader,
                }}>
                <DynamicText
                  style={{
                    marginHorizontal: 10,
                    marginVertical: 6,
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    letterSpacing: -0.2,
                    color: colors.white,
                  }}>
                  OLDER QUIZZES ({olderQuizzes.length})
                </DynamicText>
                <Image
                  style={{
                    width: 26,
                    height: 26,
                    marginRight: 8,
                    tintColor: colors.white,
                  }}
                  resizeMode={'contain'}
                  source={olderQuizzesDisclosureSource}
                />
              </View>
            </TouchableOpacity>

            {olderQuizzesLayout}
          </View>

          {emptyLayout}
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
