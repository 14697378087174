import React from 'react';

import {StyleSheet, View, Image, Text, ScrollView} from 'react-native';
import QRCode from 'react-qr-code';

import {colors} from '../classes/Colors';
import CustomButton from './CustomButton';
import {formatPromotionData} from '../constants/greggsPromotion';

const PromoQrContent = ({
  promoData,
  onButtonPress,
  isButtonDisabled,
  expiryDate,
  title,
  subtitle,
  buttonTitle,
}) => {
  const dateString = expiryDate
    ? `Offer valid until - ${formatPromotionData(new Date(expiryDate))}`
    : '';
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.modalView}>
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={require('../assets/promo/greggs_logo_blue.png')}
        />
        <Image
          style={styles.image}
          resizeMode="cover"
          source={require('../assets/greggs_background.png')}
        />
        <View style={styles.contentContainer}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
          <View style={styles.qrCodeContainer}>
            <QRCode value={promoData.promoCode} size={138} />
            <Text style={styles.qrCodeText}>{promoData.promoCode}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.subText}>{dateString}</Text>
      <CustomButton
        title={buttonTitle}
        buttonStyle={styles.button}
        titleStyle={styles.buttonText}
        onPress={onButtonPress}
        disabled={isButtonDisabled}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 20,
    width: '100%',
    maxWidth: 400,
    alignSelf: 'center',
  },
  modalView: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  subText: {
    marginTop: 12,
    color: colors.Grey800,
    fontSize: 11,
    fontWeight: '400',
    alignSelf: 'flex-end',
  },
  logo: {
    marginVertical: 25,
    marginHorizontal: 20,
    height: 16,
    width: 112,
  },
  image: {
    height: 138,
    width: '100%',
  },
  button: {
    width: '100%',
    backgroundColor: '#007AFF',
    padding: 13,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 20,
  },
  buttonText: {
    fontSize: 17,
    fontWeight: '700',
    color: colors.white,
  },
  contentContainer: {
    marginTop: 22,
    marginBottom: 32,
    marginHorizontal: 20,
  },
  title: {
    color: colors.darkGrey,
    fontWeight: '700',
    marginBottom: 7,
    fontSize: 17,
  },
  subtitle: {
    color: colors.darkGrey,
    fontWeight: '500',
    marginBottom: 35,
    fontSize: 12,
  },
  qrCodeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrCodeText: {
    paddingTop: 10,
    color: colors.darkGrey,
  },
});

export default PromoQrContent;
