/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {StyleSheet, Text, View, Platform, Dimensions} from 'react-native';

import {colors} from '../classes/Colors';

type Props = {};

type State = {};

export default class ScreenComponent extends Component {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return <View style={this.props.style}>{this.props.children}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
