import cheerio from '../Cheerio';
// render a TRUST guideline from JSON
const TableHelperClass = require('./TableHelper');

export default class TrustRender {
  constructor(errorList) {
    this.aTableHelper = new TableHelperClass.TableHelper();
    this.errorList = errorList;
  }
  htmlFromJson(CMGL, trustName) {
    if (CMGL.GLID === 'NEAS0020') console.log(''); // debug
    const aTableHelper = new TableHelperClass.TableHelper();

    let newHtml = '';
    if (CMGL.sections) {
      CMGL.sections.forEach(section => {
        if (section.SectionTitle !== 'LeadingSection') {
          newHtml += `<title2>${section.SectionTitle}</title2>`;
        }
        newHtml += section.sectionHTML;
      });
    } else {
      newHtml = CMGL.html;
    }
    const $ = cheerio.load(newHtml);

    let html = '<meta charset="utf-8"/><div class="GLContainer">';

    html += '<link href="css/style2.css" rel="stylesheet">';
    html += '<link href="css/style.css" rel="stylesheet">';
    html += '<link href="css/CM.css" rel="stylesheet">';
    html += '<link href="css/Feed2.css" rel="stylesheet">';
    html += '<link href="css/SWAST.css" rel="stylesheet">';
    html += `<link href="css/${trustName}.css" rel="stylesheet">`;
    html += '<link href="css/PlusOverride.css" rel="stylesheet">';

    // quicklook only for old EEAST not even sure it's used there even
    // var quickLook = $(CMGL.html).find('cm-quicklook');
    var quickLook = $(newHtml).find('cm-quicklook');
    if (quickLook.length > 0) {
      var cmGreenBox = quickLook.closest('cm-greenbox');
      if (cmGreenBox.length > 0) {
        html += '<div class=AACEQuickLookTitle>QUICK LOOK</div>';
        html += '<hr class="AACENoMargin">';
        if (CMGL.publ_guideline_no_ref)
          html +=
            '<div class="' +
            trustName.toUpperCase() +
            'Prefix">' +
            CMGL.publ_guideline_no_ref +
            ' </div>';
        else
          html += '<div class="' + trustName.toUpperCase() + 'Prefix"> </div>';
        html += '<div class=AACEQuickLookDiv>';
        html += cmGreenBox.clone().wrap('<div />').parent().html();
        html += '</div class=AACEQuickLookDiv>';
      }
    }

    html += '<hr class="AACENoMargin">';
    if (CMGL.publ_guideline_no_ref)
      html +=
        '<div class="' +
        trustName.toUpperCase() +
        'Prefix" style="height:26px;padding:1px;vertical-align:baseline;">' +
        '<span id="prefixref">' +
        CMGL.publ_guideline_no_ref +
        '</span>' +
        '</div>';
    else
      html +=
        '<div class="' +
        trustName.toUpperCase() +
        'Prefix" style="height:26px;padding:1px;vertical-align:baseline;"></div>';

    if (this.isDrug(CMGL)) {
      html +=
        '<div class="' +
        trustName.toUpperCase() +
        'ContainerDRUG TrustDrug" style="padding:10px;">';
    } else {
      html +=
        '<div class="' +
        trustName.toUpperCase() +
        'ContainerGL TrustDrug" style="padding:10px;">';
    }

    var drugTables = [];

    // not in trust drugs at momment
    if (CMGL.TableAndBoxes) {
      // assert(false);
      if (CMGL.TableAndBoxes.length > 0) {
        for (let aTable of CMGL.TableAndBoxes) {
          if (aTable.Type === 'DrugTable') drugTables.push(aTable);
        }
        if (drugTables.length > 0) {
          html += '<div class="padding AACEGreyText">Showing for age:  ';
          html +=
            '<select class="padding ion-input-select"' +
            ' ng-model="$localStorage.SelectedAge" ng-change="AgeSelectChanged($localStorage.SelectedAge)"' +
            'ng-options="item.name group by item.age for item in items">' +
            '</select>' +
            '</div>';

          // add button full width for each drug table
          html += '<div class="ACCEDrugButtons padding-sides">';

          var i = 1;
          for (let aDrugTable of drugTables) {
            if (aDrugTable['cpg_drug_short_info']) {
              html +=
                '<button ng-click="ACCEDrugButtonClicked(\'' +
                aDrugTable['ID'] +
                '\')" ' +
                'class="AACEDrugButton button button-stable button-full icon-right ion-chevron-right"> Dosage table: ' +
                aDrugTable['cpg_drug_short_info'] +
                '</button>';
            } else {
              html +=
                '<button ng-click="ACCEDrugButtonClicked(\'' +
                aDrugTable['ID'] +
                '\')" ' +
                'class="AACEDrugButton button button-stable button-full icon-right ion-chevron-right"> Dosage table: ' +
                i +
                '(text TBC)</button>';
            }
            i++;
          }

          html += '</div>';
        }
      }
    }

    if (CMGL.publ_section_title && this.addTitle()) {
      html +=
        '<div class=publ_section_title style="font-size: 1.3em !important; padding-top: -1px !important; padding-bottom: 5px !important;">' +
        CMGL.publ_section_title +
        '</div>';
    }
    if (CMGL.GLID === 'EAST0550') {
      console.log('');
    }
    // this preprocessing is nessaccary as some of the html does not parse very well
    let preProcessedhtml = this.preProcesshtml(newHtml, CMGL);
    // let preProcessedhtml = this.preProcesshtml(CMGL.html, CMGL);

    let htmlDom = $(preProcessedhtml);
    let endFound = false;
    let frontFound = false;
    for (let partIndex = 0; partIndex < htmlDom.length; partIndex++) {
      let aPart = htmlDom[partIndex];
      let tagName = aPart.tagName ? aPart.tagName.toUpperCase() : '';
      if (tagName.includes('FRONTMATTER')) tagName = 'FRONTMATTER';
      if (tagName.includes('ENDMATTER')) tagName = 'ENDMATTER';

      switch (tagName) {
        case 'FRONTMATTER': {
          let FM = this.addFrontMatter(CMGL);
          html += FM;
          frontFound = true;
          break;
        }
        case 'ENDMATTER': {
          let EM = this.addEndMatter(CMGL);
          html += EM;
          endFound = true;
          break;
        }
        case 'PUBL_SUBSECTION': {
          let sectionTitle = $(aPart).find('cm-subsection-title').html();

          if (sectionTitle === undefined || sectionTitle === null) {
            sectionTitle = $(aPart).find('publ_section_title').html();

            if (sectionTitle === undefined || sectionTitle === null) {
              sectionTitle = '';
            }
          }

          let result = '<div name=collaspable class="list card">';
          result +=
            '<div class="item item-text-wrap item-divider GLHeader" type="button" style="padding:0px; font-size: 1.5rem !important;">';
          result += '<div class="greenTitle">' + sectionTitle + '</div>';
          result += '<div class="item-icon-right">';
          result += '<span class="badge-minus"></span>';
          result += '</div>';
          result += '</div>';
          result += '<div class="AACE-Section-body">';
          result += $(aPart).html();
          result += '</div>';
          result += '</div>';
          html += result;
          break;
        }

        default: {
          console.log('Another section ' + CMGL.GLID + ' ' + tagName);
          if (
            tagName === 'P-LEFT' ||
            tagName === 'TITLE2' ||
            tagName === 'P-BULLET1' ||
            tagName === 'IMG-100' ||
            tagName === 'TITLE3' ||
            tagName === 'JRCALCTABLE'
          ) {
            // console.log('OK');
          } else {
            // console.log('PROBLEM');
            if (tagName.length === 1 && tagName !== 'P')
              console.log('BiGGER PROBLEM');
          }
          html += this.outerHTML(aPart, $);
          break;
        }
      }
    }

    if (!endFound) {
      if (frontFound) console.log(`BiGGER PROBLEM ${CMGL.GLID}`);
    }
    html +=
      '<div class=versionNumberRemote>' +
      CMGL.GLID +
      ' Version ' +
      CMGL.publ_major_version_no +
      '.' +
      CMGL.publ_minor_version_no +
      '</div>';
    html += '</div>';

    html = html.replace(/\/><\/img-100>/g, '></img-100>');

    if (html.indexOf('<img') !== -1) {
      let images = $(html).find('img');
      i = 0;

      for (i; i < images.length; i++) {
        let outer = this.outerHTML(images[i], $);
        let startPosition = outer.indexOf('src="');

        if (startPosition === -1) {
          startPosition = outer.indexOf("src='");
        }

        let endPosition = outer.indexOf('.jpg">');

        if (endPosition === -1) {
          endPosition = outer.indexOf(".jpg'>");
        }
        if (endPosition === -1) {
          endPosition = outer.indexOf('.jpg\\">');
        }
        if (endPosition === -1) {
          endPosition = outer.indexOf(".jpg\\'>");
        }
        if (endPosition === -1) {
          endPosition = outer.indexOf('.png">');
        }
        if (endPosition === -1) {
          endPosition = outer.indexOf(".png'>");
        }
        if (endPosition === -1) {
          endPosition = outer.indexOf('.png\\">');
        }
        if (endPosition === -1) {
          endPosition = outer.indexOf(".png\\'>");
        }

        let glid = '' + CMGL.publ_guideline_id;
        let src = outer.substring(startPosition + 5, endPosition + 4);
        let lastPathSrc = src.substring(src.lastIndexOf('/') + 1);

        if (src) {
          let newSrc = `img/${lastPathSrc}`;
          newSrc = newSrc.replace('.png', '.jpg');
          outer = outer.replace(src, newSrc);
          outer = outer.replace(
            '<img',
            '<img style="max-width:1024px;max-height:1024px;"',
          );
          html = html.replace(this.outerHTML(images[i], $), outer);
        }
      }
    }

    html = html.replace(/\u200B/g, '');

    // do table replacement

    var tableRegEx =
      /<AACETableInsertPosition [^>]*>(.*?)<\/AACETableInsertPosition>/gi;
    var matches = html.match(tableRegEx);

    var quicklook = '';

    if (matches) {
      for (let j = 0; j < matches.length; j++) {
        var match = matches[j];
        let id = match.substr(match.indexOf('id=') + 3);
        id = id.substr(0, id.indexOf(' '));
        id = id.replace('"', '');
        id = id.replace('"', '');
        var drugTable = undefined;

        for (let aTableTable of drugTables) {
          if (aTableTable.ID === id) drugTable = aTableTable;
        }

        var tableHtml = this.tableHelperProvider.tableAndBoxesReverse(
          drugTable,
          799,
          1,
          'G020',
          true,
          false,
          this.dataController,
        );

        html = html.replace(match, tableHtml);
      }
    }

    return html;
  }
  preProcesshtml(html, CMGL) {
    let preProcessedhtml = html.replace(/<p-left(?:[^>]*)\/>/g, '');
    const results = preProcessedhtml.match(/<[^>]*\/>/g);

    try {
      if (results) {
        for (let aMatch of results) {
          if (!aMatch.startsWith('<img') && !aMatch.startsWith('<br/>')) {
            this.errorList.push(`${aMatch}-${CMGL.GLID}`);
            preProcessedhtml = preProcessedhtml.replace(aMatch, '');
          }
        }
      }
    } catch (e) {
      console.log(e.message);
    }

    // if (preProcessedhtml.includes('/>')) {
    //     console.log(CMGL.GLID);
    // }

    return preProcessedhtml;
  }
  addEndMatter(CMGL) {
    let result = '<div class="SWASTEndmatter">';

    if (CMGL.publ_guideline_approved_by) {
      result +=
        '<div class="SWASTRefNo"><b>Approved by:</b> ' +
        CMGL.publ_guideline_approved_by +
        '</div>';
    }
    if (CMGL.publ_guideline_written_by) {
      result +=
        '<div class="SWASTRefNo"><b>Written by:</b> ' +
        CMGL.publ_guideline_written_by +
        '</div>';
    }
    if (CMGL.publ_guideline_version) {
      result +=
        '<div class="SWASTRefNo"><b>Version:</b> ' +
        CMGL.publ_guideline_version +
        '</div>';
    }
    if (CMGL.publ_guideline_date_issued) {
      result +=
        '<div class="SWASTRefNo"><b>Date issued:</b> ' +
        CMGL.publ_guideline_date_issued +
        '</div>';
    }
    if (CMGL.publ_guideline_date_review) {
      result +=
        '<div class="SWASTRefNo"><b>Review date:</b> ' +
        CMGL.publ_guideline_date_review +
        '</div>';
    }
    if (CMGL.publ_guideline_date_expiry) {
      result +=
        '<div class="SWASTRefNo"><b>Expiry date:</b> ' +
        CMGL.publ_guideline_date_expiry +
        '</div>';
    }
    if (CMGL.publ_guideline_date_approved) {
      result +=
        '<div class=SWASTRefNo><b>Approval date:</b> ' +
        CMGL.publ_guideline_date_approved +
        '</div>';
    }
    if (CMGL.publ_guideline_date_expiry) {
      result +=
        '<div class=SWASTRefNo><b>Expiry date:</b> ' +
        CMGL.publ_guideline_date_expiry +
        '</div>';
    }
    if (CMGL.publ_guideline_directorate) {
      result +=
        '<div class=SWASTRefNo><b>Directorate:</b> ' +
        CMGL.publ_guideline_directorate +
        '</div>';
    }

    result += '</div>';

    return result;
  }
  addFrontMatter(CMGL) {
    let result = '<div class="SWASTFrontmatter">';

    if (CMGL.publ_guideline_clinical_publication_category) {
      result += '<div class=SWASTSUBHead>Clinical Publication Category';
      var category = 'SWAST-GREEN';

      if (
        CMGL.publ_guideline_clinical_publication_category
          .toUpperCase()
          .indexOf('GREEN') != -1
      ) {
        category = 'SWAST-GREEN';
      }
      if (
        CMGL.publ_guideline_clinical_publication_category
          .toUpperCase()
          .indexOf('AMBER') != -1
      ) {
        category = 'SWAST-AMBER';
      }
      if (
        CMGL.publ_guideline_clinical_publication_category
          .toUpperCase()
          .indexOf('MANDATORY') != -1
      ) {
        category = 'SWAST-MANDATORY';
      }

      result +=
        '<div class=' +
        category +
        '>' +
        CMGL.publ_guideline_clinical_publication_category +
        '</div>';
      result += '</div>';
    }

    if (CMGL.publ_guideline_type) {
      result +=
        '<div class=SWASTRefNo><b>Guideline Type:</b> ' +
        CMGL.publ_guideline_type +
        '</div>';
    }
    if (CMGL.publ_guideline_staff_grades) {
      result += '<div class=SWASTRefNo><b>Staff Grades: </b>';
      result += CMGL.publ_guideline_staff_grades + '</div>';
    }
    if (CMGL.publ_guideline_supplements_replaces) {
      result +=
        '<div class=SWASTRefNo><b>Supplements / replaces:</b> ' +
        CMGL.publ_guideline_supplements_replaces +
        '</div>';
    }
    if (CMGL.publ_guideline_no_ref) {
      result +=
        '<div class=SWASTRefNo><b>Reference No:</b> ' +
        CMGL.publ_guideline_no_ref +
        '</div>';
    }
    if (CMGL.publ_guideline_legal_category) {
      result +=
        '<div class=SWASTRefNo><b>Legal category:</b> ' +
        CMGL.publ_guideline_legal_category +
        '</div>';
    }
    if (CMGL.publ_guideline_target_audience) {
      result +=
        '<div class=SWASTRefNo><b>Target Audience:</b> ' +
        CMGL.publ_guideline_target_audience +
        '</div>';
    }
    if (CMGL.publ_keyword_taxonomy) {
      result +=
        '<div class=SWASTRefNo><b>Keywords:</b> ' +
        CMGL.publ_keyword_taxonomy +
        '</div>';
    }

    result += '</div>';
    return result;
  }
  topLevelSection(CMGL, $) {
    //return list of top level html element publ_subsection

    // let tElem = $(CMGL.html.replace(/<p-left(?:[^>]*)\/>/g, '')).filter(
    //   'publ_subsection',
    // );
    let tElem = $(newHtml.replace(/<p-left(?:[^>]*)\/>/g, '')).filter(
      'publ_subsection',
    );

    let results = [];
    for (let aSection of tElem) {
      var dive = this.dive(aSection, $);
      if (dive === 0) results.push(aSection);
    }

    return results;
  }
  matterForTrust(trustName) {
    return trustName;
  }
  addTitle() {
    return true;
  }
  count(string, toCount) {
    try {
      var result = string.split(toCount);
      if (result && result.length > 0) {
        return result.length - 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
    }
    return 0;
  }
  replaceAfter(localHtml, afterStr, replacementStr, replaceWithStr) {
    var position = localHtml.indexOf(afterStr);
    if (position !== 1) {
      var start = localHtml.substr(0, position + afterStr.length);
      var remainder = localHtml.substr(position + afterStr.length);
      remainder = remainder.replace(replacementStr, replaceWithStr);
      var replaced = start + remainder;
      return replaced;
    } else {
      replaced = localHtml.replace(replacementStr, replaceWithStr);
      return replaced;
    }
  }
  dive(element, $) {
    var deepness = 0;
    var diveComplete = false;
    var parent;
    var temp = element;
    while (!diveComplete) {
      parent = temp.parentElement;
      if (!parent) break;
      if (
        parent.tagName === 'PUBL_SUBSECTION' ||
        this.outerHTML(parent, $).startsWith('<publ_subsection>')
      ) {
        temp = parent;
        deepness++;
      } else {
        if (parent.parentElement === null) diveComplete = true;
        temp = parent;
      }
    }
    return deepness;
  }
  isDrug(CMGL) {
    var result = false;
    var GLID = CMGL.GLID;
    if (GLID.indexOf('D0') == 0) {
      return true;
    }
    if (CMGL === undefined) {
      return false;
    }
    var drugTypes = ['Medicines Protocol', 'Patient Group Direction'];
    let res = drugTypes.find(
      aDrugType => aDrugType === CMGL.publ_guideline_type,
    );
    if (res) {
      return true;
    }
    return result;
  }
  columnWidth(widths) {
    var result = '';
    widths += '';
    if (widths !== undefined) {
      var widthArray = widths.split(' ');
      var i = 0;
      for (i = 0; i < widthArray.length; i++) {
        result += '<col width="' + widthArray[i] + '%">';
      }
    }
    return result;
  }
  getAACETable(id, guideline) {
    var tableSection = this.getFigureSectionById(id, guideline);
    var tableHtml = this.getHtmlForTable(tableSection, this.guidelineId);
    return {section: tableSection, html: tableHtml};
  }
  getAACEFigure(id, guideline) {
    var figureSection = this.getFigureSectionById(id, guideline);
    if (figureSection) {
      var htmlText = '<div class="AACEFigure">';
      var src = figureSection.image;
      htmlText +=
        '<img class="img11" src=' +
        src +
        ' ng-click="figureImageClick(\'' +
        figureSection['image'] +
        '\')" >';
      var captions = figureSection['caption'];
      htmlText += '<div class=AACEFigureCaption>' + captions + '</div>';
      var showALGButton = false;
      if (showALGButton) {
        htmlText += '<div class="col text-center">';
        htmlText +=
          '<div class="button button-outline button-positive" ng-click="gotoFig(' +
          figureSection['LinkRef'] +
          ')">Algorithm step by step</div>';
        htmlText += '</div>';
      }
      htmlText += '</div>';
      return htmlText;
    }
    return null;
  }
  getFigureSectionById(id, guideline) {
    for (var i = 0; i < guideline.sections.length; i++) {
      var section = guideline.sections[i];
      if (section.TableAndBoxes) {
        for (var j = 0; j < section.TableAndBoxes.length; j++) {
          var figureSection = section.TableAndBoxes[j];
          if (
            figureSection['cpg-figurecaptionID'] == id ||
            figureSection.ID == id
          ) {
            return figureSection;
          }
        }
      }
    }
    return null;
  }
  getHtmlForTable(tableSection, guidelineId) {
    if (tableSection) {
      var type = tableSection['Type'];
      if (type == 'table') {
        var guidelineTable = tableSection['rowColData'];
        var tableHtml = '<div class="ACCETableContainer">';
        tableHtml += '<div class=GLTableTitle>' + tableSection.title + '</div>';
        var maxColumns = 1;
        guidelineTable.forEach(function (entry) {
          if (entry.columns.length > maxColumns) {
            maxColumns = entry.columns.length;
          }
        });
        var tableStyle = 'GLTable';
        if (maxColumns > 2) {
          tableStyle = 'GLTable GLTableWide';
        }
        tableHtml +=
          '<ion-scroll has-bouncing="false" style="padding-bottom: 10px;" direction="x">';
        if (tableSection['figuretype']) {
          tableStyle += ' AACE' + tableSection['figuretype'];
        }
        tableHtml += 'table style="" class="' + tableStyle + '">';
        for (var i = 0; i < guidelineTable.length; ++i) {
          tableHtml += '<tr>';
          var row = guidelineTable[i];
          for (var j = 0; j < row.columns.length; ++j) {
            var column = row.columns[j];
            if (column.text.indexOf('Table 3.1</link>') != -1) {
              console.log(column.text);
            }
            if (column['rowspan']) {
              tableHtml +=
                '<td rowspan="' +
                column['rowspan'] +
                ' ">' +
                column.text +
                '</td>';
            } else if (column['colspan'].length > 0) {
              tableHtml +=
                '<td colspan="' +
                column['colspan'] +
                ' ">' +
                column.text +
                '</td>';
            } else {
              tableHtml += '<td>' + column.text + '</td>';
            }
          }
          tableHtml += '</tr>';
        }
        tableHtml += '</table>';
        tableHtml +=
          '<div class=AACETableTitle>' + tableSection['table_note'] + '</div>';
        tableHtml += '</ion-scroll>';
        tableHtml += '</div>';
        return tableHtml;
      } else if (type == 'DrugTable') {
        tableHtml = this.tableAndBoxesReverse(
          tableSection,
          799,
          1,
          guidelineId,
          true,
          false,
        );
        return tableHtml;
      } else {
        console.log('Unknown table type');
        return '';
      }
    }
  }
  doesTableHaveNoteText(glTable) {
    var result = false;
    try {
      for (var i = 0; i < glTable.length; i++) {
        var entry = glTable[i];
        var columns = entry.columns;
        columns.forEach(function (aColumn) {
          if (aColumn['dataType'] === 'drug-note') {
            if (aColumn['text'].length > 0 && aColumn['text'] !== 'NOTE') {
              result = true;
            }
          }
        });
        if (result) break;
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }
  doesTableHaveWeightText(glTable) {
    var result = false;
    try {
      for (var i = 0; i < glTable.length; i++) {
        var entry = glTable[i];
        var columns = entry.columns;
        columns.forEach(function (aColumn) {
          if (aColumn['dataType'] === 'drug-weight') {
            if (aColumn['text'].length > 0 && aColumn['text'] !== 'WEIGHT') {
              result = true;
            }
          }
        });
        if (result) break;
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }
  tableAndBoxesReverse(
    tableAndBoxes,
    width,
    pos,
    guidelineID,
    showTitle,
    reverse,
  ) {
    var FEED2 = false;
    if (tableAndBoxes['FEED2'] === 'YES') {
      FEED2 = true;
    }
    var guidelineTable = tableAndBoxes['rowColData'];
    this.reorderDrugTable(guidelineTable);
    var tableHtml = '';
    var columnCount = guidelineTable.length;
    var selectedAge = {
      name: 'All',
      age: 'All',
      months: -1,
      PFA: 'none',
      range: '',
    };
    var pageForAge = this.isPageForAge(guidelineID);
    if (selectedAge.months >= 0) {
      columnCount = 2;
    }
    if (pageForAge) {
      columnCount = guidelineTable.length;
    }
    if (columnCount > 8) {
      columnCount = 'Wide';
      width = 799;
    }
    tableHtml +=
      '<div class=AACEDrugTableContainer id=' +
      guidelineID +
      'dosageTable' +
      tableAndBoxes['ID'] +
      '>';
    if (columnCount > 8) {
      columnCount = 'Wide';
    }
    var ageRangeCol = 0;
    if (showTitle) {
      tableHtml +=
        '<div class=AACETableTitle>' + tableAndBoxes['title'] + '</div>';
    }
    var idAdded = true;
    tableHtml +=
      '<ion-scroll style="padding-bottom: 15px;" has-bouncing="false" direction="x">';
    var rowsAdded = 0;
    tableHtml += '<table class=AACEDrugTablePFA' + columnCount + '>';
    var firstRow = guidelineTable[0];
    var skipRowPos = 0;
    if (
      firstRow.columns[0].text == '999' ||
      firstRow.columns[0].text == '9999'
    ) {
      skipRowPos = 1;
    }
    var notes = [];
    if (FEED2) {
      var columnsToUseInOrder = [];
      var hasNoteText = this.doesTableHaveNoteText(guidelineTable);
      var hasWeight = this.doesTableHaveWeightText(guidelineTable);
      skipRowPos = 0;
      if (tableAndBoxes['figuretype'] === 'dosage8') {
        columnsToUseInOrder = [
          'drug-age',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
        if (hasWeight) {
          columnsToUseInOrder.splice(1, 0, 'drug-weight');
        }
      }
      if (tableAndBoxes['figuretype'] === 'dosage9') {
        columnsToUseInOrder = [
          'drug-age',
          'drug-weight',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
      }
      if (
        tableAndBoxes['figuretype'] === 'quickref' ||
        tableAndBoxes['figuretype'] === 'intravascular' ||
        tableAndBoxes['figuretype'] === 'reversal' ||
        tableAndBoxes['figuretype'] === 'cardiac'
      ) {
        columnsToUseInOrder = [
          'drug-name',
          'drug-route',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
        skipRowPos = 0;
        reverse = true;
      }
      // internal debug field so don't include
      if (false) {
        columnsToUseInOrder.push('drug-age');
        columnsToUseInOrder.push('drug-months');
        columnsToUseInOrder.push('drug-start-age');
        columnsToUseInOrder.push('drug-end-age');
      }
      if (!reverse) {
        for (var i = skipRowPos; i < columnsToUseInOrder.length; i++) {
          tableHtml += '<tr>';
          for (var j = 0; j < guidelineTable.length; j++) {
            var row = guidelineTable[j];
            var range = -1;
            var column = this.columnForDataType(
              row.columns,
              columnsToUseInOrder[i],
            );
            range = row.columns[0].text;
            if (column) {
              if (pageForAge || this.isDateInRange(range, selectedAge)) {
                if (i == skipRowPos) {
                  tableHtml += '<th>';
                  var columnText = this.removeNote(column.text, notes);
                  tableHtml += columnText;
                  tableHtml += '</th>\n';
                } else {
                  tableHtml += '<td>';
                  columnText = this.removeNote(column.text, notes);
                  tableHtml += columnText;
                  tableHtml += '</td>\n';
                }
                if (j > 0) {
                  rowsAdded++;
                }
              }
            }
          }
          tableHtml += '</tr>\n';
        }
      } else {
        for (var i = 0; i < guidelineTable.length; ++i) {
          row = guidelineTable[i];
          tableHtml +=
            '<tr ng-click="tableRow(\'' +
            tableAndBoxes['ID'] +
            "'," +
            i +
            ')">';
          for (var j = 0; j < columnsToUseInOrder.length; ++j) {
            column = this.columnForDataType(
              row.columns,
              columnsToUseInOrder[j],
            );
            if (column) {
              if (i == 0) {
                tableHtml += '<th>';
                columnText = this.removeNote(column.text, notes);
                tableHtml += columnText;
                tableHtml += '</th>\n';
              } else {
                if (j == 0) {
                  tableHtml += '<td class="blueUnderLine">';
                } else {
                  tableHtml += '<td>';
                }
                columnText = this.removeNote(column.text, notes);
                tableHtml += columnText;
                tableHtml += '</td>\n';
              }
            }
          }
        }
        tableHtml += '</tr>';
        rowsAdded++;
      }
    } else {
      if (!reverse) {
        for (var i = skipRowPos; i < firstRow.columns.length; i++) {
          tableHtml += '<tr>';
          for (var j = 0; j < guidelineTable.length; j++) {
            row = guidelineTable[j];
            range = -1;
            if (skipRowPos > 0) {
              range = row.columns[0].text;
            }
            if (pageForAge || this.isDateInRange(range, selectedAge)) {
              if (i == skipRowPos) {
                tableHtml += '<th>';
                columnText += this.removeNote(row.columns[i].text, notes);
                tableHtml += columnText;
                tableHtml += '</th>\n';
              } else {
                tableHtml += '<td>';
                columnText = this.removeNote(row.columns[i].text, notes);
                tableHtml += columnText;
                tableHtml += '</td>\n';
              }
              if (j > 0) {
                rowsAdded++;
              }
            }
          }
          tableHtml += '</tr>\n';
        }
      } else {
        for (var i = 0; i < guidelineTable.length; i++) {
          row = guidelineTable[i];
          tableHtml +=
            '<tr ng-click="tableRow(\'' +
            tableAndBoxes['ID'] +
            "'," +
            i +
            ')">';
          for (var j = 0; j < row.columns.length; ++j) {
            if (i == 0) {
              tableHtml += '<th>';
              columnText = this.removeNote(row.columns[j].text, notes);
              tableHtml += columnText;
              tableHtml += '</th>\n';
            } else {
              if (j == 0) {
                tableHtml += '<td class="blueUnderLine">';
              } else {
                tableHtml += '<td>';
              }
              columnText = this.removeNote(row.columns[j].text, notes);
              tableHtml += columnText;
              tableHtml += '</td>\n';
            }
          }
          tableHtml += '</tr>\n';
          rowsAdded++;
        }
      }
    }
    if (rowsAdded == 0) {
      var pointToReplace = tableHtml.indexOf('<table class=ACCEDrugTablePFA');
      tableHtml = tableHtml.substr(0, pointToReplace);
      tableHtml +=
        '<div>It is NOT recommended to administer this drug to a person in the age range you have selected. See the full guideline for age restrictions</div>';
    } else {
      tableHtml += '</table>';
      tableHtml +=
        '<div class=AACETableTitle>' + tableAndBoxes['table_note'] + '</div>';
    }
    tableHtml += '</ion-scroll>';
    if (notes.length > 0) {
      tableHtml += '<div class=AACEDrugTableNotes>NOTES: ';
      for (var i = 0; i < notes.length; i++) {
        tableHtml += notes[i];
      }
      tableHtml += '</div>';
    }
    tableHtml += '</div>';
    return tableHtml;
  }
  reorderDrugTable(rowColsData) {
    try {
      var last = rowColsData[rowColsData.length - 1];
      if (last['columns'][0].text == '9999') {
        rowColsData.unshift(rowColsData.pop());
      }
    } catch (error) {
      console.log('reorderDrugTable: ' + error);
    }
  }
  isPageForAge(guidelineId) {
    return guidelineId.indexOf('P') == 0;
  }
  columnForDataType(columns, dataType) {
    try {
      for (var i = 0; i < columns.length; i++) {
        var column = columns[i];
        if (column['dataType'] === dataType) {
          return column;
        }
      }
    } catch (error) {
      console.log('columnForDataType: ' + error);
    }
    return null;
  }
  isDateInRange(range, selectedAge) {
    return true;
  }
  removeNote(columnText, notes) {
    var result = columnText;
    var note = this.aTableHelper.getElement(columnText, 'note');
    var noteRef = this.aTableHelper.getElement(columnText, 'note_ref');
    var count = 0;
    while (note.length > 0) {
      if (noteRef.length > 0) {
        // notes.push(noteRef);
      }
      // notes.push(note);
      result = result.replace(note, '');
      note = this.aTableHelper.getElement(result, 'note');
      if (note.length > 0) {
        var nextNoteRef = result.slice(
          result.indexOf(noteRef) + noteRef.length,
        );
        noteRef = this.aTableHelper.getElement(nextNoteRef, 'note_ref');
      }
      count++;
    }
    return result;
  }
  getElement(htmlText, elementName) {
    var startString = '<' + elementName + '>';
    var startPos = htmlText.indexOf(startString);
    if (startPos == -1) {
      startString = '<' + elementName + ' ';
      startPos = htmlText.indexOf(startString);
    }
    var endString = '</' + elementName + '>';
    var endPos = htmlText.indexOf(endString);
    if (startPos != -1 && endPos != -1 && endPos > startPos) {
      var result = htmlText.substr(startPos, endPos + endString.length);
      return result;
    }
    return '';
  }
  findWithAttr(array, attribute, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][attribute] === value) {
        return i;
      }
    }
    return -1;
  }
  outerHTML(node, $) {
    var processedNode = $(node);
    var outerHtml = $.html(processedNode);
    return outerHtml;
  }
}
