/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
  Linking,
} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

var _ = require('lodash');

type Props = {};

type State = {};

export default class MainMenuScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: '',
      headerShown: false,
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
    }
  }

  goToProfile() {
    this.props.navigation.navigate('Profile', {}, this.state);
  }

  gotoSubscriptions() {
    this.props.navigation.navigate('Subscription', {}, this.state);
  }

  goToSettings() {
    this.props.navigation.navigate('Settings', {}, this.state);
  }

  async sendFeedback() {
    this.props.screenProps.sendSupportEmail('ParaPass Feedback', 'feedback');
  }

  goToAbout() {
    var html = `
    <h2>Background: The ParaPass app</h2>
<p>The ParaPass app is for information and CPD (Continuing Professional Development) purposes only and is neither a diagnostic nor a treatment tool. By accessing this app, you agree to these terms and conditions of use.</p>
<p>The ParaPass app can be used by individual clinicians using iOS and Android devices. A subscription can be purchased via the Apple App Store or Google Play. The app can also be used via a website (<a href="https://www.parapassweb.co.uk">https://www.parapassweb.co.uk<\a>). For more information please go to <a href="https://www.classprofessional.co.uk/digital-products/apps/parapass-app/">https://www.classprofessional.co.uk/digital-products/apps/parapass-app/.</a></p>
<p>The app can also be provided by ambulance trusts for their staff, in which case the subscription will be arranged via Class Publishing directly.</p>
<h2>Privacy</h2>
<p>Class Professional Publishing will hold any information that you provide to us confidentially.</p>
<p>When you create an account on the ParaPass app we ask for your name and email address so we know who you are. We use a trusted third-party supplier to hold this user data securely. Class Professional Publishing collects data on who has logged in to the ParaPass app.</p>
<p>To work correctly, the ParaPass app also has to store your results data and progress on all material you interact with.</p>
<p>Should the app subscription be provided to you by your ambulance trust or other organisation, this data may also be made available to them and any parties they choose to share this information with.</p>
<h2>Your Rights</h2>
<p>Under the General Data Protection Regulation (GDPR) and other information rights laws, you have rights as an individual which we will always respect. You can <a href="https://ico.org.uk/">read more about your rights here</a></p>
<h2>Disclaimer</h2>
<p>Quizzes, case studies and Standby CPD issues in the ParaPass app are published by Class Publishing, an imprint of Class Professional Publishing.</p>
<p>A number of the resources in the ParaPass app relate to the JRCALC Guidelines, which are published by Class Professional Publishing and approved by the Association of Ambulance Chief Executives (AACE) and the Joint Royal Colleges Ambulance Liaison Committee (JRCALC).</p>
<p>Class Professional Publishing, the Association of Ambulance Chief Executives and the Joint Royal Colleges Ambulance Liaison Committee (collectively 'the Suppliers' or 'We' as the context admits) have made every effort to ensure that the information, tables, drawings and diagrams in this Content are accurate at the time of publication.</p>
<p>However, Users of this Content ('Users/You/Your') must be aware that the Content cannot always contain all the information necessary for determining appropriate care, cannot address all individual situations and is not a substitute for sound clinical judgment. You and all individuals You permit to use this Content must ensure You and they have the appropriate knowledge and skills to enable suitable interpretation. Users must always be aware that such innovations or alterations after the date of publication may not be incorporated in the Content.</p>
<p>The Suppliers shall not be liable and shall not compensate you or any other party for any loss or damage You may suffer unless We have breached any duties imposed on us by law, including if We have caused death or personal injury by our negligence and that failure is not attributed to: (a) Your own fault; (b) the fault of a third party; or (c) any other events which We could not have foreseen or forestalled even if We had taken reasonable care.</p>
<p>Please note, however, the Suppliers assume no responsibility whatsoever for the content of external resources referred to in the text or accompanying online materials.</p>
<p>The ParaPass app links to NICE guidance in some cases. NICE guidance is prepared for the National Health Service in England and is subject to regular review and may be updated or withdrawn. NICE has not checked the use of its content in this publication to confirm that it accurately reflects the NICE publications from which it is taken.</p>
<h2>FAQs</h2>
<p>Read the <a href="https://www.classprofessional.co.uk/faqs#parapass-app">FAQ here</a>
<h2>Contacting Us</h2>
<p>Class Professional Publishing will be pleased to answer any questions you have about the ParaPass app. You can call us on 01278 427 800 or email us: <a href="mailto:apps@class.co.uk">apps@class.co.uk</a></p>
    `;

    this.props.navigation.navigate('HtmlInfo', {
      title: 'Help & Info',
      html: html,
    });
  }

  goToUsingApp() {
    this.props.screenProps.showOnboarding();
  }

  render() {
    var emailAddress = '';

    if (this.props.screenProps.user !== null) {
      emailAddress = this.props.screenProps.user.email;
    }

    var menuItems = [
      {
        title: 'Profile',
        subTitle: emailAddress,
        icon: (
          <Image
            style={{width: 36, height: 36}}
            source={require('../assets/Glyph_Large_Profile.png')}
          />
        ),
        action: this.goToProfile.bind(this),
      },
      {
        title: 'My Subscriptions',
        subTitle: 'Manage and change',
        icon: (
          <Image
            style={{width: 36, height: 36}}
            source={require('../assets/Glyph_Large_Purchase.png')}
          />
        ),
        action: this.gotoSubscriptions.bind(this),
      },
      {
        title: 'Settings',
        subTitle: 'Change font size',
        icon: (
          <Image
            style={{width: 36, height: 36}}
            source={require('../assets/Glyph_Large_Settings.png')}
          />
        ),
        action: this.goToSettings.bind(this),
      },
      {
        title: 'Send Feedback',
        subTitle: 'Provide feedback & get in contact',
        icon: (
          <Image
            style={{width: 36, height: 36}}
            source={require('../assets/Glyph_Large_Feedback.png')}
          />
        ),
        action: this.sendFeedback.bind(this),
      },
      {
        title: 'Using the app',
        subTitle: "View main features and what's new",
        icon: (
          <Image
            style={{width: 36, height: 36}}
            source={require('../assets/Glyph_Large_Help.png')}
          />
        ),
        action: this.goToUsingApp.bind(this),
      },
      {
        title: 'About the app',
        subTitle: 'Info and FAQs',
        icon: (
          <Image
            style={{width: 36, height: 36}}
            source={require('../assets/Glyph_Large_More.png')}
          />
        ),
        action: this.goToAbout.bind(this),
      },

      // {
      //   title: 'Question units',
      //   subTitle: 'View your progress',
      //   icon: (<Image style={{width: 31, height: 25}} source={require('../assets/menu_packs.png')}/>),
      //   action: () => this.displayQuestionPacks()
      // },
      // {
      //   title: 'Buy more question units',
      //   subTitle: 'Subscriptions and special units',
      //   icon: (<Image style={{width: 31, height: 26}} source={require('../assets/menu_more.png')}/>),
      //   action: () => this.displayBuyScene()
      // },
      // {
      //   title: 'Create custom quiz unit',
      //   subTitle: 'Make a unit to suit you',
      //   icon: (<Image style={{width: 36, height: 28}} source={require('../assets/menu_custom.png')}/>),
      //   action: () => this.displayCustomQuiz()
      // },
      // {
      //   title: 'Help and info',
      //   subTitle: 'FAQs and troubleshooting',
      //   icon: (<Image style={{width: 27, height: 25}} source={require('../assets/menu_help.png')}/>),
      //   action: () => this.displayHelpInfo()
      // },
      // {
      //   title: 'About the app',
      //   subTitle: 'Version and copyright information',
      //   icon: (<Image style={{width: 27, height: 8}} source={require('../assets/menu_about.png')}/>),
      //   action: () => this.displayAboutApp()
      // },
      // {
      //   title: 'Settings',
      //   subTitle: 'Change basic settings',
      //   icon: (<Image style={{width: 29, height: 29}} source={require('../assets/menu_settings.png')}/>),
      //   action: () => this.displaySettings()
      // },
      // {
      //   title: 'Send Feedback',
      //   subTitle: 'Provide feedback on the app',
      //   icon: (<Image style={{width: 29, height: 27}} source={require('../assets/menu_feedback.png')}/>),
      //   action: () => this.sendTestFeedback()
      // },
    ];

    var menuRowLayout = [];

    for (var menuItem of menuItems) {
      var aMenuRowLayout = (
        <TouchableOpacity key={menuItem.title} onPress={menuItem.action}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingHorizontal: 7,
              paddingVertical: 14,
              borderBottomWidth: 1,
              borderBottomColor: colors.Grey200,
            }}>
            <View style={{width: 36}}>{menuItem.icon}</View>

            <View style={{flex: 1, marginHorizontal: 7}}>
              <DynamicText
                style={{
                  fontSize: 18,
                  fontWeight: '600',
                  letterSpacing: -0.46,
                  color: colors.ButtonBlue,
                }}>
                {menuItem.title}
              </DynamicText>
              <DynamicText
                style={{fontSize: 12, letterSpacing: 0, color: colors.Grey700}}>
                {menuItem.subTitle}
              </DynamicText>
            </View>

            <Image
              style={styles.disclosureArrow}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
          <View style={styles.rowSeparator} />
        </TouchableOpacity>
      );

      menuRowLayout.push(aMenuRowLayout);
    }

    var logoMarginTop = 0;

    if (Platform.OS === 'ios') {
      logoMarginTop = 20;
    }

    var ambulanceBarTiles = [],
      ambulanceBarTileWidth = 46,
      windowWidth = Dimensions.get('window').width;

    for (
      var ambulanceBarTileIndex = 0;
      ambulanceBarTileIndex < Math.ceil(windowWidth / ambulanceBarTileWidth);
      ambulanceBarTileIndex++
    ) {
      ambulanceBarTiles.push(
        <View key={ambulanceBarTileIndex} style={{flexDirection: 'row'}}>
          <View
            style={{width: 23, height: 7, backgroundColor: colors.StandbyGreen}}
          />
          <View
            style={{
              width: 23,
              height: 7,
              backgroundColor: colors.StandbyYellow,
            }}
          />
        </View>,
      );
    }

    return (
      <View style={styles.container}>
        <ScrollView
          style={{flex: 1}}
          scrollIndicatorInsets={{right: 1}}
          contentContainerStyle={{alignItems: 'stretch'}}>
          <View
            style={{
              alignItems: 'stretch',
              paddingTop: 22,
              backgroundColor: colors.NavBar,
              height: 94,
            }}>
            <View
              style={{
                flex: 1,
                marginTop: logoMarginTop,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Image
                style={{width: 150, height: 24}}
                resizeMode={'contain'}
                source={require('../assets/Logo_ParaPass_Short.png')}
              />
            </View>
            <View style={{flexDirection: 'row', height: 7}}>
              {ambulanceBarTiles}
            </View>
          </View>
          {/* <View style={{flex: 1, flexDirection:'row', alignItems:'stretch', backgroundColor: '#7FAEC1'}}>
              <Image style={{flex: 1, margin: 30, width: 191, height: 65}} resizeMode={'contain'} source={require('../assets/logo_big.png')}/>
            </View> */}

          {menuRowLayout}

          <View style={{height: 30}}></View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey000,
  },
});
