/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component, PureComponent} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

export default class FilterChip extends PureComponent {
  constructor(props) {
    super(props);
  }

  onPress() {
    if (this.props.onPress) {
      this.props.onPress(this.props.name);
    }
  }

  render() {
    let iconSrc = '';

    if (this.props.name === 'Quiz') {
      iconSrc = this.props.selected
        ? require('../assets/Icon_Quizzes_White.png')
        : require('../assets/Icon_Quizzes_Grey70.png');
    } else if (this.props.name === 'Standby CPD') {
      iconSrc = this.props.selected
        ? require('../assets/Icon_Standby_White.png')
        : require('../assets/Icon_Standby_Grey70.png');
    } else if (this.props.name === 'Case Study') {
      iconSrc = this.props.selected
        ? require('../assets/Icon_Case_Studies_White.png')
        : require('../assets/Icon_Case_Studies_Grey70.png');
    } else if (this.props.name === 'PGD' || this.props.name === 'PMA') {
      iconSrc = this.props.selected
        ? require('../assets/Icon_PGD_White.png')
        : require('../assets/Icon_PGD_Grey70.png');
    } else if (this.props.name === 'Book') {
      iconSrc = this.props.selected
        ? require('../assets/Icon_Books_White.png')
        : require('../assets/Icon_Books_Grey70.png');
    }

    let backgroundColor = 'transparent';
    let textColor = '#495057';
    let borderColor = '#868e96';

    if (this.props.selected) {
      backgroundColor = '#007aff';
      textColor = colors.white;
      borderColor = '#007aff';
    }

    let chipStyle = {
      flex: 0,
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 8,
      paddingHorizontal: 15,
      paddingVertical: 5,
      borderRadius: 40,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      borderWidth: 1,
    };
    if (Platform.OS === 'web') {
      chipStyle = {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 8,
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 40,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      };
    }

    return (
      <TouchableOpacity style={chipStyle} onPress={this.onPress.bind(this)}>
        {iconSrc !== '' ? (
          <Image
            style={{marginRight: 6, width: 20, height: 20}}
            source={iconSrc}
          />
        ) : null}
        <Text style={{fontWeight: '600', fontSize: 16, color: textColor}}>
          {this.props.name}
        </Text>
      </TouchableOpacity>
    );

    return (
      <div
        className={`filterChip ${
          this.props.selected ? 'filterChipSelected' : ''
        }`}
        onClick={this.props.onClick.bind(this, this.props.name)}>
        {iconSrc !== '' ? (
          <img className="filterChipIcon" src={iconSrc} alt="" />
        ) : null}
        <div>{this.props.name}</div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
});
