/**
 * Victoria County History
 * https://github.com/facebook/react-native
 * @flow
 */
'use strict';

import {Platform, Dimensions} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { isIphoneX } from 'react-native-iphone-x-helper'
import {EventRegister} from 'react-native-event-listeners';

export default {
  isTablet() {
    const {width} = Dimensions.get('window');
    return width >= 768;
  },

  getMenuWidth() {
    if (Platform.OS === 'web') {
      return 360;
    } else {
      var width = Dimensions.get('window').width;

      if (this.isCompact()) {
        return width - 60;
      } else {
        return 320;
      }
    }
  },

  isCompact() {
    var width = Dimensions.get('window').width;

    if (Platform.OS === 'web') {
      return false;
    } else {
      if (width <= 675) {
        return true;
      } else {
        return false;
      }
    }
  },

  shouldDisplayMenu() {
    var width = Dimensions.get('window').width;

    if (width > 800) {
      return true;
    } else {
      return false;
    }
  },

  getNavigationBarHeight() {
    var navBarHeight = 44;

    if (Platform.OS === 'ios') {
      // if (isIphoneX()) {
      //   navBarHeight = 88;
      // }
    } else if (Platform.OS === 'android') {
      navBarHeight = 44;
    }

    return navBarHeight;
  },

  defaultFontSize() {
    return 18;
  },

  async loadFontSize() {
    var fontSize = await AsyncStorage.getItem('fontSize');

    if (fontSize !== null && fontSize !== undefined) {
      fontSize = parseInt(fontSize);
    } else {
      fontSize = this.defaultFontSize();
    }

    global.customFontSize = fontSize;
  },

  async getFontSize() {
    // var fontSize = await AsyncStorage.getItem('fontSize');

    return global.customFontSize;

    // if (fontSize !== null && fontSize !== undefined) {

    //   fontSize = parseInt(fontSize);

    //   return fontSize;

    // } else {

    //   return this.defaultFontSize();
    // }
  },

  async increaseFontSize() {
    console.log('increasing font size');

    var newFontSize = await this.getFontSize();

    if (newFontSize < 26) {
      newFontSize++;
    }

    global.customFontSize = newFontSize;

    try {
      await AsyncStorage.setItem('fontSize', newFontSize + '');
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    EventRegister.emit('fontSizeUpdated', '');
  },

  async decreaseFontSize() {
    var newFontSize = await this.getFontSize();

    if (newFontSize > 14) {
      newFontSize--;
    }

    global.customFontSize = newFontSize;

    try {
      await AsyncStorage.setItem('fontSize', newFontSize + '');
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    EventRegister.emit('fontSizeUpdated', '');
  },

  async resetFontSize() {
    var newFontSize = this.defaultFontSize();

    global.customFontSize = newFontSize;

    try {
      await AsyncStorage.setItem('fontSize', newFontSize + '');
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    EventRegister.emit('fontSizeUpdated', '');
  },
};
