import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import {colors} from '../../classes/Colors';
import LayoutController from '../../classes/LayoutController';
import BackgroundContainer from './BackgroundContainer';
import {logAnalyticsEvent} from '../../utils/firebase';
import {psirfAnalyticsEventNames, buttonEvents} from '../../constants/psirf';
import CustomMarkdown from '../CustomMarkdown';

const calculateHeight = (text, fontSize = 16, containerWidth, lineHeight) => {
  const baseCharWidth = fontSize * 0.6; // Approximation for typical character widths

  let currentLineWidth = 0;
  let totalLines = 1; // Start with one line by default

  // Regular expression to detect markdown links like [Link Text](URL)
  const linkRegex = /\[([^\]]+)\]\([^)]+\)/g;

  // Replace links with placeholder to simplify character handling
  const processedText = text.replace(linkRegex, (_, linkText) => linkText);

  for (let i = 0; i < processedText.length; i++) {
    const letter = processedText[i];

    // Check for explicit new line characters
    if (letter === '\n') {
      totalLines++; // New line detected, increment line count
      currentLineWidth = 0; // Reset the current line width
    } else {
      // Determine the width of the letter based on its type
      let letterWidth = baseCharWidth; // Default width for most letters

      if (letter.match(/[ijl]/)) {
        letterWidth = baseCharWidth * 0.5; // Narrow letters
      } else if (letter.match(/[A-Z]/)) {
        letterWidth = baseCharWidth * 1.5; // Capitalized letters
      } else if (letter.match(/[\s-]/)) {
        letterWidth = baseCharWidth * 0.5; // Spaces and hyphens
      } else if (letter.match(/[\.,:;'"\(\)\[\]\{\}]/)) {
        letterWidth = baseCharWidth * 0.5; // Punctuation and brackets
      }

      // Increment the line width by the character width
      currentLineWidth += letterWidth;

      // Check if the current line width exceeds container width
      if (currentLineWidth > containerWidth) {
        totalLines++; // Wrap to a new line
        currentLineWidth = letterWidth; // Start new line with the current letter
      }
    }
  }

  // Calculate the final height based on the total number of lines and the line height
  const height = totalLines * lineHeight;

  // + as a buffer
  return height + 8;
};

const KeyPoints = ({content, psirfMetaData}) => {
  const [currentPointIndex, setCurrentPointIndex] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const containerMaxWidth = containerWidths.PSIRFMaxWidth;
    const screenWidth = Dimensions.get('window').width;
    const outerPadding = 16 * 2;
    const innerPadding = 14 * 2;
    const fontSize = 16;
    const lineHeight = 20;
    const outerContainerWidth = Math.min(
      screenWidth - outerPadding,
      containerMaxWidth,
    );
    const containerWidth = outerContainerWidth - innerPadding;

    const contentHeights = content.map(({KeyPointBody}) =>
      calculateHeight(KeyPointBody, fontSize, containerWidth, lineHeight),
    );

    let calculatedHeight = Math.max(...contentHeights);

    // Set a maximum height to avoid overly tall boxes
    if (Platform !== 'web') {
      const maxHeight = LayoutController.isTablet() ? 250 : 350;
      calculatedHeight = Math.min(calculatedHeight, maxHeight);
    }

    setHeight(calculatedHeight);
  }, [content]);

  const handleNext = () => {
    if (currentPointIndex < content.length - 1) {
      setCurrentPointIndex(currentPointIndex + 1);
    }
    logAnalyticsEvent(psirfAnalyticsEventNames.button, {
      event: buttonEvents.keyPointsNext,
      psirf_id: psirfMetaData.psirfId,
    });
  };

  const handleBack = () => {
    if (currentPointIndex > 0) {
      setCurrentPointIndex(currentPointIndex - 1);
    }
    logAnalyticsEvent(psirfAnalyticsEventNames.button, {
      event: buttonEvents.keyPointsBack,
      psirf_id: psirfMetaData.psirfId,
    });
  };

  const nextDisabled = currentPointIndex === content.length - 1;
  const backDisabled = currentPointIndex === 0;
  const singleItem = content.length === 1;
  const title = singleItem ? 'Key Point' : 'Key Points';

  return (
    <BackgroundContainer color="green">
      <View style={styles.container}>
        <Text style={styles.subtitle}>LEARNING</Text>
        <Text style={styles.title}>{title}</Text>
        <View style={{minHeight: height}}>
          <View style={styles.keyPointContainer}>
            <CustomMarkdown>
              {content[currentPointIndex].KeyPointBody}
            </CustomMarkdown>
          </View>
        </View>
        {!singleItem && (
          <>
            <View style={styles.indicatorContainer}>
              {content.map((_, index) => (
                <View
                  key={index}
                  style={[
                    styles.indicatorDot,
                    currentPointIndex === index && styles.activeDot,
                  ]}
                />
              ))}
            </View>
            <View style={styles.navigation}>
              <TouchableOpacity
                onPress={handleBack}
                style={[
                  styles.navButton,
                  backDisabled && styles.buttonDisabled,
                ]}
                disabled={backDisabled}>
                <Text
                  style={[
                    styles.navButtonText,
                    backDisabled && styles.buttonDisabledText,
                  ]}>
                  Back
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleNext}
                style={[
                  styles.navButton,
                  {marginLeft: 9},
                  nextDisabled && styles.buttonDisabled,
                ]}
                disabled={nextDisabled}>
                <Text
                  style={[
                    styles.navButtonText,
                    nextDisabled && styles.buttonDisabledText,
                  ]}>
                  Next
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: containerWidths.PSIRFMaxWidth,
    marginHorizontal: 'auto',
    width: '100%',
    alignSelf: 'center',
  },
  subtitle: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
    color: 'rgba(255, 255, 255, 0.8)',
    paddingBottom: 4,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'left',
    color: colors.white,
    paddingBottom: 20,
  },
  keyPointContainer: {
    flexWrap: 'wrap',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {width: 0, height: 4},
    shadowRadius: 4,
    shadowOpacity: 1,
    // padding 2 because the markdown component adds margin 12
    paddingVertical: 2,
    paddingHorizontal: 14,
    backgroundColor: colors.white,
    borderRadius: 8,
    flex: 1,
  },
  keyPoint: {
    justifyContent: 'center',
    fontSize: 16,
    color: colors.black,
    lineHeight: 20,
  },
  navigation: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 336,
    width: '100%',
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
  navButton: {
    backgroundColor: colors.white,
    padding: 14,
    borderRadius: 7,
    flex: 1,
    width: '100%',
    maxWidth: 160,
  },
  navButtonText: {
    color: colors.black,
    fontSize: 17,
    textAlign: 'center',
    flex: 1,
    fontWeight: '700',
    lineHeight: 20.57,
  },
  indicatorContainer: {
    flexDirection: 'row',
    margin: 22,
    alignSelf: 'center',
  },
  indicatorDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    marginHorizontal: 5,
  },
  activeDot: {
    backgroundColor: colors.white,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  buttonDisabledText: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
});

export default KeyPoints;
