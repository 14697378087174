import React, {forwardRef} from 'react';

import {View, StyleSheet} from 'react-native';
import {colors} from '../../classes/Colors';

const BackgroundContainer = forwardRef(({children, color}, ref) => {
  const BACKGROUND_COLOR_MAP = {
    white: colors.white,
    green: colors.PSIRFForeground,
    grey: 'background: rgba(247, 250, 248, 1)',
  };
  const BORDER_COLOR_MAP = {
    white: colors.PSIRFBorderWhite,
    green: colors.PSIRFBorderGreen,
    grey: colors.PSIRFBorderWhite,
  };

  const backgroundColor = BACKGROUND_COLOR_MAP[color] ?? 'transparent';
  const borderColor = BORDER_COLOR_MAP[color];
  return (
    <View
      ref={ref}
      style={[
        styles.container,
        {backgroundColor: backgroundColor},
        borderColor && {borderBottomColor: borderColor, borderBottomWidth: 1},
      ]}>
      {children}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    width: '100%',
  },
});

export default BackgroundContainer;
