import React from 'react';

import {StyleSheet, ScrollView, Text, View} from 'react-native';

const content = {
  title:
    'ParaPass free hot drink promotion in association with Greggs Plc Terms & Conditions',
  content: [
    'The promoter is: Class Publishing Ltd (company no. 2993127), whose registered office is at The Exchange, Express Park, Bristol Road, Bridgwater, TA6 4RR.',
    'The promotion is open to North East Ambulance Service ParaPass users, who have been granted access to the app through the Trust.',
    'Promotion dates: 01 October 2024 – 31 October 2024.',
    'Code Expiry Date: 30 November 2024, 23:59. Codes shown after the expiry date will not be valid or accepted.',
    'Each participant is eligible for One Free Regular Hot Drink code during October 2024, regardless of the number of quizzes taken.',
    'Codes will be distributed via the ParaPass app upon quiz completion.',
    'Participants must achieve a pass rate of 60% or above in a quiz, on the ParaPass app, to receive a free hot drink code.',
    "It is the user's responsibility to ensure they have access to the code within the app.",
    'Offer is only valid on regular hot drinks (Americano, White Coffee, Cappuccino, Latte, Speciality Latte (vanilla), Flat White, Espresso, Hot Chocolate, Mocha, Tea & Speciality Tea (Green and Peppermint). Also available on regular seasonal hot drinks, all hot drinks are subject to availability).',
    'The unique valid code must be shown to a member of staff to redeem.',
    'The code cannot be used in conjunction with any other offer or Greggs’ loyalty scheme.',
    'Only one code can be used per transaction.',
    'The offer is valid at most Greggs shops. Excluded shops are those situated in MOTO forecourts – Doncaster, Ferrybridge, Toddington North & South, Heston, Hilton Park, Lancaster, Southwaite North, Trowell, Leigh Del East & West, Knutsford, Heston East, Frankly North & South, Donnington Park, Trowell South, Wetherby, Rugby.',
    'The offer is subject to availability and while stocks last. An alternative product may be provided if necessary.',
    'The offer is valid for take-away only.',
    'Class Professional Publishing and Greggs are not responsible for lost or stolen codes.',
    'This promotion is not endorsed, sponsored, or administered by Greggs. Greggs is not responsible for the administration of the promotion, and any questions, comments, or complaints should be directed to Class Professional Publishing.',
    'Class Professional Publishing reserves the right to amend or withdraw the promotion at any time without prior notice.',
    'For any queries regarding the promotion, users can contact Class Professional Publishing customer service at apps@class.co.uk or by calling 01278 427800.',
  ],
};

const PromoInfoContent = () => {
  return (
    // using contentContainerStyle instead of style to allow scrolling
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.title}>{content.title}</Text>
      {content.content.map((item, index) => {
        return (
          <View key={index} style={styles.line}>
            <Text style={[styles.contentItem, styles.bulletPoint]}>
              {`\u2022`}
            </Text>
            <Text style={styles.contentItem}>{`${item}`}</Text>
          </View>
        );
      })}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 20,
    width: '100%',
    maxWidth: 500,
    alignSelf: 'center',
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    marginBottom: 12,
  },
  contentItem: {
    fontSize: 15,
  },
  line: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  bulletPoint: {
    marginRight: 4,
  },
});

export default PromoInfoContent;
