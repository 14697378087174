'use strict';

import React, {Component, PureComponent} from 'react';
import {View, Text, Platform, Image} from 'react-native';

import ProgressView from './ProgressView';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';
import DynamicText from '../components/DynamicText';

var moment = require('moment');

export default class QuizSetCell extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var quizSetName = '';
    var quizSetDate = '';
    var typeLayout = null;
    var questionCount = 0;
    var quizzesCount = 0;
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var correctPercentage = 0;
    var borderLeftColor = colors.white;
    var borderLeftWidth = 0;

    if (this.props.quizSet != null) {
      quizSetDate = moment(this.props.quizSet.date).format('DD MMM YYYY');

      if (this.props.quizSet.type === 'topic') {
        quizSetName = this.props.quizSet.title;

        if (
          this.props.quizSet.trust != null &&
          this.props.quizSet.trust !== ''
        ) {
          var trust = this.props.quizSet.trust;

          typeLayout = (
            <View
              style={{
                justifyContent: 'center',
                paddingHorizontal: 6,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)}
              </Text>
            </View>
          );
        } else {
          typeLayout = (
            <Image
              style={{width: 38, height: 14}}
              source={require('../assets/Logo_JRCALC_Small.png')}
            />
          );
        }
      }

      if (this.props.quizSet.type === 'guideline') {
        quizSetName = this.props.quizSet.title;
        var sectionColor = DataController.getPrimaryColorForSection(
          this.props.quizSet.section,
        );
        typeLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '500', color: sectionColor}}>
            {this.props.quizSet.section}
          </DynamicText>
        );
      }

      if (this.props.quizSet.type === 'section') {
        quizSetName = this.props.quizSet.section;
        borderLeftWidth = 4;
        borderLeftColor = DataController.getPrimaryColorForSection(
          this.props.quizSet.section,
        );
        var sectionColor = DataController.getPrimaryColorForSection(
          this.props.quizSet.section,
        );
        typeLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '500', color: sectionColor}}>
            {this.props.quizSet.section}
          </DynamicText>
        );
      }

      if (this.props.quizSet.type === 'standby') {
        quizSetName = this.props.quizSet.title;
        typeLayout = (
          <Image
            style={{width: 85, height: 13}}
            source={require('../assets/Logo_Standby_Small.png')}
          />
        );
      }

      if (this.props.quizSet.type === 'caseStudy') {
        quizSetName = this.props.quizSet.title;
        typeLayout = (
          <DynamicText
            style={{
              fontSize: 12,
              fontWeight: '500',
              color: colors.SectDefault,
            }}>
            Case Study
          </DynamicText>
        );
      }

      if (this.props.quizSet.questions != null) {
        questionCount = this.props.quizSet.questions.length;
      }
    }

    if (this.props.quizzes != null) {
      quizzesCount = this.props.quizzes.length;
    }

    if (this.props.results != null) {
      for (
        var resultIndex = 0;
        resultIndex < this.props.results.length;
        resultIndex++
      ) {
        var result = this.props.results[resultIndex];

        if (result.correct) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      }
    }

    unansweredCount = questionCount - correctCount - incorrectCount;

    if (questionCount > 0) {
      correctPercentage = Math.floor((correctCount / questionCount) * 100);
    }

    var typeDotSeparatorLayout = null;

    if (typeLayout != null) {
      typeDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
    }

    var quizTimeLayout = null;

    if (this.props.quiz != null) {
      var dateString = moment(this.props.quiz.time).format('DD MMM YYYY');
      var timeString = moment(this.props.quiz.time).format('HH:mm');

      quizTimeLayout = (
        <DynamicText
          style={{
            marginTop: 4,
            fontSize: 16,
            letterSpacing: -0.41,
            color: colors.Grey900,
          }}>
          Taken on{' '}
          <DynamicText style={{fontWeight: '500'}}>{dateString}</DynamicText> at{' '}
          <DynamicText style={{fontWeight: '500'}}>{timeString}</DynamicText>
        </DynamicText>
      );
    }

    var statusLayout = null;
    var statusDotSeparatorLayout = null;

    if (
      this.props.quizSet != null &&
      this.props.quizSet.pgd === true &&
      this.props.user != null &&
      this.props.user.plusTrusts.length > 0
    ) {
      statusDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
      if (
        this.props.quizSet.pgdInfo != null &&
        this.props.quizSet.pgdInfo.acknowledged === true
      ) {
        statusLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '600', color: colors.Green500}}>
            Authorised
          </DynamicText>
        );
      } else {
        statusLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '600', color: colors.Red500}}>
            Unauthorised
          </DynamicText>
        );
      }
    }

    var infoLayout = (
      <View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {typeLayout}
          {/* TODO hidden ahead of changes coming in 2.4 */}
          {/* {typeDotSeparatorLayout}
          <DynamicText style={{fontSize: 12, color: colors.Grey700}}>
            {quizSetDate}
          </DynamicText> */}
          {statusDotSeparatorLayout}
          {statusLayout}
        </View>

        <DynamicText
          style={{
            marginTop: 4,
            fontSize: 19,
            fontWeight: '600',
            letterSpacing: -0.04,
            color: colors.Grey900,
          }}>
          {quizSetName}
        </DynamicText>
        {quizTimeLayout}
      </View>
    );

    var progressLayout = (
      <View>
        <View
          style={{marginTop: 7, flexDirection: 'row', alignItems: 'center'}}>
          <DynamicText
            style={{fontSize: 16, letterSpacing: -0.41, color: colors.Grey600}}>
            {correctCount}/{questionCount}
          </DynamicText>
          <ProgressView
            style={{flex: 1, marginLeft: 6, marginRight: 8, height: 4}}
            correctAnswers={correctCount}
            incorrectAnswers={incorrectCount}
            unansweredQuestions={unansweredCount}
          />
        </View>
      </View>
    );

    return (
      <View
        style={{
          paddingLeft: 10,
          paddingRight: 6,
          paddingVertical: 15,
          borderBottomWidth: 1,
          borderColor: colors.Grey100,
          borderLeftWidth: borderLeftWidth,
          borderLeftColor: borderLeftColor,
        }}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{flex: 1}}>
            {infoLayout}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 6,
                marginRight: 14,
              }}>
              <View
                style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text
                  style={{marginRight: 8, fontSize: 16, color: colors.Grey800}}>
                  Quizzes taken
                </Text>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 16,
                    fontWeight: '600',
                    color: colors.Grey900,
                  }}>
                  {quizzesCount}
                </Text>
              </View>

              <View
                style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text
                  style={{marginRight: 8, fontSize: 16, color: colors.Grey800}}>
                  Correct answers
                </Text>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 16,
                    fontWeight: '600',
                    color: colors.Green500,
                  }}>
                  {correctPercentage}%
                </Text>
              </View>
            </View>
          </View>

          <Image
            style={{width: 26, height: 26}}
            source={require('../assets/Button_Disclosure_Right_Blue.png')}
          />
        </View>
      </View>
    );
  }
}
