import React from 'react';

import {View, Text, Image, ImageBackground, StyleSheet} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

import {colors} from '../../classes/Colors';
import LayoutController from '../../classes/LayoutController';
import {containerWidths} from '../../constants/containerWidths';
import PSIRFHeader from './PSIRFHeader';

const moment = require('moment');

const TopSection = ({content}) => {
  const header = 'PSIRF LEARNING';
  const isTablet = LayoutController.isTablet();
  const image = content.TopImage[0].url;
  const imageSource = {uri: image};
  const styles = isTablet ? tabletStyles : mobileStyles;
  const dateIconSource = require('../../assets/PSIRF/PSIRF_alert_yellow.png');
  const date = content.DisplayDate
    ? `\u2022 ${moment(content.DisplayDate)
        .format('DD MMM YYYY')
        .toLocaleUpperCase()}`
    : '';
  return (
    <>
      <PSIRFHeader />
      <View style={[sharedStyles.background, isTablet && {paddingLeft: 16}]}>
        <View style={styles.container}>
          <ImageBackground source={imageSource} style={styles.image}>
            <LinearGradient
              colors={[
                'rgba(39, 39, 39, 0)',
                'rgba(39, 39, 39, 0.2)',
                'rgba(39, 39, 39, 0.6)',
                'rgba(39, 39, 39, 1)',
              ]}
              style={styles.image}
              start={isTablet ? {x: 1, y: 0} : {x: 0, y: 0}}
              end={isTablet ? {x: 0, y: 0} : {x: 0, y: 1}}>
              {isTablet && (
                <LinearGradient
                  colors={[
                    'rgba(39, 39, 39, 0)',
                    'rgba(39, 39, 39, 0.2)',
                    'rgba(39, 39, 39, 0.6)',
                    'rgba(39, 39, 39, 1)',
                  ]}
                  style={styles.image}
                  start={{x: 0, y: 0}}
                  end={{x: 0, y: 1}}
                />
              )}
            </LinearGradient>
          </ImageBackground>
          <View style={styles.textContainer}>
            <View style={sharedStyles.dateContainer}>
              <Image style={sharedStyles.icon} source={dateIconSource} />
              <Text style={sharedStyles.date}>{`${header}${date}`}</Text>
            </View>
            <Text style={sharedStyles.title}>{content.topSectionTitle}</Text>
            <Text style={sharedStyles.author}>{content.AuthorLine}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const sharedStyles = StyleSheet.create({
  icon: {
    width: 16,
    height: 14,
  },
  background: {
    backgroundColor: colors.PSIRFBackground,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'left',
    paddingBottom: 11,
  },
  dateContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 11,
  },
  date: {
    color: colors.PSIRFHeader,
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
    marginLeft: 5,
  },
  author: {
    color: colors.PSIRFAuthorTagline,
    marginTop: 2,
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    maxWidth: containerWidths.PSIRFMaxWidth,
    width: '100%',
  },
  textContainer: {
    padding: 16,
  },
  image: {
    width: '100%',
    height: 220,
    resizeMode: 'cover',
  },
});

const tabletStyles = StyleSheet.create({
  container: {
    flexDirection: 'row-reverse',
    maxWidth: containerWidths.PSIRFMaxWidth,
    alignSelf: 'center',
    width: '100%',
  },
  textContainer: {
    flex: 4,
    marginVertical: 'auto',
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // Fix single pixel issue with image on certain resolutions
    marginRight: -1,
  },
  image: {
    flex: 6,
    height: 240,
    resizeMode: 'cover',
  },
});

export default TopSection;
