/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component, PureComponent} from 'react';
import {StyleSheet, Text, View, ActivityIndicator} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

export default class LoadingBar extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.screenProps.isLoadingData === false) {
      return null;
    }

    return (
      <View
        style={[
          this.props.style,
          {
            flexDirection: 'row',
            backgroundColor: colors.white,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderColor: colors.Grey300,
          },
        ]}>
        <DynamicText>{this.props.screenProps.loadingBarText}</DynamicText>
        <ActivityIndicator style={{marginLeft: 10}}></ActivityIndicator>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
});
