/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
  Linking,
  StatusBar,
  Alert,
} from 'react-native';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import LayoutController from '../classes/LayoutController';
import PlatformController from '../classes/PlatformController';
import CommunicationsController from '../classes/CommunicationsController';

var _ = require('lodash');
var moment = require('moment');

type Props = {};

type State = {};

export default class SubscriptionScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: 'My Subscriptions',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: '500',
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
    }
  }

  logOut() {
    this.props.screenProps.logOut();
  }

  manageSubscription() {
    if (Platform.OS === 'ios') {
      PlatformController.openURL(
        'itms-apps://apps.apple.com/account/subscriptions',
      );
    } else if (Platform.OS === 'android') {
      PlatformController.openURL(
        'https://play.google.com/store/account/subscriptions?package=co.uk.classprofessional.cpdquiz',
      );
    } else {
      PlatformController.openURL(
        'http://www.parapass.co.uk/Account/MyAccountJRCALCCPD',
      );
    }
  }

  async ambulanceTrustContact() {
    // PlatformController.openURL('mailto:apps@class.co.uk?subject=ParaPass%20Ambulance%20Trust%20PGD%20Query');

    var subject = 'ParaPass PGD Query';
    var body = `Which Ambulance Trust are you with?



What is your Ambulance Trust email address?



Are you a Registered Paramedic? If so please provide your HCPC number:



Any further comments:


`;

    if (Platform.OS !== 'web') {
      // subject = encodeURIComponent(subject);
      // body = encodeURIComponent(body);
    } else {
      // body = body.replace(/<br>/g, '\n');
    }

    if (Platform.OS === 'android') {
      body = body.replace(/\n/g, '<br>');
    }

    // subject = encodeURI(subject);
    // body = encodeURI(body);

    try {
      CommunicationsController.sendEmail(
        ['apps@class.co.uk'],
        [],
        [],
        subject,
        body,
        [],
      );
    } catch (error) {
      console.log(error);
    }
  }

  async contactSupport() {
    this.props.screenProps.sendSupportEmail('ParaPass Support', 'support');
  }

  async requestAccountDeletion() {
    this.props.screenProps.showAccountDeletionAlert(true);
  }

  getProductPrices(products) {
    let twelveMonthPrice = 'Loading';
    let twelveMonthProductId = '';

    let oneMonthPrice = 'Loading';
    let oneMonthProductId = '';

    products.forEach(product => {
      let formattedPrice;

      // Check if the platform is Android and get the Android-specific pricing
      if (Platform.OS === 'android') {
        formattedPrice =
          product.subscriptionOfferDetails[0].pricingPhases.pricingPhaseList[0]
            .formattedPrice;
      } else {
        formattedPrice = product.localizedPrice;
      }

      if (
        product.productId === 'co.uk.class.cpd.12months' ||
        product.productId === 'co.uk.class.cpd.12months.android'
      ) {
        twelveMonthPrice = formattedPrice;
        twelveMonthProductId = product.productId;
      } else if (
        product.productId === 'co.uk.class.cpd.1month' ||
        product.productId === 'co.uk.class.cpd.1month.android'
      ) {
        oneMonthPrice = formattedPrice;
        oneMonthProductId = product.productId;
      }
    });

    return {
      twelveMonthPrice,
      twelveMonthProductId,
      oneMonthPrice,
      oneMonthProductId,
    };
  }

  render() {
    const {
      twelveMonthPrice,
      twelveMonthProductId,
      oneMonthPrice,
      oneMonthProductId,
    } = this.getProductPrices(this.props.screenProps.products);

    var currentSubscriptionLayout = null;

    if (this.props.screenProps.currentSubscription != null) {
      var currentSubscriptionLengthText = ' ';
      var subscriptionDeviceTypeText = ' ';

      if (this.props.screenProps.currentSubscription.SubscriptionID === 61) {
        currentSubscriptionLengthText = ' 1 month ';
      } else if (
        this.props.screenProps.currentSubscription.SubscriptionID === 66
      ) {
        currentSubscriptionLengthText = ' 12 month ';
      } else if (
        this.props.screenProps.currentSubscription.SubscriptionID === 90
      ) {
        currentSubscriptionLengthText = ' Group ';
      }

      if (this.props.screenProps.currentSubscription.CustomFieldList != null) {
        for (
          let subscriptionCustomFieldIndex = 0;
          subscriptionCustomFieldIndex <
          this.props.screenProps.currentSubscription.CustomFieldList.length;
          subscriptionCustomFieldIndex++
        ) {
          var subscriptionCustomField =
            this.props.screenProps.currentSubscription.CustomFieldList[
              subscriptionCustomFieldIndex
            ];

          if (subscriptionCustomField.Key === '3329_DeviceType') {
            subscriptionDeviceTypeText =
              ' ' + subscriptionCustomField.Value + '';
          }
        }
      }

      var expireText =
        'Your' +
        subscriptionDeviceTypeText +
        currentSubscriptionLengthText +
        'subscription will expire on';

      var dateString =
        this.props.screenProps.currentSubscription.ExpiryDate.replace(
          '/Date(',
          '',
        );
      dateString = dateString.replace(')/', '');
      var expiryDateSeconds = parseInt(dateString);
      var expiryDate = moment(expiryDateSeconds);
      var expiryDateString = expiryDate.format('Do MMMM YYYY');

      var expiryDateEndOfDay = expiryDate.endOf('day');
      var today = moment();

      if (expiryDateEndOfDay.isAfter(today) === false) {
        expireText =
          'Your' +
          subscriptionDeviceTypeText +
          currentSubscriptionLengthText +
          'subscription expired on';
      }

      currentSubscriptionLayout = (
        <View
          style={{
            marginTop: 10,
            marginBottom: 10,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          }}>
          <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
            <Text
              style={{fontSize: 21, fontWeight: '600', color: colors.Grey900}}>
              Subscription Information
            </Text>
            <Text style={{marginTop: 8, fontSize: 17, color: colors.Grey900}}>
              {expireText} {expiryDateString}
            </Text>
          </View>

          <TouchableOpacity onPress={this.manageSubscription.bind(this)}>
            <View
              style={{
                backgroundColor: colors.GreyA00,
                borderTopWidth: 1,
                borderColor: colors.Grey100,
              }}>
              <Text
                style={{
                  marginVertical: 15,
                  fontSize: 16,
                  fontWeight: '600',
                  letterSpacing: -0.39,
                  textAlign: 'center',
                  color: colors.ButtonBlue,
                }}>
                Manage my subscription
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    }

    var buySubscriptionLayout = null;

    var restoreExistingSubscriptionButtonLayout = (
      <TouchableOpacity
        onPress={this.props.screenProps.restoreSubscription.bind(this)}>
        <View
          style={{
            backgroundColor: colors.GreyA00,
            borderTopWidth: 1,
            borderColor: colors.Grey100,
          }}>
          <Text
            style={{
              marginVertical: 15,
              fontSize: 16,
              fontWeight: '600',
              letterSpacing: -0.39,
              textAlign: 'center',
              color: colors.ButtonBlue,
            }}>
            Restore existing subscription
          </Text>
        </View>
      </TouchableOpacity>
    );

    if (this.props.screenProps.currentlyRestoringSubscription) {
      restoreExistingSubscriptionButtonLayout = (
        <TouchableOpacity>
          <View
            style={{
              backgroundColor: colors.GreyA00,
              borderTopWidth: 1,
              borderColor: colors.Grey100,
            }}>
            <Text
              style={{
                marginVertical: 15,
                fontSize: 16,
                fontWeight: '600',
                letterSpacing: -0.39,
                textAlign: 'center',
                color: colors.Grey500,
              }}>
              Restore existing subscription
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      buySubscriptionLayout = (
        <View>
          <View
            style={{
              marginTop: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
              <Text
                style={{
                  fontSize: 21,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                Subscription options
              </Text>
              <Text style={{marginTop: 5, fontSize: 15, color: colors.Grey600}}>
                Choose between 1 and 12 months subscriptions. The subscriptons
                are auto renewing and can be cancelled at any time.
              </Text>
            </View>

            <View style={{height: 1, backgroundColor: colors.Grey200}} />

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 22,
                paddingHorizontal: 10,
              }}>
              <Text
                style={{
                  flex: 1,
                  fontSize: 17,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}
                numberOfLines={0}>
                1 Month Subscription
              </Text>

              <TouchableOpacity
                activeOpacity={0.7}
                onPress={this.props.screenProps.requestSubscription.bind(
                  this,
                  oneMonthProductId,
                )}>
                <View
                  style={{
                    marginLeft: 20,
                    width: 90,
                    paddingVertical: 8,
                    backgroundColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 2,
                    shadowOffset: {width: 0, height: 1},
                    shadowRadius: 1,
                    shadowColor: 'black',
                    shadowOpacity: 0.2,
                  }}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: '600',
                      letterSpacing: -0.39,
                      color: colors.white,
                    }}>
                    {oneMonthPrice}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{height: 1, backgroundColor: colors.Grey200}} />

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 22,
                paddingHorizontal: 10,
              }}>
              <Text
                style={{
                  flex: 1,
                  fontSize: 17,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}
                numberOfLines={0}>
                12 Months Subscription
              </Text>

              <TouchableOpacity
                activeOpacity={0.7}
                onPress={this.props.screenProps.requestSubscription.bind(
                  this,
                  twelveMonthProductId,
                )}>
                <View
                  style={{
                    marginLeft: 20,
                    width: 90,
                    paddingVertical: 8,
                    backgroundColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 2,
                    shadowOffset: {width: 0, height: 1},
                    shadowRadius: 1,
                    shadowColor: 'black',
                    shadowOpacity: 0.2,
                  }}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: '600',
                      letterSpacing: -0.39,
                      color: colors.white,
                    }}>
                    {twelveMonthPrice}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              marginTop: 10,
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
              <Text
                style={{
                  fontSize: 21,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                Restore existing subscription
              </Text>
              <Text style={{marginTop: 5, fontSize: 17, color: colors.Grey900}}>
                If you already have a subscription you can restore it to this
                device here.
              </Text>
            </View>

            {restoreExistingSubscriptionButtonLayout}
          </View>
        </View>
      );
    } else if (Platform.OS === 'web') {
      buySubscriptionLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() =>
            PlatformController.openURL('http://www.parapass.co.uk/Basket')
          }>
          <View
            style={{
              alignSelf: 'stretch',
              paddingVertical: 12,
              backgroundColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
            }}>
            <Text
              style={{
                fontSize: 17,
                fontWeight: '600',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Buy Subscription
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    var notSubscribedLayout = null;

    if (this.props.presentedModally === true) {
      var emailAddressText = '';
      if (
        this.props.screenProps.user !== null &&
        this.props.screenProps.user.email != null &&
        this.props.screenProps.user.email !== ''
      ) {
        emailAddressText = this.props.screenProps.user.email;
      }

      var notSubscribedLayout = (
        <View>
          <View
            style={{
              marginTop: 10,
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
              <Text
                style={{
                  fontSize: 21,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                Account
              </Text>
              <Text style={styles.regularTextStyle}>{emailAddressText}</Text>
            </View>
          </View>

          <View
            style={{
              marginTop: 10,
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
              <Text
                style={{
                  fontSize: 21,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                ParaPass PGDs
              </Text>
              <Text style={styles.regularTextStyle}>
                In order to access PGD assessments on the ParaPass app, you need
                to log in as an Ambulance Trust user with your Ambulance Trust
                email address. You also need to be listed in the correct staff
                group within our system to access PGD assessments. You can check
                your staff group on the JRCALC Plus app by clicking on the
                profile icon in the top left hand corner and viewing
                Subscriptions. Your staff group will be listed there. If no
                staff group is listed, or you think your staff group needs
                amending, please send{' '}
                <Text
                  style={{color: colors.ButtonBlue}}
                  onPress={this.ambulanceTrustContact.bind(this)}>
                  apps@class.co.uk
                </Text>{' '}
                an email with these details:
              </Text>

              <Text style={styles.regularTextStyle}>
                Which Ambulance Trust are you with?
              </Text>
              <Text style={styles.regularTextStyle}>
                What is your Ambulance Trust email address?
              </Text>
              <Text style={styles.regularTextStyle}>
                Are you a Registered Paramedic? If so please provide your HCPC
                number:
              </Text>
              <Text style={styles.regularTextStyle}>Any further comments:</Text>
            </View>
          </View>
        </View>
      );
    }

    var topPadding = 0;

    if (
      this.props.presentedModally === true &&
      PlatformController.deviceHasTopSafeArea()
    ) {
      topPadding = 44;
    }

    var statusBarLayout = null;

    if (this.props.presentedModally === true) {
      statusBarLayout = <StatusBar barStyle="dark-content" />;
    }

    return (
      <View style={styles.container}>
        {statusBarLayout}

        <ScrollView
          style={{flex: 1}}
          scrollIndicatorInsets={{right: 1}}
          contentContainerStyle={{
            alignItems: 'stretch',
            marginTop: 10 + topPadding,
            marginBottom: 10,
            marginHorizontal: 10,
          }}>
          {currentSubscriptionLayout}

          {notSubscribedLayout}

          {buySubscriptionLayout}

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 21,
                  letterSpacing: -0.5,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                Support
              </DynamicText>
              <DynamicText
                style={{
                  fontSize: 17,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                If you need any help with or have any questions about your
                subscription, please get in touch with us through the button
                below.
              </DynamicText>
            </View>

            <TouchableOpacity onPress={this.contactSupport.bind(this)}>
              <View
                style={{
                  backgroundColor: colors.GreyA00,
                  borderTopWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '600',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.ButtonBlue,
                  }}>
                  Contact support
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>

          <View
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <View style={{paddingVertical: 15, paddingHorizontal: 10}}>
              <DynamicText
                style={{
                  marginBottom: 5,
                  fontSize: 21,
                  letterSpacing: -0.5,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                Request account deletion
              </DynamicText>
              <DynamicText
                style={{
                  marginBottom: 16,
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                If you wish to delete your account, do so through the button
                below. You will be asked to confirm your request.
              </DynamicText>
              <DynamicText
                style={{
                  marginBottom: 16,
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                Please note that the account deletion is not immediate and may
                take a few days. We will inform you via email once your account
                has been deleted.
              </DynamicText>
              <DynamicText
                style={{
                  fontFamily: 'InterUI-Regular',
                  fontSize: 16,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                }}>
                Deleting your account will not cancel your subscription. You
                will need to manage your subscription to cancel.
              </DynamicText>
            </View>

            <TouchableOpacity onPress={this.requestAccountDeletion.bind(this)}>
              <View
                style={{
                  backgroundColor: colors.GreyA00,
                  borderTopWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '600',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.Red500,
                  }}>
                  Request account deletion
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              PlatformController.openURL(
                'https://www.classprofessional.co.uk/terms-of-use/parapass-terms-and-conditions/',
              );
            }}>
            <Text
              style={{
                fontSize: 14,
                color: '#1470CB',
                marginTop: 24,
                marginBottom: 6,
                textAlign: 'center',
              }}>
              Terms and Privacy Policy
            </Text>
          </TouchableOpacity>

          <View
            style={{
              marginTop: 20,
              marginBottom: 80,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <TouchableOpacity onPress={this.logOut.bind(this)}>
              <View style={{backgroundColor: colors.GreyA00}}>
                <DynamicText
                  style={{
                    marginVertical: 15,
                    fontSize: 16,
                    fontWeight: '500',
                    letterSpacing: -0.39,
                    textAlign: 'center',
                    color: colors.Red500,
                  }}>
                  Log out
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: colors.Grey100,
  },
  regularTextStyle: {
    marginTop: 8,
    fontSize: 17,
    color: colors.Grey900,
  },
});
