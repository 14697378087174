import React, {Component} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  Platform,
  ScrollView,
} from 'react-native';
const Panzoom = require('@panzoom/panzoom');
type State = {
  zoomPercentage: number,
};

export default class AMImageViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoomPercentage: 400,
    };
  }

  zoomIn() {
    if (this.state.zoomPercentage < 2000) {
      this.setState({zoomPercentage: this.state.zoomPercentage + 50});
    }
  }

  zoomOut() {
    if (this.state.zoomPercentage > 110) {
      this.setState({zoomPercentage: this.state.zoomPercentage - 50});
    }
  }

  close() {
    this.props.onClose();
    this.setState({zoomPercentage: 400});
  }

  render() {
    if (this.props.visible !== true) {
      return <View />;
    }

    var imageUrl = '';

    if (this.props.imageUrls != null && this.props.imageUrls.length > 0) {
      imageUrl = this.props.imageUrls[0].url;
    }

    // var imageHTML = `
    // <html>
    //   <head>
    //     <meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>
    //     <style>
    //     body {
    //       margin: 0px;
    //       padding: 0px;
    //     }
    //       .video-container {
    //         width: 100%;
    //         margin-top: 4px;
    //         position: relative;
    //         padding-bottom: 56.25%; /* 16:9 */
    //       }
    //       .video-frame {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //       }
    //     </style>
    //     <script id='panZoomLoadScript' src="https://www.unpkg.com/@panzoom/panzoom/dist/panzoom.js"></script>
    //     <script id='panZoomScript'>
    //       window.addEventListener('load', loadPanZoom)

    //       function loadPanZoom() {
    //         const element = document.getElementById('panzoom')
    //         const panzoom = Panzoom(element, {
    //               // options here
    //         });

    //         // enable mouse wheel
    //         const parent = element.parentElement
    //         parent.addEventListener('wheel', panzoom.zoomWithWheel);
    //       }

    //     </script>
    //   </head>
    //   <body>
    //     <div id="panzoom">
    //       <img src="` + imageUrl + `" width="900" height="900">
    //     </div>
    //   </body>
    // </html>
    // `;

    // if (document.getElementById('panZoomScript') != null) {
    //   const scriptTag = document.getElementById('panZoomScript').innerHTML;
    //   window.eval(scriptTag);
    // }

    var imageHTML =
      `
    <html>
      <head>
        <meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>
        <style>
          body {
            margin: 0px;
            padding: 0px;
          }
        </style>
      </head>
      <body>
        <div style="width: ` +
      this.state.zoomPercentage +
      `%; height: ` +
      this.state.zoomPercentage +
      `%"> 
          <img src="` +
      imageUrl +
      `" width="100%"> 
        </div>
      </body>
    </html>
    `;

    if (document.getElementById('panZoomScript') != null) {
      const scriptTag = document.getElementById('panZoomScript').innerHTML;
      window.eval(scriptTag);
    }

    return (
      <View
        ref={ref => (this.imageZoomModal = ref)}
        style={{
          position: 'absolute',
          left: 0,
          top: -50,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#374044',
        }}>
        <View style={{flex: 1, alignSelf: 'stretch'}}>
          <ScrollView
            style={{flex: 1, alignSelf: 'stretch', overflow: 'scroll'}}
            contentContainerStyle={{
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image
              style={{
                width: this.state.zoomPercentage,
                height: this.state.zoomPercentage,
              }}
              source={{uri: imageUrl}}
              resizeMode="contain"
            />
          </ScrollView>
        </View>

        {/* <Image style={{height: this.state.zoomPercentage + '%', width: this.state.zoomPercentage + '%'}} resizeMode='contain' source={{uri: imageUrl}}/> */}
        <TouchableOpacity
          style={{
            position: 'absolute',
            top: 30,
            right: 10,
            width: 32,
            height: 32,
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: 100 / 2,
            justifyContent: 'center',
          }}
          hitSlop={{
            top: 8,
            bottom: 8,
            left: 8,
            right: 8,
          }}
          onPress={this.close.bind(this)}>
          <Image
            style={{
              height: 30,
              width: 30,
              alignSelf: 'center',
            }}
            source={require('../assets/Button_Close.png')}
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            position: 'absolute',
            top: 82,
            right: 10,
            width: 32,
            height: 32,
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: 100 / 2,
            justifyContent: 'center',
          }}
          hitSlop={{
            top: 8,
            bottom: 8,
            left: 8,
            right: 8,
          }}
          onPress={this.zoomIn.bind(this)}>
          <Text
            style={{
              alignSelf: 'center',
              paddingBottom: 7,
              fontSize: 30,
              color: '#FFF',
            }}>
            +
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            position: 'absolute',
            top: 122,
            right: 10,
            width: 32,
            height: 32,
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: 100 / 2,
            justifyContent: 'center',
          }}
          hitSlop={{
            top: 8,
            bottom: 8,
            left: 8,
            right: 8,
          }}
          onPress={this.zoomOut.bind(this)}>
          <Text
            style={{
              alignSelf: 'center',
              paddingBottom: 7,
              fontSize: 30,
              color: '#FFF',
            }}>
            -
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
