/**
 * PPP
 * https://github.com/facebook/react-native
 * @flow
 */
'use strict';

import {Platform, Dimensions} from 'react-native';

export default {
  openURL(url: string) {
    try {
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  },

  getTopInset(): number {
    return 0;
  },

  getBottomInset(): number {
    return 0;
  },

  getLeftInset(): number {
    return 0;
  },

  getRightInset(): number {
    return 0;
  },

  deviceHasTopSafeArea(): boolean {
    return false;
  },

  deviceHasBottomSafeArea(): boolean {
    return false;
  },

  isMobileWeb(): boolean {
    try {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return true;
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};
