import PlatformController from '../classes/PlatformController';

/**
 * Add a reflection to Parafolio
 *
 * @param {string} activityDescription
 * @param {string} timelineReference
 * @param {string}} title
 * @param {Date} time
 * @param {string} reflection
 */
export const addToParafolio = (
  activityDescription = '',
  timelineReference = '',
  title = '',
  time = 0,
  reflection = '',
) => {
  try {
    const timestamp = new Date(time);
    const activityDate = timestamp.getTime();
    const encodedReflection = encodeURIComponent(reflection);
    const baseUrl = 'https://parafolio.co.uk/?addreflection=true';
    // const baseUrl =
    //   'https://parafolio-feature-testing.netlify.app/?addreflection=true';
    const url = `${baseUrl}&title=${title}&activitydate=${activityDate}&activitydescription=${activityDescription}&timelinereference=${timelineReference}&activitytype=Work-based learning&reflection=${encodedReflection}`;
    PlatformController.openURL(url);
  } catch (error) {
    console.log(error);
  }
};
