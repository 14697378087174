'use strict';

import React, {Component, PureComponent} from 'react';
import {View, Text, Platform, Image} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

type Props = {
  name: string,
  seeMoreButton: boolean,
  collapsible: boolean,
  collapsed: boolean,
  collapseButtonTapped: () => void,
  seeMoreButtonTapped: () => void,
  padContents: boolean,
};

export default class ContentCard extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  collapseButtonTapped() {
    if (this.props.collapseButtonTapped) {
      this.props.collapseButtonTapped();
    }
  }

  seeMoreButtonTapped() {
    if (this.props.seeMoreButtonTapped) {
      this.props.seeMoreButtonTapped();
    }
  }

  render() {
    let collapsedButtonIcon = require('../assets/Button_Disclosure_Up.png');
    if (this.props.collapsed) {
      collapsedButtonIcon = require('../assets/Button_Disclosure_Blue_Down.png');
    }

    let padContents = true;
    if (this.props.padContents === false) {
      padContents = false;
    }

    return (
      <View
        style={[
          {
            marginBottom: 16,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          },
          this.props.style,
        ]}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <DynamicText
            numberOfLines={0}
            style={{
              flexShrink: 1,
              paddingLeft: 12,
              marginVertical: 16,
              paddingRight: 4,
              fontSize: 17,
              fontWeight: '800',
              color: colors.Grey900,
            }}>
            {this.props.name}
          </DynamicText>
          <View style={{flexDirection: 'row'}}>
            {this.props.seeMoreButton ? (
              <TouchableOpacity
                onPress={this.seeMoreButtonTapped.bind(this)}
                style={{
                  alignSelf: 'flex-start',
                  marginTop: 13,
                  paddingHorizontal: 9,
                  paddingVertical: 4,
                  backgroundColor: colors.Grey200,
                  borderRadius: 14,
                }}>
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: '600',
                    color: colors.ButtonBlue,
                  }}>
                  See more
                </Text>
              </TouchableOpacity>
            ) : null}
            {this.props.collapsible ? (
              <TouchableOpacity onPress={this.collapseButtonTapped.bind(this)}>
                <Image
                  style={{marginTop: 3, width: 44, height: 44}}
                  source={collapsedButtonIcon}
                />
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
        <View
          style={{
            maxHeight: this.props.collapsed ? 0 : 10000,
            overflow: 'hidden',
          }}>
          <View
            style={{
              paddingHorizontal: padContents ? 12 : 0,
              paddingBottom: padContents ? 16 : 0,
            }}>
            {this.props.children}
          </View>
        </View>
      </View>
    );
  }
}
