/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {StyleSheet, Text, View, Image, TouchableOpacity} from 'react-native';

import Select from 'react-select';

import {colors} from '../classes/Colors';
import styles from '../css/DropDown.css';

export default class DropDown extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(selectedOption) {
    this.props.optionChanged(selectedOption);
  }

  render() {
    var options = this.props.options.slice();

    const styles = {
      control: base => ({
        ...base,
        fontFamily: 'Sans-serif',
      }),
      option: base => ({
        ...base,
        fontFamily: 'Sans-serif',
      }),
    };

    return (
      <View style={[this.props.style, {}]}>
        <Select
          value={{
            value: this.props.selectedOption.value,
            label: this.props.labelText,
          }}
          onChange={this.handleChange.bind(this)}
          styles={styles}
          options={options}
          components={{
            DropdownIndicator: () => (
              <Image
                style={{marginRight: 10, width: 14, height: 9}}
                source={require('../assets/Button_SmallDisclosure_Down.png')}
              />
            ),
            IndicatorSeparator: () => null,
          }}
        />
      </View>
    );
  }
}
