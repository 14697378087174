import React from 'react';

import {View, StyleSheet, FlatList} from 'react-native';

import {colors} from '../classes/Colors';
import PSIRFHeader from '../components/PSIRF/PSIRFHeader';
import PSIRFNavCard from '../components/PSIRF/PSIRFNavCard';
import {psirfAnalyticsEventNames} from '../constants/psirf';
import {logAnalyticsEvent} from '../utils/firebase';

const PSIRFDashboardScreen = ({navigation, ...rest}) => {
  const data = navigation.getParam('data');
  const quizOnPressHandler = navigation.getParam('quizOnPressHandler');
  const fallbackImage = require('../assets/Image_Loading_Placeholder_Large.png');

  const renderItem = (item, index, id) => {
    const isHero = index === 0;
    const topSection = item.content.find(
      section => section.type === 'TopSection',
    );
    const image = topSection?.content.TopImage[0].url;
    const date = topSection?.content.DisplayDate;
    const navigationHandler = () =>
      navigation.navigate(
        'PSIRF',
        {data: item, quizOnPressHandler},
        {navigation, ...rest},
      );

    const onPressHandler = () => {
      logAnalyticsEvent(psirfAnalyticsEventNames.started, {
        from: psirfAnalyticsEventNames.dashboard,
        psirf_id: id,
      });
      navigationHandler();
    };
    return (
      <PSIRFNavCard
        id={id}
        image={image}
        fallbackImage={fallbackImage}
        title={item.Name}
        subtitle={item.DisplayTitle}
        onPress={onPressHandler}
        trust={item.Trust}
        isHero={isHero}
        displayDate={date}
      />
    );
  };

  return (
    <View style={styles.screen}>
      <PSIRFHeader />
      <View style={styles.container}>
        <FlatList
          data={data}
          renderItem={({item, index}) => renderItem(item, index, item.ID)}
          keyExtractor={item => item.ID}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{padding: 16}}
        />
      </View>
    </View>
  );
};

// Use static navigationOptions to set the header options in React Navigation v4
PSIRFDashboardScreen.navigationOptions = ({navigation}) => {
  return {
    title: '',
    headerStyle: navigation.getParam('headerStyle', {
      backgroundColor: colors.NavBar,
    }),
    headerTintColor: navigation.getParam('headerTintColor', colors.white),
    headerTitleStyle: navigation.getParam('headerTitleStyle', {
      fontFamily: 'InterUI-Regular',
      fontSize: 17,
      fontWeight: '600',
      letterSpacing: -0.41,
      color: colors.white,
    }),
  };
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: '#F1F3F5',
  },
  container: {
    flex: 1,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
  },
});

export default PSIRFDashboardScreen;
