/**
 * @format
 */

import {AppRegistry, Platform} from 'react-native';
import {registerRootComponent} from 'expo';
import App from './src/App';
import {name as appName} from './app.json';

AppRegistry.registerComponent(appName, () => App);
registerRootComponent(App);
