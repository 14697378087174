/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component, PureComponent} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {colors} from '../classes/Colors';

export default class ProgressView extends PureComponent {
  constructor(props) {
    super(props);
  }

  correctAnswerStyle() {
    var correctPercentage =
      this.props.correctAnswers /
      (this.props.correctAnswers +
        this.props.incorrectAnswers +
        this.props.unansweredQuestions);

    if (isNaN(correctPercentage) || isFinite(correctPercentage) === false) {
      correctPercentage = 0;
    }

    var backgroundColor = colors.Green500;

    if (this.props.correctBackgroundColor != null) {
      backgroundColor = this.props.correctBackgroundColor;
    }

    return {
      flex: correctPercentage,
      backgroundColor: backgroundColor,
    };
  }

  incorrectAnswerStyle() {
    var incorrectPercentage =
      this.props.incorrectAnswers /
      (this.props.correctAnswers +
        this.props.incorrectAnswers +
        this.props.unansweredQuestions);

    if (isNaN(incorrectPercentage) || isFinite(incorrectPercentage) === false) {
      incorrectPercentage = 0;
    }

    var backgroundColor = colors.Red500;

    if (this.props.incorrectBackgroundColor != null) {
      backgroundColor = this.props.incorrectBackgroundColor;
    }

    return {
      flex: incorrectPercentage,
      backgroundColor: backgroundColor,
    };
  }

  unansweredStyle() {
    var unansweredPercentage =
      this.props.unansweredQuestions /
      (this.props.correctAnswers +
        this.props.incorrectAnswers +
        this.props.unansweredQuestions);

    if (
      isNaN(unansweredPercentage) ||
      isFinite(unansweredPercentage) === false
    ) {
      unansweredPercentage = 0;
    }

    var backgroundColor = colors.Grey200;

    if (this.props.unansweredBackgroundColor != null) {
      backgroundColor = this.props.unansweredBackgroundColor;
    }

    return {
      flex: unansweredPercentage,
      backgroundColor: backgroundColor,
    };
  }

  render() {
    return (
      <View style={this.props.style}>
        <View style={styles.progressContainer}>
          <View style={this.correctAnswerStyle()}></View>
          <View style={this.incorrectAnswerStyle()}></View>
          <View style={this.unansweredStyle()}></View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  progressContainer: {
    flex: 1,
    flexDirection: 'row',
  },
});
