export const getIconSource = icon => {
  switch (icon) {
    case 'Case Study':
      return require('../assets/Icon_Case_studies_Opacity70.png');
    case 'Standby':
      return require('../assets/Icon_Standby_Opacity70.png');
    case 'Quiz':
      return require('../assets/Icon_Quizzes_Opacity70.png');
    case 'PGD':
      return require('../assets/Icon_PGD_Opacity70.png');
    case 'Book':
      return require('../assets/Icon_Books_Opacity70.png');
    case 'Serious Incident':
      return require('../assets/Icon_Serious_Incidents_Opacity70.png');
    case 'Promotion':
      return require('../assets/promo/gift_blue.png');
    default:
      return null;
  }
};
