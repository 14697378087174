import React, {useRef} from 'react';

import {ScrollView, View, StyleSheet} from 'react-native';

import TopSection from './TopSection';
import Summary from './Summary';
import Learning from './Learning';
import QuickWatch from './QuickWatch';
import KeyPoints from './KeyPoints';
import ReflectiveQuestions from './ReflectiveQuestions';
import FurtherReading from './FurtherReading';
import Quiz from './Quiz';
import UsefulLinks from './UsefulLinks';
import Acknowledgment from './Acknowledgment';
import UpTicks from './UpTicks';

const getComponent = (
  index,
  section,
  screenProps,
  psirfMetaData,
  quizOnPressHandler,
) => {
  const quizHandler = () => {
    const quizSet = screenProps.quizSets.find(
      quizSet => quizSet.name === section.content.QuizLink,
    );
    if (!quizSet) {
      screenProps.showAlert(
        'Quiz Unavailable',
        `The quiz: ${section.content.QuizLink}, can't be displayed at the moment. It might still be downloading. Please try again shortly.`,
      );
      return;
    }
    quizOnPressHandler(quizSet, 'PSIRF');
  };

  switch (section.type) {
    case 'TopSection':
      return (
        <TopSection
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'Summary':
      return (
        <Summary
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'Learning':
      return (
        <Learning
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'QuickWatch':
      return (
        <QuickWatch
          key={index}
          content={section.content}
          updateVideoHistory={screenProps.updateVideoHistory}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'KeyPoints':
      return (
        <KeyPoints
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'ReflectiveQuestions':
      return (
        <ReflectiveQuestions
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'FurtherReading':
      return (
        <FurtherReading
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'Quiz':
      return (
        <Quiz
          key={index}
          content={section.content}
          onPress={quizHandler}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'UsefulLinks':
      return (
        <UsefulLinks
          key={index}
          content={section.content}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'Acknowledgment':
      return (
        <Acknowledgment
          key={index}
          user={screenProps.user}
          title={psirfMetaData.title}
          psirfMetaData={psirfMetaData}
        />
      );
    case 'upTicks':
      return null;
    // TODO: Need to implement this component
    // return <UpTicks key={index} content={section.content} />;
    default:
      return null;
  }
};

const PSIRFRenderer = ({data, screenProps, quizOnPressHandler}) => {
  const topSection = data.content.find(
    section => section.type === 'TopSection',
  );
  const title = topSection.content.topSectionTitle;
  const date = topSection.content.DisplayDate;
  const scrollViewRef = useRef(null);
  const components = data.content.map((item, index) =>
    getComponent(
      index,
      item,
      screenProps,
      {title, date, psirfId: data.ID},
      quizOnPressHandler,
    ),
  );
  return (
    <ScrollView ref={scrollViewRef}>
      <View style={styles.container}>{components}</View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    marginHorizontal: 'auto',
  },
});

export default PSIRFRenderer;
