/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  Linking,
  Alert,
  Modal,
  Dimensions,
  // WebView,
} from 'react-native';

import AMWebView from '../components/AMWebView';

import ScreenComponent from '../components/ScreenComponent';
import ProgressView from '../components/ProgressView';
import DataController from '../classes/DataController';
import LayoutController from '../classes/LayoutController';
import DynamicText from '../components/DynamicText';
import AMImageViewer from '../components/AMImageViewer';

import PlatformController from '../classes/PlatformController';
import GuidelinesController from '../classes/GuidelinesController';
import {colors} from '../classes/Colors';
import RNFS from '../classes/RNFS';

import {firebase} from '../classes/Firebase';

var _ = require('lodash');
var moment = require('moment');

type Props = {};

type State = {
  quizSet: [],
  issue: Object,
  imageURL: string,
  webviewHeight: number,
  imageViewerVisible: boolean,
  imageModalPath: string,
  issueHtml: string,
};

export default class StandbyVolumeScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('takeQuizButtonTapped')}>
          <DynamicText
            style={{color: colors.white, marginRight: 10, fontSize: 17}}>
            Take Quiz
          </DynamicText>
        </TouchableOpacity>
      </View>
    );

    return {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        quizSet: this.props.navigation.getParam('quizSet', {}),
        issue: this.props.navigation.getParam('issue', {}),
        imageURL: '',
        webviewHeight: 1000,
        imageViewerVisible: false,
        imageModalPath: '',
      };

      if (this.state.issue.html != null) {
        this.state.issueHtml = this.state.issue.html;
      } else {
        this.state.issueHtml = '';
      }
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.state.issue.CoverImage != null) {
        var imageURL = await DataController.getStandbyIssueImagePath(
          this.state.issue,
          this.state.issue.CoverImage[0].filename,
        );
        this.setState({imageURL: imageURL});
      }

      if (Platform.OS === 'ios') {
        // get image urls as base64 - as can't load images regularly on iOS for some reason
        var imageMatches = this.state.issue.html.match(
          /src\s*=\s*"images\/(.+?)"/g,
        );

        var issueHtml = this.state.issueHtml;

        if (imageMatches) {
          for (
            let imageMatchIndex = 0;
            imageMatchIndex < imageMatches.length;
            imageMatchIndex++
          ) {
            var imageMatch = imageMatches[imageMatchIndex];

            var imageFileName = imageMatch
              .replace('src="images/', '')
              .replace('"', '');

            var imagePath = await DataController.getStandbyIssueImagePath(
              this.state.issue,
              imageFileName,
            );

            var base64String =
              await DataController.getBase64ImageStringForImagePath(imagePath);

            var imageType = 'jpeg';

            if (imageFileName.includes('png')) {
              imageType = 'png';
            }

            issueHtml = issueHtml.replace(
              imageMatch,
              'id="' +
                imagePath +
                '" src="data:image/' +
                imageType +
                ';base64, ' +
                base64String +
                '"',
            );

            var test = '';
          }
        }

        this.setState({issueHtml: issueHtml});

        try {
          firebase.analytics().logEvent('standby_issue_viewed', {
            standbyIssueViewed: this.state.issue.Name,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        takeQuizButtonTapped: that.takeQuizButtonTapped.bind(that, 'navBar'),
      });
    }, 100);
  }

  takeQuizButtonTapped(from: string) {
    this.takeQuiz('All Questions');

    try {
      firebase.analytics().logEvent('standby_quiz_started', {
        standbyIssueViewed: this.state.issue.Name,
        from: from,
      });
    } catch (error) {
      console.log(error);
    }
  }

  takeQuiz(quizMode: string) {
    var that = this;

    // check if there's an inprogress quiz already underway and ask user what they want to do.
    var inProgressQuiz = null;

    for (
      let quizIndex = 0;
      quizIndex < this.props.screenProps.inProgressQuizzes.length;
      quizIndex++
    ) {
      var anInProgressQuiz =
        this.props.screenProps.inProgressQuizzes[quizIndex];

      if (anInProgressQuiz.quizSet === this.state.quizSet.name) {
        inProgressQuiz = anInProgressQuiz;
        break;
      }
    }

    if (inProgressQuiz && Platform.OS !== 'web') {
      Alert.alert(
        'You already have a quiz in progress for this Standby CPD',
        'Would you like to continue where you left off or start a new quiz',
        [
          {
            text: 'Start new quiz',
            onPress: () => {
              that.startQuiz(quizMode, null);
            },
          },
          {
            text: 'Continue',
            onPress: () => {
              that.startQuiz(quizMode, inProgressQuiz);
            },
          },
        ],
        {cancelable: false},
      );
    } else {
      this.startQuiz(quizMode, null);
    }
  }

  startQuiz(quizMode: string, inProgressQuiz: Object) {
    var questionsForQuizSet = this.state.quizSet.questions.slice();

    if (questionsForQuizSet.length > 0) {
      questionsForQuizSet = _.sortBy(questionsForQuizSet, ['QuestionID']);

      if (inProgressQuiz) {
        var results =
          this.props.screenProps.resultsForQuizzes[inProgressQuiz.name];

        var questionsForQuiz = [];
        var currentQuestion = null;
        var currentQuestionNumber = 1;
        var questionNumbersAnswered = [];
        var questionNumbersViewed = [];
        var correctCount = 0;
        var incorrectCount = 0;

        for (
          var quizQuestionIndex = 0;
          quizQuestionIndex < inProgressQuiz.questions.length;
          quizQuestionIndex++
        ) {
          var quizQuestionName = inProgressQuiz.questions[quizQuestionIndex];
          var questionForQuiz = null;

          // add full question to quiz
          for (
            var questionIndex = 0,
              questionsLength = this.props.screenProps.questions.length;
            questionIndex < questionsLength;
            questionIndex++
          ) {
            var question = this.props.screenProps.questions[questionIndex];

            if (question.Name === quizQuestionName) {
              questionsForQuiz.push(question);
              questionForQuiz = question;
            }
          }

          var resultFound = false;

          for (
            let resultIndex = 0;
            resultIndex < results.length;
            resultIndex++
          ) {
            var result = results[resultIndex];
            var resultQuestionName = result.packName + '-' + result.questionID;

            if (
              result.quizName === inProgressQuiz.name &&
              resultQuestionName === quizQuestionName
            ) {
              resultFound = true;

              if (result.correct) {
                correctCount++;
              } else {
                incorrectCount++;
              }

              questionNumbersAnswered.push(quizQuestionIndex + 1);
              questionNumbersViewed.push(quizQuestionIndex + 1);
            }
          }

          if (resultFound === false && currentQuestion == null) {
            currentQuestionNumber = quizQuestionIndex + 1;
            currentQuestion = questionForQuiz;
          }
        }

        this.props.navigation.navigate(
          'Quiz',
          {
            quiz: inProgressQuiz,
            quizSet: this.state.quizSet,
            questions: questionsForQuiz,
            currentQuestion: currentQuestion,
            currentQuestionNumber: currentQuestionNumber,
            questionNumbersAnswered: questionNumbersAnswered,
            questionNumbersViewed: questionNumbersViewed,
            correctCount: correctCount,
            incorrectCount: incorrectCount,
            comingFromOverviewScreen: false,
            from: 'Standby CPD',
          },
          this.state,
        );
      } else {
        var questionsForQuiz = questionsForQuizSet;

        var now = new Date();
        var nowTimestamp = now.getTime();

        var questionIDsForQuiz = questionsForQuiz.map(function (question) {
          return question.PackName + '-' + question.QuestionID;
        });

        var quiz = {
          name: this.state.quizSet.name + '-' + nowTimestamp,
          time: nowTimestamp,
          quizSet: this.state.quizSet.name,
          quizMode: quizMode,
          questions: questionIDsForQuiz,
        };

        this.props.navigation.navigate(
          'Quiz',
          {
            quiz: quiz,
            quizSet: this.state.quizSet,
            questions: questionsForQuiz,
            from: 'Standby CPD',
          },
          this.state,
        );
      }
    } else {
      // no questions so don't start quiz
    }
  }

  async goToReference(glid) {
    var referenceContent = await GuidelinesController.getGuidelineJSONForGLID(
      glid,
    );

    if (referenceContent != null && referenceContent !== 'Old Guideline') {
      this.props.navigation.navigate(
        'Reference',
        {
          guidelineContents: referenceContent,
        },
        this.state,
      );
    } else {
      let guidelineName =
        await GuidelinesController.getGuidelineNameForGuideline(glid);
      if (guidelineName != null && guidelineName !== '') {
        // guideline is in list so it should be downloaded - but might not have finished yet
        Alert.alert(
          "The reference can't be displayed at the moment. It might still be downloading. Please try again shortly.",
        );
      }
    }
  }

  onNavigationChange(event) {
    if (event.title) {
      if (event.title === 'about:blank') {
        return;
      }

      var htmlHeight = Number(event.title); //convert to number
      htmlHeight = htmlHeight;

      // if (this.state.currentQuestionNumber === 1) {
      //   htmlHeight = htmlHeight + 80;
      // }

      if (Platform.isPad) {
        htmlHeight = htmlHeight - 50;
      }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    }
  }

  onMessage(m) {
    var message = m.nativeEvent.data;
    if (message.includes('scrollHeight')) {
      var htmlHeight = Number(message.split('-')[1]);

      if (Platform.isPad) {
        htmlHeight = htmlHeight - 50;
      }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    } else if (message.includes('imageTapped')) {
      var imageString = message;

      if (Platform.OS === 'ios') {
        imageString = imageString.replace('imageTapped-', '');
        imageString = imageString.substr(0, imageString.indexOf('|'));
      } else {
        var imageString = imageString.replace(/imageTapped%257C/g, '');
        imageString = imageString.replace(/imageTapped-/g, '');
        imageString = imageString.replace('|', '');
        var imageString = imageString.replace(/%253A/g, ':');
        var imageString = imageString.replace(/%252F/g, '/');
        var imageString = decodeURI(imageString);

        console.log(imageString);
      }

      this.openImageModal(imageString);
    }
  }

  openImageModal(imagePath) {
    console.log('Image was tapped');
    this.setState({imageViewerVisible: true, imageModalPath: imagePath});
  }

  closeImageModal() {
    this.setState({imageViewerVisible: false});
  }

  render() {
    var headerImageLayout = null;
    var alignTitle = 'left';

    var quizSetDate = moment(this.state.issue.PublicDate).format('DD MMM YYYY');

    var imageSource = {uri: this.state.imageURL};
    var defaultSource = require('../assets/Image_Loading_Placeholder_Large.png');
    if (this.state.imageURL == null) {
      defaultSource = require('../assets/Image_No_Image_Placeholder_Large.png');
      imageSource = defaultSource;
    }

    if (LayoutController.isCompact()) {
      headerImageLayout = (
        <View style={{}}>
          <Image
            style={{maxWidth: 664, height: 200}}
            source={imageSource}
            defaultSource={defaultSource}
          />
        </View>
      );
    } else {
      alignTitle = 'center';

      headerImageLayout = (
        <View style={{alignItems: 'center'}}>
          <Image
            style={{width: 700, height: 300}}
            source={imageSource}
            defaultSource={defaultSource}
          />
        </View>
      );
    }

    var authorMarginTop = -27;

    if (Platform.OS === 'android') {
      authorMarginTop = -15;
    }

    // replace image with video if there is a videoID
    var videoID = this.state.issue.VideoID;
    // videoID = '530845717';

    if (videoID != null) {
      let webCSS = '';

      if (Platform.OS === 'web') {
        webCSS = `
          .video-content {
            min-height: 340px;
          }
          .video-container {
            min-height: 340px;
          }
        `;
      }

      var videoHtml =
        `
      <html>
        <head>
          <meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>
          <style>
          body {
            margin: 0px;
            padding: 0px;
          }
          .video-content {
            height: 100%;
          }
          .video-container {
            width: 100%;
            position: relative;
            height: 100%;
          }
          .video-frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          ${webCSS}

          </style>
        </head>
        <body>
          <div class='video-container'>
            <iframe src="https://player.vimeo.com/video/` +
        videoID +
        `?portrait=0&autoplay=0" class="video-frame" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </body>
      </html>`;

      let videoWidth = Dimensions.get('window').width;
      if (videoWidth > 700) {
        videoWidth = 700;
      }

      authorMarginTop = -16;

      if (Platform.OS === 'web') {
        headerImageLayout = (
          <View
            style={{flex: 1, maxHeight: 340, minHeight: 340, marginBottom: 0}}>
            <div
              className="video-content"
              dangerouslySetInnerHTML={{__html: videoHtml}}></div>
          </View>
        );
      } else if (Platform.OS === 'ios') {
        headerImageLayout = (
          <View
            style={{width: videoWidth, aspectRatio: 16 / 9, marginBottom: 0}}>
            <AMWebView
              style={{flex: 1}}
              source={{html: videoHtml}}
              allowsFullscreenVideo={true}
              useWebKit={true}
            />
          </View>
        );
      } else if (Platform.OS === 'android') {
        headerImageLayout = (
          <View
            style={{
              alignSelf: 'stretch',
              width: videoWidth,
              aspectRatio: 16 / 9,
              marginBottom: 0,
            }}>
            <AMWebView
              style={{flex: 1}}
              source={{html: videoHtml}}
              allowsFullscreenVideo={true}
              useWebKit={true}
            />
          </View>
        );
      }
    }

    let audioHtml = '';
    let audioURL = this.state.issue.AudioURL;
    if (audioURL != null && audioURL !== '') {
      audioHtml =
        "<audio title='ParaPass Standby CPD' preload='metadata' style='width: 100%;' src='" +
        audioURL +
        "' controls></audio>";
    }

    var contentHTML = this.state.issueHtml;

    if (Platform.OS === 'web') {
      // change so all images are loaded from s3 rather than locally
      var originalSrc = 'src="images/';
      var newSrc =
        'src="' +
        'https://ppp-temp-files.s3.amazonaws.com/' +
        this.state.issue.Name +
        '/';
      var regExpSrc = new RegExp(originalSrc, 'g');
      contentHTML = contentHTML.replace(regExpSrc, newSrc);

      // change all links to open in new tab/window as opening in current tab and going back would lose navigation
      var originalAnchors = '<a href="http';
      var newAnchors = '<a target="_blank" href="http';
      var regExpAnchors = new RegExp(originalAnchors, 'g');
      contentHTML = contentHTML.replace(regExpAnchors, newAnchors);
    }

    var authorText = '';
    var authorHtml = '<h1>Authors</h1>';

    if (this.state.issue.Author != null) {
      authorText = 'By ' + this.state.issue.Author.join(', ');
    }

    for (
      let authorIndex = 0;
      authorIndex < this.state.issue.Author.length;
      authorIndex++
    ) {
      var author = this.state.issue.Author[authorIndex];

      let matchingAuthor = _.find(this.props.screenProps.authors, {
        Name: author,
      });

      if (matchingAuthor != null) {
        authorHtml += '<h2>' + matchingAuthor.Name + '</h2>';

        if (matchingAuthor.Notes != null) {
          authorHtml += '<p>' + matchingAuthor.Notes + '</p>';
        } else {
          authorHtml += '<br/>';
        }
      } else {
        authorHtml += '<h2>' + author + '</h2>';
      }
    }

    var mainBundlePath = DataController.getBundleWebPath();

    var jQueryScript =
      `<script id='jqueryScript' src="` +
      mainBundlePath +
      `jquery-3.5.1.min.js"></script>`;

    var jQueryCode = '';
    var updateHeightScript = '';

    if (Platform.OS !== 'web') {
      jQueryCode = `
        $(document).ready(function(){

          var singleClickTimer = null;

          $('.image').click(function(e){

            console.log('tapped');
            console.log(e);
            window.ReactNativeWebView.postMessage("imageTapped-" + e.target.id + "|" + e.target.src);
          });
        });
      `;

      updateHeightScript = `
        window.location.hash = 1;
          var calculator = document.createElement("div");
          calculator.className = "height-calculator";
          while (document.body.firstChild) {
              calculator.appendChild(document.body.firstChild);
          }
        document.body.appendChild(calculator);
        document.title = calculator.scrollHeight;
        console.log(calculator.scrollHeight);
        console.log('posting message');
        window.ReactNativeWebView.postMessage("scrollHeight-" + calculator.scrollHeight);
        console.log('adding button click');
        // document.querySelector("button").onclick = function() {
        //   console.log("Send post message");
        //   window.ReactNativeWebView.postMessage("Hello React");
        // }
      `;
    }

    const script =
      jQueryScript +
      `
    
    <script id='mainScript'>

        // your code goes here
        setTimeout(function() {
          
          // toggle_visibility('list');
          // toggle_visibility('list');

          updateHeight();

        }, 550);

        console.log('adding image click');

        ` +
      jQueryCode +
      `

        // document.querySelector("button").onclick = function() {
        //   console.log("Send post message");
        //   // window.ReactNativeWebView.postMessage("Hello React");
        // }

        function toggle_visibility(className) {
          elements = document.getElementsByClassName(className);
          for (var i = 0; i < elements.length; i++) {
              console.log('visibility');
              elements[i].style.display = elements[i].style.display == 'inline' ? 'none' : 'inline';
          }

          updateHeight();
        }

        function updateHeight() {

          ` +
      updateHeightScript +
      `
        }

    </script>
    `;

    var collapsibleScript = `
    <script id='collapsibleScript'>var coll = document.getElementsByClassName("collapsible");
    var i;
    console.log('script activated');

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        console.log('collapsible clicked');
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight){
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }

        setTimeout(function() {
          updateHeight();
        }, 200);
      });
    }</script>
    `;

    var styleCSS =
      `
    <style>
      .standby-content-container
      {
          font-family: InterUI-Regular, -apple-system, BlinkMacSystemFont,
                      "Segoe UI", "Roboto", "Oxygen",
                      "Ubuntu", "Cantarell", "Fira Sans",
                      "Droid Sans", "Helvetica Neue", sans-serif;
          font-size: ` +
      this.props.screenProps.fontSize +
      `px;
          margin-left:auto;
          margin-right:auto;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 20px;
          -webkit-text-size-adjust:none;
          line-height:140%;
          max-width:700px;
          /*    6.5 inch*/

          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-word-break: break-all;
          word-break: break-word;
      }
      p.volume
      {
          text-align:right;
          font-size:140%;
          font-weight:bold;
          margin-top:1.5em;
          margin-bottom:-1.5em;
      }
      span.volume
      {
          background-color:#3ca376;
          color:#ffffff;
          border-radius:10px;
          padding:0.5em;
          padding-left:1.5em;
          padding-right:1.5em;
      }
      img{
          max-height:100%;
          max-width:100%;
      }
      img.inline
      {
          vertical-align:middle;
      }
      p.image
      {
          text-align:center;
      }
      p.openimage
      {
          text-align:center;
          padding-top:2em;
          padding-bottom:5em;
          border-radius:10px;
          background-color:#fef56c;
      }
      p.author
      {
          text-align:center;
          font-size:140%;
          font-weight:bold;
          margin-top:-5em;
          margin-bottom:1.5em;
      }
      span.author
      {
          background-color:#3ca376;
          color:#ffffff;
          border-radius:10px;
          padding:0.5em;
          padding-left:5em;
          padding-right:5em;
      }
      p.title
      {
          text-align:center;
          color:#0081ad;
          font-size:180%;
          font-weight:bold;
          margin-bottom:2em;
      }
      div.box
      {
          border:3px solid #0081ad;
          padding:0.3em 1em 0.3em 1em;
      }
      p.floatr
      {
          text-align:right;
          float:right;
          margin-top:-1em;
          margin-right:-9px;
      }
      
      p.float
      {
          text-align:right;
          float:right;
          margin-top:-1em;
          padding-left:1em;
      }
      span.color
      {
          color:#0081ad;
      }
      span.color1
      {
          color:#0081ad;
          font-size:140%;
      }
      
      h1
      {
          font-size:180%;
          color:#0081ad;
          line-height:1em;
          margin-top:1em;
      }
      h2
      {
          font-size:140%;
          margin-bottom:-.5em;
          margin-top:1em;
      }
      p.fig-caption
      {
          text-align:left;
          font-size:120%;
          color:#0081ad;
      }
      span.endhead
      {
          color:#86cfd6;
          font-size:140%;
          text-shadow:2px 2px 2px #767d83;
      
      }
      p.attribution
      {
          font-size:90%;
          margin-top:-.8em;
          margin-bottom:.8em;
          color:#0081ad;
          padding-top:.3em;
          text-align:left;
      }
      div.figure
      {
          margin-top:2em;
          margin-bottom:2em;
      }
      
      p.table-caption
      {
          text-align:left;
          font-size:120%;
          color:#0081ad;
      }
      table
      {
      /*    border:2px solid;*/
          width:100%;
      }
      td.cell1
      {
          border-bottom:2px solid;
          padding-top:.5em;
          padding-left:0.5em;
          padding-bottom:.5em;
      }
      td.cell1a
      {
          padding-top:.5em;
          padding-left:0.5em;
          padding-bottom:.5em;
      }
      p.sq
      {
          text-indent:-1.35em;
          margin-left:1.55em;
          margin-top:-.8em;
          text-align:left;
      }
      span.sq
      {
          color:#0081ad;
      }
      div.list
      {
          margin-top:1em;
          margin-bottom:1em;
      }
      p.reftitle
      {
          text-align:center;
          color:#3ca376;
          font-size:165%;
          font-weight:bold;
          margin-bottom:1em;
      }            

      body, html, .height-calculator {
          margin: 0;
          padding: 0;
      }
      .height-calculator {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
      }
      .hiddenText {
        display: none;
      }
      .assessment-button {
        background-color: ` +
      colors.ButtonBlue +
      `;
        border: none;
        color: white;
        padding: 15px 32px;
        width: 100%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
      }


      .accordion {
        background-color: #fff;
        color: #0081ad;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 0px;
        padding-right: 10px;
        margin-bottom: 0px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 26px;
        font-family: InterUI-Bold, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        transition: 0.4s;
      }
      
      
      .collapsible {
        font-size:115%;
        color:#0081ad;
        background-color: #FFF;
        font-weight: 600;
        line-height:1em;
        margin-top:0.5em;
        cursor: pointer;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 4px;
        padding-right: 30px;
        margin-right: 20px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
      }
      
      .active, .collapsible:hover {

      }
      
      .collapsible:after {
        padding: 0 0 0 0;
        content: url('` +
      mainBundlePath +
      'img/Button_Disclosure_Right.png' +
      `');
        float: right;
        position: relative;
        left: 30;
        vertical-align: text-top;
      }
      
      .active:after {
        content: url('` +
      mainBundlePath +
      'img/Button_Disclosure_Down.png' +
      `');
      }
      
      .collapsible-content {
        padding-bottom: 10;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }


      
      .panel {
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
      sup {
        font-size: 0.6em;
      }
    </style>
    `;

    var rawHtml =
      "<html><head><meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>" +
      styleCSS +
      script +
      "</head><body><div class='standby-content-container'>" +
      audioHtml +
      contentHTML +
      authorHtml;

    // add button  to take quiz before reference
    var referencesReplacementCode = null;

    // add collapsible sections

    var sections = rawHtml.split('<h1>');
    var allSectionsHtml = '';

    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      var section = sections[sectionIndex];

      if (sectionIndex === 0) {
        allSectionsHtml += section;
      } else {
        if (sectionIndex === sections.length - 1) {
          console.log('last section');
        }

        var sectionTitle = section.substr(0, section.indexOf('</h1>'));
        var section = section.substr(
          sectionTitle.length + 5,
          section.length - (sectionTitle.length + 5),
        );

        allSectionsHtml =
          allSectionsHtml +
          "<button class='collapsible'>" +
          sectionTitle +
          '</button>' +
          "<div class='collapsible-content'>" +
          section +
          '</div>';
      }
    }

    rawHtml = allSectionsHtml;

    rawHtml +=
      "<span class='hiddenText'>" +
      1 +
      '</span></div>' +
      collapsibleScript +
      '</body></html>';

    if (Platform.OS === 'web') {
      if (document.getElementById('jqueryScript') != null) {
        const scriptTag = document.getElementById('jqueryScript').innerHTML;
        window.eval(scriptTag);
      }

      if (document.getElementById('mainScript') != null) {
        const scriptTag = document.getElementById('mainScript').innerHTML;
        window.eval(scriptTag);
      }

      if (document.getElementById('collapsibleScript') != null) {
        const scriptTag =
          document.getElementById('collapsibleScript').innerHTML;
        window.eval(scriptTag);
      }
    }

    var baseUrl = DataController.getStandbyIssuePath(this.state.issue); // image path without last component
    if (Platform.OS == 'android') {
      // console.log('file://' + RNFS.DocumentDirectoryPath + '/' + this.state.issue.Name + '/');
      baseUrl =
        'file://' +
        RNFS.DocumentDirectoryPath +
        '/' +
        this.state.issue.Name +
        '/';
    }

    var scalesPageToFit = false;

    if (Platform.OS === 'android') {
      scalesPageToFit = true;
    }

    console.log('RAW HTML: ' + contentHTML);

    var webContentLayout = (
      <AMWebView
        style={{flex: 1, height: this.state.webviewHeight}}
        // ref="mainWebView"
        source={{html: rawHtml, baseUrl: baseUrl}}
        allowFileAccessFromFileURLs={true}
        // style={{height:this.state.Height}}
        scalesPageToFit={scalesPageToFit}
        originWhitelist={['*']}
        scrollEnabled={false}
        allowFileAccess={true}
        javaScriptEnabled={true}
        allowsFullscreenVideo={true}
        allowUniversalAccessFromFileURLs={true}
        useWebKit={true}
        onMessage={m => this.onMessage(m)}
        onNavigationStateChange={this.onNavigationChange.bind(this)}
        onShouldStartLoadWithRequest={event => {
          console.log(event.url);
          if (
            event.url.includes('http') ||
            event.url.includes('www') ||
            event.url.includes('mailto')
          ) {
            if (
              event.url.includes('https://parapassweb.co.uk') &&
              event.url.includes('quiz=')
            ) {
              try {
                let quizName = event.url.substr(event.url.indexOf('quiz=') + 5);
                this.props.screenProps.openQuizSetWithName(quizName, undefined);
              } catch (error) {
                console.log(error);
              }
              return false;
            }

            PlatformController.openURL(event.url);
            return false;
          } else if (event.url.includes('glid://')) {
            let glid = event.url.replace('glid://', '');
            this.goToReference(glid);
            return false;
          }
          return true;
        }}
      />
    );

    if (Platform.OS === 'web') {
      var buttonHtml =
        "<button class='assessment-button' onclick='" +
        this.takeQuizButtonTapped.bind(this, 'Bottom of Page') +
        "'>Test</button>";

      rawHtml = rawHtml.replace(
        "<button class='assessment-button'>Take assessment quiz</button>",
        '',
      );

      var tags = document.getElementsByTagName('a');

      console.log(tags.length);

      for (var i = 0; i < tags.length; i++) {
        let anchorTag = tags[i];

        let href = anchorTag.getAttribute('href');
        if (href != null && href.includes('glid://')) {
          let glid = href.replace('glid://', '');
          anchorTag.setAttribute('href', '#');
          anchorTag.onclick = this.goToReference.bind(this, glid);
        }
      }

      webContentLayout = (
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: rawHtml + script}}></div>
      );
    }

    var contentStretchMode = 'stretch';

    if (LayoutController.isCompact() === false) {
      contentStretchMode = 'center';
    }

    var src = [{url: this.state.imageModalPath, props: {}}];

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView
          style={{flex: 1}}
          scrollIndicatorInsets={{left: 1}}
          contentContainerStyle={{
            backgroundColor: colors.white,
            alignItems: 'stretch',
            paddingBottom: 50,
          }}>
          <View
            style={{
              height: 60,
              backgroundColor: colors.StandbyYellow,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Image
              style={{width: 325, height: 49}}
              source={require('../assets/Logo_Standby_Large.png')}
            />
          </View>

          <View style={{alignItems: contentStretchMode}}>
            <View style={{maxWidth: 700, alignItems: 'stretch'}}>
              {headerImageLayout}

              <View
                style={{
                  padding: 15,
                  maxWidth: 740,
                  backgroundColor: colors.StandbyYellow,
                }}>
                <View
                  style={{
                    alignItems: 'center',
                    marginTop: authorMarginTop,
                    marginBottom: 9,
                  }}>
                  <View
                    style={{
                      paddingHorizontal: 13,
                      paddingVertical: 2,
                      backgroundColor: colors.StandbyGreen,
                    }}>
                    <DynamicText
                      style={{
                        fontSize: 15,
                        fontWeight: '600',
                        letterSpacing: -0.24,
                        color: colors.white,
                      }}>
                      {authorText}
                    </DynamicText>
                  </View>
                </View>

                <DynamicText
                  style={{
                    fontSize: 21,
                    fontWeight: 'bold',
                    letterSpacing: -0.54,
                    color: '#0e5c77',
                    textAlign: alignTitle,
                  }}>
                  {this.state.issue.Title}
                </DynamicText>
              </View>

              <View style={{marginBottom: 10, padding: 10}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <DynamicText style={{fontSize: 12, color: colors.Grey700}}>
                    {quizSetDate}
                  </DynamicText>
                  <View
                    style={{
                      marginHorizontal: 6,
                      width: 2,
                      height: 2,
                      backgroundColor: colors.Grey700,
                      borderRadius: 1,
                    }}
                  />
                  <DynamicText
                    style={{
                      fontSize: 12,
                      fontWeight: '500',
                      color: colors.Grey700,
                    }}>
                    {this.state.quizSet.questions.length} Questions
                  </DynamicText>
                </View>
              </View>

              {webContentLayout}

              <TouchableOpacity
                activeOpacity={0.5}
                onPress={this.takeQuizButtonTapped.bind(
                  this,
                  'Bottom of Page',
                )}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 30,
                  }}>
                  <DynamicText
                    style={{
                      marginRight: 5,
                      paddingHorizontal: 60,
                      paddingVertical: 14,
                      backgroundColor: colors.ButtonBlue,
                      fontSize: 17,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.white,
                    }}>
                    Take assessment quiz
                  </DynamicText>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>

        <AMImageViewer
          visible={this.state.imageViewerVisible}
          imageUrls={src}
          onClose={this.closeImageModal.bind(this)}
        />
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
